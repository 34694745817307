export const isValidAddress = (address) =>
  Boolean(
    address.country && address.postalCode && address.street && address.name1,
  );

export const isFrenchAddress = (address) =>
  Boolean(
    address && address.country && address.country.toLowerCase() === "france",
  );

export default {
  isValidAddress,
  isFrenchAddress,
};
