import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Actions from "components/Layout/Actions";
import CenteredTitle from "components/Layout/CenteredTitle";
import Illustration from "components/Layout/Illustration";
import MainContent from "components/Layout/MainContent";
import Button from "components/Button";
import Link from "@material-ui/core/Link";
import { getReturnUrl } from "utils/UrlUtil";
import Step from "components/Layout/Step";
import useServiceName from "hook/useServiceName";
import { useTranslation } from "react-i18next";

const ErrorUrlNotAuthorized = () => {
  const serviceName = useServiceName();

  const { t } = useTranslation();

  const title = t("errorUrlNotAuthorized:title");

  return (
    <Step progress={100}>
      <Illustration>
        <img src="/certification.png" alt={title} aria-hidden title={title} />
      </Illustration>
      <CenteredTitle>{title}</CenteredTitle>
      <MainContent>
        <Typography variant="body1" component="div" align="center">
          {t("errorUrlNotAuthorized:subTitle")}
        </Typography>
      </MainContent>
      <Actions>
        <Box mb={2} style={{ width: "100%" }}>
          <Link
            style={{ width: "100%" }}
            href={getReturnUrl()}
            underline="none"
          >
            <Button>
              {t("common:goTo")} {serviceName}
            </Button>
          </Link>
        </Box>
      </Actions>
    </Step>
  );
};

export default ErrorUrlNotAuthorized;
