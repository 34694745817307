import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Actions from "components/Layout/Actions";
import MainContent from "components/Layout/MainContent";
import Button from "components/Button";
import Link from "components/Link";
import Step from "components/Layout/Step";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import MiniCard from "components/Layout/MiniCard";
import {
  LEGAL_REPRESENTATION_OPTIONS,
  getLegalRepresentativeStatus,
  setLegalRepresentativeStatus as setStatus,
} from "utils/TestModeParametersUtil";

function LegalRepresentativeConditions() {
  const title = "Votre entreprise:";

  const [legalRepresentativeStatus, setLegalRepresentativeStatus] = useState(
    getLegalRepresentativeStatus(),
  );

  const disabled = !legalRepresentativeStatus;

  const goOnButton = () => (
    <Button
      data-testid="test-button"
      disabled={disabled}
      onClick={() => setStatus(legalRepresentativeStatus)}
    >
      Continuer
    </Button>
  );

  return (
    <Step
      title={title}
      previousStepPath="/conditions-compte"
      progress={500 / 8}
    >
      <MainContent>
        <FormControl fullWidth component="fieldset">
          <RadioGroup
            name="legalRepresentative"
            value={legalRepresentativeStatus}
            onChange={(e) =>
              setLegalRepresentativeStatus(e.currentTarget.value)
            }
          >
            <MiniCard type="tertiary">
              <FormControlLabel
                htmlFor="noLegalRepresentative"
                value={LEGAL_REPRESENTATION_OPTIONS.NO_LEGAL_REPRESENTATIVE}
                control={<Radio id="noLegalRepresentative" />}
                label={
                  <Typography variant="subtitle2">
                    n&apos;a pas de représentant légal
                  </Typography>
                }
              />
            </MiniCard>

            <MiniCard type="tertiary">
              <FormControlLabel
                htmlFor="realPath"
                value={LEGAL_REPRESENTATION_OPTIONS.REAL_PATH}
                control={<Radio id="realPath" />}
                label={
                  <Typography variant="subtitle2">pas de conditions</Typography>
                }
              />
            </MiniCard>
          </RadioGroup>
        </FormControl>
      </MainContent>
      <Actions>
        <Box mb={2} style={{ width: "100%" }}>
          {disabled ? (
            goOnButton()
          ) : (
            <Link
              data-testid="test-link"
              to={{
                pathname: "/conditions-piece-d-identite",
                state: {
                  from: "/conditions-representant-legal",
                },
              }}
              underline="none"
            >
              {goOnButton()}
            </Link>
          )}
        </Box>
      </Actions>
    </Step>
  );
}

export default LegalRepresentativeConditions;
