import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const FaceIdIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 74 54">
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M50.989 1.49l-1.083 26.282c-.045.503-.364.94-.829 1.138l-40.6 15.454c-.181.083-.394.059-.554-.062-.16-.12-.24-.319-.208-.516l.35-27.639c.02-.514.34-.969.817-1.164L50.217.916c.182-.086.398-.066.56.055.162.12.243.32.212.519z"
          transform="translate(9 4)"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M50.879 1.579l-.86 25.769c-.036.494-.341.927-.794 1.129L6.703 45.495c-.439.162-.75-.101-.746-.591l.351-27.639c.015-.502 2.07-2.697 2.534-2.894L48.515.904c.179-.07 1.679-.517 1.829-.396.535 0 .535.535.535 1.071z"
          transform="translate(9 4)"
        />
        <path
          fill="#20458F"
          d="M50.701 27.471l1.097-26.252c.039-.914-.676-1.435-1.589-1.133L6.632 14.875c-.967.39-1.62 1.305-1.671 2.345l-.299 27.735c-.009.98.724 1.497 1.601 1.151l42.74-16.338c.943-.399 1.594-1.28 1.698-2.297zm-.518-25.696l-1.067 26.279c-.011.015-.026.027-.043.035L6.28 44.33c-.014.007-.03.007-.043 0l.329-27.648c.01-.015.026-.026.043-.03L50.14 1.775c.014-.003.029-.003.044 0z"
          transform="translate(9 4)"
        />
        <path
          fill="#20458F"
          d="M37.244 17.648l-14.688 5.255.043-1.855 14.702-5.22-.057 1.82zm3.093 1.89L22.49 25.992l.04-1.855 17.868-6.415-.061 1.816zm-17.918 9.543l14.631-5.355.057-1.825-14.644 5.325-.044 1.855zm10.086-.678L22.353 32.17l.04-1.851 10.165-3.75-.053 1.834zm-22.198 8.274l9.507-3.557.232-11.158-9.577 3.435-.162 11.28z"
          transform="translate(9 4)"
        />
        <path
          fill="#20458F"
          fillRule="nonzero"
          d="M50.924 6.92L6.057 22.597 6.131 16.301 51.174 0.943z"
          transform="translate(9 4)"
        />
        <path
          fill="#EDF3FF"
          fillRule="nonzero"
          d="M18.1 33.758c.034-3.159-.698-4.812-1.663-5.32.632-.615 1.016-1.44 1.079-2.319.026-1.313-1.035-1.96-2.365-1.479-1.365.525-2.314 1.776-2.448 3.229-.072.724.393 1.392 1.097 1.58-1.057 1.312-1.909 3.64-1.952 6.646l6.251-2.337z"
          transform="translate(9 4)"
        />
        <path
          stroke="#92004D"
          strokeDasharray="2.85"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.071"
          d="M2.142 4.257h51.414c1.183 0 2.142.96 2.142 2.142v32.134c0 1.183-.959 2.142-2.142 2.142H2.142C.96 40.675 0 39.716 0 38.533V6.399c0-1.183.96-2.142 2.142-2.142z"
          transform="translate(9 4)"
        />
      </g>
    </g>
  </SvgIcon>
);

export default FaceIdIcon;
