import axios from "axios";
import jwt_decode from "jwt-decode";

let jwToken = null;
export const TOKEN_PATH = `${process.env.REACT_APP_CRL_BACK_URL}/token`;

export default class TokenService {
  static getJwToken = () => jwToken;

  static setJwToken = (jwt) => {
    jwToken = jwt;
    const decodedJwt = jwt_decode(jwt.accessToken);
    jwToken.exp = decodedJwt.exp;
  };

  static getAccessToken = () => jwToken && jwToken.accessToken;

  static getIdToken = () => jwToken?.idToken;

  static getTokenFromCode = (code) => axios.post(TOKEN_PATH, { code });

  static refresh = async () => {
    const tokenResponse = await axios.post(TOKEN_PATH, {
      refreshToken: jwToken ? jwToken.refreshToken : "",
    });
    const newJwt = tokenResponse.data;
    this.setJwToken(newJwt);
  };
}
