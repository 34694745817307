import certificationSuccessAutomaticFr from "../languages/certificationSuccessAutomatic/fr.json";
import certificationSuccessAutomaticEn from "../languages/certificationSuccessAutomatic/en.json";
import certificationSuccessManualFr from "../languages/certificationSuccessManual/fr.json";
import certificationSuccessManualEn from "../languages/certificationSuccessManual/en.json";
import certifiedAccountFr from "../languages/certifiedAccount/fr.json";
import certifiedAccountEn from "../languages/certifiedAccount/en.json";
import choiceOfLegalRepresentativeFr from "../languages/choiceOfLegalRepresentative/fr.json";
import choiceOfLegalRepresentativeEn from "../languages/choiceOfLegalRepresentative/en.json";
import commonFr from "../languages/common/fr.json";
import commonEn from "../languages/common/en.json";
import companyInfoFr from "../languages/companyInfo/fr.json";
import companyInfoEn from "../languages/companyInfo/en.json";
import companyStatusFr from "../languages/companyStatus/fr.json";
import companyStatusEn from "../languages/companyStatus/en.json";
import disconnectedHomeFr from "../languages/disconnectedHome/fr.json";
import disconnectedHomeEn from "../languages/disconnectedHome/en.json";
import errorCompanyNonDiffusibleFr from "../languages/errorCompanyNonDiffusible/fr.json";
import errorCompanyNonDiffusibleEn from "../languages/errorCompanyNonDiffusible/en.json";
import errorInactiveCoclicoFr from "../languages/errorInactiveCoclico/fr.json";
import errorInactiveCoclicoEn from "../languages/errorInactiveCoclico/en.json";
import errorMissingCoclicoFr from "../languages/errorMissingCoclico/fr.json";
import errorMissingCoclicoEn from "../languages/errorMissingCoclico/en.json";
import footerFr from "../languages/footer/fr.json";
import footerEn from "../languages/footer/en.json";
import homeFr from "../languages/home/fr.json";
import homeEn from "../languages/home/en.json";
import idAnalysisFr from "../languages/idAnalysis/fr.json";
import idAnalysisEn from "../languages/idAnalysis/en.json";
import idIllegibleFr from "../languages/idIllegible/fr.json";
import idIllegibleEn from "../languages/idIllegible/en.json";
import idSelectionFr from "../languages/idSelection/fr.json";
import idSelectionEn from "../languages/idSelection/en.json";
import invalidIdFr from "../languages/invalidId/fr.json";
import invalidIdEn from "../languages/invalidId/en.json";
import multipleIdErrorFr from "../languages/multipleIdError/fr.json";
import multipleIdErrorEn from "../languages/multipleIdError/en.json";
import notReadableIdErrorFr from "../languages/notReadableIdError/fr.json";
import notReadableIdErrorEn from "../languages/notReadableIdError/en.json";
import outOfDateIdErrorFr from "../languages/outOfDateIdError/fr.json";
import outOfDateIdErrorEn from "../languages/outOfDateIdError/en.json";
import partnersFr from "../languages/partners/fr.json";
import partnersEn from "../languages/partners/en.json";
import serviceUnavailableFr from "../languages/serviceUnavailable/fr.json";
import serviceUnavailableEn from "../languages/serviceUnavailable/en.json";
import subHomeFr from "../languages/subHome/fr.json";
import subHomeEn from "../languages/subHome/en.json";
import subIdSelectionFr from "../languages/subIdSelection/fr.json";
import subIdSelectionEn from "../languages/subIdSelection/en.json";
import subRLIdSelectionFr from "../languages/subRLIdSelection/fr.json";
import subRLIdSelectionEn from "../languages/subRLIdSelection/en.json";
import updateUserInfoFr from "../languages/updateUserInfo/fr.json";
import updateUserInfoEn from "../languages/updateUserInfo/en.json";
import uploadFr from "../languages/upload/fr.json";
import uploadEn from "../languages/upload/en.json";
import stepFr from "../languages/step/fr.json";
import stepEn from "../languages/step/en.json";
import idSelectionStepFr from "../languages/idSelectionStep/fr.json";
import idSelectionStepEn from "../languages/idSelectionStep/en.json";
import uploadFileFr from "../languages/uploadFile/fr.json";
import uploadFileEn from "../languages/uploadFile/en.json";
import useUploadFr from "../languages/useUpload/fr.json";
import useUploadEn from "../languages/useUpload/en.json";
import certificationControlInProgressFr from "../languages/certificationControlInProgress/fr.json";
import certificationControlInProgressEn from "../languages/certificationControlInProgress/en.json";
import unknownCompanyFr from "../languages/unknownCompany/fr.json";
import unknownCompanyEn from "../languages/unknownCompany/en.json";
import unknownLeaderFr from "../languages/unknownLeader/fr.json";
import unknownLeaderEn from "../languages/unknownLeader/en.json";
import errorMissingSiretFr from "../languages/errorMissingSiret/fr.json";
import errorMissingSiretEn from "../languages/errorMissingSiret/en.json";
import errorMissingAddressFr from "../languages/errorMissingAddress/fr.json";
import errorMissingAddressEn from "../languages/errorMissingAddress/en.json";
import errorUrlNotAuthorizedFr from "../languages/errorUrlNotAuthorized/fr.json";
import errorUrlNotAuthorizedEn from "../languages/errorUrlNotAuthorized/en.json";
import errorInactiveSiretFr from "../languages/errorInactiveSiret/fr.json";
import errorInactiveSiretEn from "../languages/errorInactiveSiret/en.json";

export const resourceFr = {
  certificationSuccessAutomatic: certificationSuccessAutomaticFr,
  certificationSuccessManual: certificationSuccessManualFr,
  certifiedAccount: certifiedAccountFr,
  choiceOfLegalRepresentative: choiceOfLegalRepresentativeFr,
  common: commonFr,
  companyInfo: companyInfoFr,
  companyStatus: companyStatusFr,
  disconnectedHome: disconnectedHomeFr,
  errorCompanyNonDiffusible: errorCompanyNonDiffusibleFr,
  errorInactiveCoclico: errorInactiveCoclicoFr,
  errorMissingCoclico: errorMissingCoclicoFr,
  footer: footerFr,
  home: homeFr,
  idAnalysis: idAnalysisFr,
  idIllegible: idIllegibleFr,
  idSelection: idSelectionFr,
  invalidId: invalidIdFr,
  multipleIdError: multipleIdErrorFr,
  notReadableIdError: notReadableIdErrorFr,
  outOfDateIdError: outOfDateIdErrorFr,
  partners: partnersFr,
  serviceUnavailable: serviceUnavailableFr,
  subHome: subHomeFr,
  subIdSelection: subIdSelectionFr,
  subRLIdSelection: subRLIdSelectionFr,
  updateUserInfo: updateUserInfoFr,
  upload: uploadFr,
  step: stepFr,
  idSelectionStep: idSelectionStepFr,
  uploadFile: uploadFileFr,
  useUpload: useUploadFr,
  certificationControlInProgress: certificationControlInProgressFr,
  unknownCompany: unknownCompanyFr,
  unknownLeader: unknownLeaderFr,
  errorMissingSiret: errorMissingSiretFr,
  errorMissingAddress: errorMissingAddressFr,
  errorUrlNotAuthorized: errorUrlNotAuthorizedFr,
  errorInactiveSiret: errorInactiveSiretFr,
};

export const resourceEn = {
  certificationSuccessAutomatic: certificationSuccessAutomaticEn,
  certificationSuccessManual: certificationSuccessManualEn,
  certifiedAccount: certifiedAccountEn,
  choiceOfLegalRepresentative: choiceOfLegalRepresentativeEn,
  common: commonEn,
  companyInfo: companyInfoEn,
  companyStatus: companyStatusEn,
  disconnectedHome: disconnectedHomeEn,
  errorCompanyNonDiffusible: errorCompanyNonDiffusibleEn,
  errorInactiveCoclico: errorInactiveCoclicoEn,
  errorMissingCoclico: errorMissingCoclicoEn,
  footer: footerEn,
  home: homeEn,
  idAnalysis: idAnalysisEn,
  idIllegible: idIllegibleEn,
  idSelection: idSelectionEn,
  invalidId: invalidIdEn,
  multipleIdError: multipleIdErrorEn,
  notReadableIdError: notReadableIdErrorEn,
  outOfDateIdError: outOfDateIdErrorEn,
  partners: partnersEn,
  serviceUnavailable: serviceUnavailableEn,
  subHome: subHomeEn,
  subIdSelection: subIdSelectionEn,
  subRLIdSelection: subRLIdSelectionEn,
  updateUserInfo: updateUserInfoEn,
  upload: uploadEn,
  step: stepEn,
  idSelectionStep: idSelectionStepEn,
  uploadFile: uploadFileEn,
  useUpload: useUploadEn,
  certificationControlInProgress: certificationControlInProgressEn,
  unknownCompany: unknownCompanyEn,
  unknownLeader: unknownLeaderEn,
  errorMissingSiret: errorMissingSiretEn,
  errorMissingAddress: errorMissingAddressEn,
  errorUrlNotAuthorized: errorUrlNotAuthorizedEn,
  errorInactiveSiret: errorInactiveSiretEn,
};
