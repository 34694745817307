export default class UploadItem {
  constructor({
    label = () => "",
    id = "",
    file = "",
    documentId = "",
    progress = "",
  } = {}) {
    this.label = label;
    this.id = id;
    this.file = file;
    this.documentId = documentId;
    this.progress = progress;
  }
}
