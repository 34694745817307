import axios from "axios";
import UrlParametersKey from "utils/UrlParametersKey";

export const PREVENT_MANUAL_REQUESTS_PATH = `${process.env.REACT_APP_CRL_BACK_URL}/prevent-manual-requests`;

export default class PreventManualRequestsService {
  static checkPreventManualRequests = () =>
    axios.get(`${PREVENT_MANUAL_REQUESTS_PATH}/check`, {
      params: {
        entry: sessionStorage.getItem(UrlParametersKey.ENTRY) || "direct",
      },
    });
}
