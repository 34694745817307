import React, { useState, useEffect } from "react";
import Step from "components/Layout/Step";
import MiniCard from "components/Layout/MiniCard";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CompanyService from "services/CompanyService";
import CompanyDirectorStatus from "utils/CompanyDirectorStatus";
import MainContent from "components/Layout/MainContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "components/Button";
import Actions from "components/Layout/Actions";
import Box from "@material-ui/core/Box";
import PreventManualRequestsProvider from "components/PreventManualRequests/PreventManualRequestsProvider";
import { getManualSwitchStatus } from "utils/AutomaticStatusUtil";
import ManualSwitchStatus from "utils/ManualSwitchStatus";
import { useTranslation } from "react-i18next";

const CompanyStatus = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [previousStepPath, setPreviousStepPath] = useState(
    "/verification-client",
  );
  const [companyStatus, setCompmanyStatus] = useState("COMPANY_IMMATPENDING");

  const goToUpload = () => {
    history.push({
      pathname: "/import/justificatifs-societe",
      state: { access: companyStatus },
    });
  };

  useEffect(() => {
    const getPreviousStepPath = async () => {
      const companyDirectorStatus = await CompanyService.getStatus();

      if (
        companyDirectorStatus === CompanyDirectorStatus.USER_IS_NOT_DIRECTOR &&
        CompanyService.getChoiceOfLegalRepresentative() !==
          "DIRIGEANT_NOT_EXIST"
      ) {
        setPreviousStepPath("/choix-representant-legal");
      }
    };

    getPreviousStepPath();
  }, []);

  return (
    <PreventManualRequestsProvider
      preventFunction={() =>
        getManualSwitchStatus() === ManualSwitchStatus.NO_PHYSICAL_DIRECTOR ||
        getManualSwitchStatus() === ManualSwitchStatus.USER_IS_NOT_DIRECTOR
      }
    >
      <Step
        title={t("companyStatus:title")}
        previousStepPath={previousStepPath}
        progress={55}
      >
        <MainContent>
          <FormControl fullWidth component="fieldset">
            <RadioGroup
              aria-label="company-status"
              name="company-status"
              defaultValue={companyStatus}
              onChange={(e) => setCompmanyStatus(e.currentTarget.value)}
            >
              <MiniCard type="tertiary">
                <FormControlLabel
                  htmlFor="companyStatusCompanyImmatpending"
                  value="COMPANY_IMMATPENDING"
                  control={
                    <Radio
                      id="companyStatusCompanyImmatpending"
                      inputProps={{
                        "data-testid": "option_1",
                      }}
                    />
                  }
                  label={
                    <Typography variant="subtitle2">
                      {t("companyStatus:choice1")}
                    </Typography>
                  }
                />
              </MiniCard>

              <MiniCard type="tertiary">
                <FormControlLabel
                  htmlFor="companyStatusCompanyAssociation"
                  value="COMPANY_ASSOCIATION"
                  control={
                    <Radio
                      id="companyStatusCompanyAssociation"
                      inputProps={{
                        "data-testid": "option_2",
                      }}
                    />
                  }
                  label={
                    <Typography variant="subtitle2">
                      {t("companyStatus:choice2")}
                    </Typography>
                  }
                />
              </MiniCard>
              <MiniCard type="tertiary">
                <FormControlLabel
                  htmlFor="companyStatusCompanyLiberal"
                  value="COMPANY_LIBERAL"
                  control={<Radio id="companyStatusCompanyLiberal" />}
                  label={
                    <Typography variant="subtitle2">
                      {t("companyStatus:choice3")}
                    </Typography>
                  }
                />
              </MiniCard>
              <MiniCard type="tertiary">
                <FormControlLabel
                  htmlFor="companyStatusCompanyImmatdone"
                  value="COMPANY_IMMATDONE"
                  control={<Radio id="companyStatusCompanyImmatdone" />}
                  label={
                    <Typography variant="subtitle2">
                      {t("companyStatus:choice4")}
                    </Typography>
                  }
                />
              </MiniCard>
              <MiniCard type="tertiary">
                <FormControlLabel
                  htmlFor="companyStatusCompanyForeign"
                  value="COMPANY_FOREIGN"
                  control={<Radio id="companyStatusCompanyForeign" />}
                  label={
                    <Typography variant="subtitle2">
                      {t("companyStatus:choice5")}
                    </Typography>
                  }
                />
              </MiniCard>
            </RadioGroup>
          </FormControl>
        </MainContent>
        <Actions>
          <Box mb={2} style={{ width: "100%" }}>
            <Button onClick={goToUpload} data-testid="test-button">
              {t("common:continue")}
            </Button>
          </Box>
        </Actions>
      </Step>
    </PreventManualRequestsProvider>
  );
};

export default CompanyStatus;
