import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Actions from "components/Layout/Actions";
import MainContent from "components/Layout/MainContent";
import Button from "components/Button";
import Link from "components/Link";
import Step from "components/Layout/Step";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import MiniCard from "components/Layout/MiniCard";
import {
  setIsAutomaticSuccess as setStatus,
  getIsAutomaticSuccess,
} from "utils/TestModeParametersUtil";

function AutomaticSuccess() {
  const title =
    "Voulez-vous que votre compte soit automatiquement certifié à la fin:";

  const [automaticSuccess, setIsAutomaticSuccess] = useState(
    getIsAutomaticSuccess(),
  );

  const disabled = automaticSuccess === null;

  const goOnButton = () => (
    <Button
      disabled={disabled}
      onClick={() => setStatus(automaticSuccess)}
      data-testid="test-button"
    >
      Continuer
    </Button>
  );

  return (
    <Step title={title} previousStepPath="/mode-de-test" progress={100 / 8}>
      <MainContent>
        <Typography
          variant="body1"
          component="div"
          style={{
            marginBottom: "2rem",
            textAlign: "center",
          }}
        >
          Attention! Si vous voulez être certifié à la fin de ce parcours, vous
          allez devoir le réaliser entièrement dans les conditions réelles.
        </Typography>
        <FormControl fullWidth component="fieldset">
          <RadioGroup
            name="automaticSuccess"
            value={automaticSuccess}
            onChange={(e) =>
              setIsAutomaticSuccess(e.currentTarget.value === "true")
            }
          >
            <MiniCard type="tertiary">
              <FormControlLabel
                htmlFor="automatic"
                value
                control={<Radio id="automatic" />}
                label={<Typography variant="subtitle2">Oui</Typography>}
              />
            </MiniCard>

            <MiniCard type="tertiary">
              <FormControlLabel
                htmlFor="notAutomatic"
                value={false}
                control={<Radio id="notAutomatic" />}
                label={<Typography variant="subtitle2">Non</Typography>}
              />
            </MiniCard>
          </RadioGroup>
        </FormControl>
      </MainContent>
      <Actions>
        <Box mb={2} style={{ width: "100%" }}>
          {disabled ? (
            goOnButton()
          ) : (
            <Link
              data-testid="test-link"
              to={{
                pathname: automaticSuccess
                  ? "/commencer-test"
                  : "/description-simulateur",
                state: { from: "/automatic-success" },
              }}
              underline="none"
            >
              {goOnButton()}
            </Link>
          )}
        </Box>
      </Actions>
    </Step>
  );
}

export default AutomaticSuccess;
