let isCertified = false;

export const setIsCertified = (newIsCertified) => {
  isCertified = newIsCertified;
};

export const getIsCertified = () => {
  if (isCertified) {
    isCertified = false;
    return true;
  }
  return false;
};
