import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const IdErrorIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 100 100">
    <path
      d="M67 8.295c11.5-2 24 6.5 24 15v51c-.5 8-7 17.5-24 17M33 8.295c-11.5-2-24 6.5-24 15v51c.5 8 7 17.5 24 17"
      stroke="#717171"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="m64.092 25.006-24.818-1.668a5 5 0 0 0-5.326 4.695l-2.653 45.078a5 5 0 0 0 4.727 5.287l25.194 1.337a5 5 0 0 0 5.258-4.74L68.75 30.25a5 5 0 0 0-4.658-5.243z"
      fill="#F7F7F7"
    />
    <path
      d="M64.587 21.968 37.026 20.13a5 5 0 0 0-5.324 4.693l-2.904 48.948a5 5 0 0 0 4.729 5.289L61.5 80.532a5 5 0 0 0 5.256-4.737l2.492-48.582a5 5 0 0 0-4.661-5.245z"
      stroke="#717171"
    />
    <circle cx="50" cy="39.295" r="10" fill="#717171" />
    <path
      d="M41.5 34.295h17M60 39.295H40M41.5 44.295h17"
      stroke="#fff"
      strokeWidth=".5"
      strokeLinecap="round"
    />
    <circle cx="50" cy="39.295" r="11.8" stroke="#717171" strokeWidth=".4" />
    <path
      d="M52 29.295c2.5 3.334 5.5 12.8-.5 20M49 29.295c-2.5 3.334-6.813 11.5-.5 20"
      stroke="#fff"
      strokeWidth=".5"
      strokeLinecap="round"
    />
    <rect
      x="39.12"
      y="56.295"
      width="19.367"
      height="2.565"
      rx="1.283"
      transform="rotate(2.69 39.12 56.295)"
      fill="#717171"
    />
    <rect
      x="39.12"
      y="63.295"
      width="19.367"
      height="2.565"
      rx="1.283"
      transform="rotate(2.69 39.12 63.295)"
      fill="#717171"
    />
    <path
      stroke="#3C3C3C"
      strokeWidth="4"
      strokeLinecap="round"
      d="M12 12.295h76"
    />
    <circle cx="50" cy="48" r="15" fill="#CD3939" />
    <path
      d="m43.99 42.23 12.933 12.116m-13.269 0 12.934-12.115"
      stroke="#fff"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </SvgIcon>
);

export default IdErrorIcon;
