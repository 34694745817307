import axios from "axios";
import {
  isTestModeAuthorized,
  ID_STATUS_OPTIONS,
  RESULT_OPTIONS,
  getIdStatus,
  getIsTestMode,
  getResult,
  getIsAutomaticSuccess,
} from "utils/TestModeParametersUtil";
import CertificationStatus from "utils/CertificationStatus";
import CertificationProcessType from "utils/CertificationProcessType";
import TokenService from "./TokenService";

const CERTIFICATION_PATH = `${process.env.REACT_APP_CRL_BACK_URL}/users/me/certifications`;
const TEST_CERTIFICATION_PATH = `${process.env.REACT_APP_CRL_BACK_URL}/users/me/certifications/test`;
const PROCURATION_PATH = `${process.env.REACT_APP_CRL_BACK_URL}/users/me/procurations`;

let procurationStatus = null;

export default class CertificationService {
  static create = (documentIds, type) => {
    const result = getResult();
    if (
      isTestModeAuthorized &&
      getIsTestMode() &&
      result !== RESULT_OPTIONS.REAL_PATH
    ) {
      if (getIsAutomaticSuccess()) {
        return axios.post(
          `${TEST_CERTIFICATION_PATH}?idToken=${TokenService.getIdToken()}`,
          {
            documentIds,
            type,
          },
        );
      }
      return new Promise((resolve) => {
        resolve({ status: 201, data: { id: "certification_id" } });
      });
    }
    return axios.post(
      `${CERTIFICATION_PATH}?idToken=${TokenService.getIdToken()}`,
      {
        documentIds,
        type,
      },
    );
  };

  static get = (certificationId) => {
    if (isTestModeAuthorized && getIsTestMode() && !getIsAutomaticSuccess()) {
      let status;
      let type;
      const idStatus = getIdStatus();
      const result = getResult();
      switch (idStatus) {
        case ID_STATUS_OPTIONS.NOT_READABLE: {
          status = CertificationStatus.READ_RETRY_DOCUMENT_BAD_SCORE;
          break;
        }
        case ID_STATUS_OPTIONS.IS_EXPIRED: {
          status = CertificationStatus.READ_RETRY_DOCUMENT_NOT_VALID;
          break;
        }
        case ID_STATUS_OPTIONS.NOT_VALID: {
          status = CertificationStatus.READ_RETRY_DOCUMENT_NOT_READABLE;
          break;
        }
        default: {
          status = CertificationStatus.CERTIFICATION_COMPLETED;
          break;
        }
      }

      if (status === CertificationStatus.CERTIFICATION_COMPLETED) {
        if (result === RESULT_OPTIONS.IMPOSSIBLE_CERTIFICATION) {
          status = CertificationStatus.CERTIFICATION_CREATION_ERROR;
        } else if (result === RESULT_OPTIONS.AUTOMATIC_CERTIFICATION) {
          type = CertificationProcessType.AUTOMATIC;
        } else {
          type = CertificationProcessType.MANUAL;
        }
      }

      return new Promise((resolve) => {
        resolve({ data: { status, type } });
      });
    }
    return axios.get(`${CERTIFICATION_PATH}/${certificationId}`);
  };

  static getProcuration = (idToken) =>
    axios.get(`${PROCURATION_PATH}?idToken=${idToken}`);

  static setProcurationStatus = (newProcurationStatus) => {
    procurationStatus = newProcurationStatus;
  };

  static getProcurationStatus = () => procurationStatus;
}
