import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import MuiModal from "@material-ui/core/Modal";
import CloseIcon from "icons/close";
import CloseIconCircle from "icons/closeCircle";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    background: "rgba(76,76,76,0.7)",
  },
  modal: {
    position: "relative",
    margin: "40px 16px 0",
    maxHeight: "calc(100% - 80px)",
    overflow: "auto",
    borderRadius: 8,
    [theme.breakpoints.up("md")]: {
      width: "620px",
    },
  },
  main: {
    padding: "20px",
    [theme.breakpoints.up("sm")]: {
      padding: "24px 20px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "32px 40px 40px",
    },
  },
  icon: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
  },
  iconMobile: {
    fontSize: "24px",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  iconDesktop: {
    fontSize: "40px",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  title: {
    color: theme.palette["brand-02-blue-01-default"],
    fontFamily: "Montserrat",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    marginBottom: "40px",
    marginRight: "30px",
    [theme.breakpoints.up("md")]: {
      fontSize: "22px",
      lineHeight: "28px",
    },
  },
  actions: {},
}));

const Modal = (props) => {
  const { children, title, actions, onClose, ...others } = props;

  const classes = useStyles();

  return (
    <MuiModal onClose={onClose} {...others}>
      <div className={classes.wrapper} onClick={onClose} role="presentation">
        <div className={classes.modal}>
          <Paper square onClick={(e) => e.stopPropagation()}>
            <div className={classes.main}>
              <CloseIcon
                onClick={onClose}
                className={classnames(classes.icon, classes.iconMobile)}
              />
              <CloseIconCircle
                onClick={onClose}
                className={classnames(classes.icon, classes.iconDesktop)}
              />
              {title && <div className={classes.title}>{title}</div>}
              {children}
            </div>
            {actions && <div className={classes.actions}>{actions}</div>}
          </Paper>
        </div>
      </div>
    </MuiModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  actions: PropTypes.node,
};

Modal.defaultProps = {
  className: "",
  children: null,
  title: "",
  actions: null,
};

export default Modal;
