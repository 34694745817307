import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const NoObstacleIdIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 74 54">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(5 9)">
        <rect
          width="8.956"
          height="4.084"
          x="16.657"
          y="29.116"
          fill="#F0F4FF"
          fillRule="nonzero"
          stroke="#92004D"
          strokeWidth="1.071"
          rx="2.042"
          transform="rotate(-48.071 21.136 31.158)"
        />
        <rect
          width="10.367"
          height="4.728"
          x="22.033"
          y="27.38"
          fill="#F0F4FF"
          fillRule="nonzero"
          stroke="#92004D"
          strokeWidth="1.071"
          rx="2.364"
          transform="rotate(-45.404 27.216 29.743)"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M61.633 2.213l-.295 27.558c.011.221-.068.437-.219.601-.15.164-.36.262-.584.272l-40.691-.437c-.456-.035-.801-.421-.78-.873l.29-27.562c-.017-.46.343-.849.808-.873l40.686.437c.459.033.808.422.785.877z"
        />
        <path
          fill="#20458F"
          d="M62.135 29.779l.292-27.562c.047-.93-.663-1.724-1.591-1.781L18.459 0c-.928.038-1.653.816-1.626 1.746l-.292 27.558c-.05.931.66 1.728 1.591 1.785l42.377.436c.928-.038 1.653-.816 1.626-1.746zM60.862 2.222l-.292 27.523c-.016-.001-.031-.005-.044-.014l-42.377-.436c-.014.004-.029.004-.043 0l.292-27.51c.014-.006.03-.006.044 0l42.376.437c.014-.006.03-.006.044 0z"
        />
        <path
          fill="#20458F"
          fillRule="nonzero"
          d="M17.458 1.237L61.794 1.704 61.726 7.972 17.39 7.504zM33.811 12.064L48.378 12.218 48.357 14.099 33.791 13.945zM33.778 15.197L51.511 15.384 51.491 17.265 33.757 17.078zM34.093 17.89L48.661 17.89 48.661 19.771 34.093 19.771zM33.711 21.468L43.846 21.575 43.826 23.456 33.691 23.349zM22.727 11.382L32.228 11.382 32.228 22.664 22.727 22.664z"
        />
        <path
          fill="#FFFAE6"
          fillRule="nonzero"
          d="M30.11 23.057c0-3.194-.736-5.154-1.707-6.04.876-.585 1.26-1.669.948-2.667-.314-.999-1.251-1.676-2.307-1.667-1.057.008-1.983.701-2.28 1.705-.296 1.003.108 2.08.993 2.651-1.045.925-1.883 2.941-1.914 5.953l6.267.065z"
        />
        <path
          fill="#FDDAEC"
          fillRule="nonzero"
          d="M30.471 26.623c4.078 0 7.383-3.271 7.383-7.306s-3.305-7.306-7.383-7.306c-4.078 0-7.383 3.271-7.383 7.306s3.305 7.306 7.383 7.306z"
        />
        <path
          fill="#FAFBFF"
          fillRule="nonzero"
          d="M19.675 27.946s-1.323 5.74-7.617 6.281c-6.293.541-9.99 2.754-12.058-8.45l9.703-13.988 2.704-2.82 3.332-1.814h1.089v14.761l-7.46 3.787c-.507.284-.992.275-.644.742.282.38.926.039 1.35-.175l2.033-1.121 11.974-6.63 5.928-3.291c.384-.21 1.094.973 1.279 1.309.578 2.466.194 4.705-2.47 6.181l-9.077 4.766"
        />
        <path
          fill="#92004D"
          d="M27.54 19.968l-1.654-2.925c-.067-.116-.084-.254-.047-.382.036-.129.123-.238.24-.303l2.956-1.637c.244-.134.55-.048.688.193 1.043 1.855.374 4.196-1.495 5.237-.242.135-.548.054-.688-.183zM11.923 34.431c-.039-.068-.06-.144-.062-.222-.006-.133.042-.263.133-.362.09-.098.217-.156.352-.162 1.174-.042 2.32-.362 3.343-.934 1.871-1.036 3.2-2.821 3.643-4.897.038-.175.168-.317.34-.373.172-.055.361-.015.496.105s.194.302.157.478c-.507 2.358-2.016 4.386-4.142 5.564-1.162.649-2.464 1.013-3.797 1.061-.192.008-.371-.091-.463-.258zm16.23-15.45l-1.134-2.007 2.015-1.126c.396 1.124.044 2.373-.882 3.133zm-4.843-.502l1.244 2.182-.004.005c.084.165.253.272.44.277.187.004.362-.093.454-.254.093-.161.088-.36-.012-.516l-1.24-2.183c-.079-.172-.25-.285-.44-.293-.191-.007-.37.092-.464.257-.093.165-.085.368.022.525zm-1.248.677l.714 1.27.005-.005c.144.226.443.301.678.169.235-.132.325-.423.204-.662l-.72-1.265c-.083-.166-.252-.273-.44-.277-.187-.005-.361.092-.454.254-.092.161-.087.359.013.516z"
        />
        <path
          stroke="#92004D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth=".877"
          d="M17.075 6.719s-5.12 1.329-6.973 4.214C8.25 13.818.117 26.209.117 26.209"
        />
        <path
          fill="#92004D"
          fillRule="nonzero"
          d="M8.534 26.662c-.136-.243-.05-.548.194-.685l20.597-11.405c.628-.33 1.407-.11 1.764.498 1.622 2.876.584 6.508-2.32 8.118l-7.86 4.657c-.824.65-1.45.982-1.574.406-.123-.576.181-1.31 2.24-2.222l6.71-3.718c2.413-1.34 3.275-4.361 1.926-6.752-.078-.137-.253-.185-.392-.11L9.218 26.854c-.243.129-.546.044-.684-.192z"
        />
        <path fill="#4C61FF" d="M10.85 19.317l6.277 1.999-6.276-1.999z" />
        <path
          stroke="#92004D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth=".877"
          d="M10.851 19.317L17.127 21.316"
        />
      </g>
    </g>
  </SvgIcon>
);

export default NoObstacleIdIcon;
