import File from "models/File";
import UploadTypeStatus from "./UploadTypeStatus";

let files = [];

export const getFiles = () => files;

export const addFile = (file) => {
  const { id, documentId, uploadType } = file;

  const index = files.findIndex(
    (savedFile) => savedFile.documentId === documentId,
  );

  if (index === -1) {
    files.push(
      new File({
        id,
        documentId,
        uploadType,
      }),
    );
  }
};

export const deleteFile = (id) => {
  const index = files.findIndex((savedFile) => savedFile.id === id);

  if (index > -1) {
    files.splice(index, 1);
  }
};

export const deleteFilesByType = (type) => {
  files = getFiles().filter((file) => file.uploadType !== type);
};

export const deleteIdFiles = () => {
  files = getFiles().filter((file) => file.uploadType !== UploadTypeStatus.ID);
};

export const getNumberOfCompanyFiles = () =>
  getFiles().filter((file) => file.uploadType === UploadTypeStatus.COMPANY)
    .length;

export const getNumberOfIdFiles = () =>
  getFiles().filter((file) => file.uploadType === UploadTypeStatus.ID).length;

export default {
  getFiles,
  addFile,
  deleteFile,
  deleteIdFiles,
  getNumberOfCompanyFiles,
  getNumberOfIdFiles,
};
