import React from "react";
import { Link } from "@material-ui/core";
import Button from "components/Button";
import ErrorPage from "pages/ErrorPage";
import PreventManualRequestsProvider from "components/PreventManualRequests/PreventManualRequestsProvider";
import { useTranslation } from "react-i18next";

const ErrorMissingSiret = () => {
  const { t } = useTranslation();

  return (
    <PreventManualRequestsProvider preventFunction={() => true}>
      <ErrorPage
        title={t("errorMissingSiret:title")}
        subtitle={t("errorMissingSiret:subTitle")}
        action={
          <Link
            style={{ width: "100%" }}
            href={process.env.REACT_APP_ADV_MISSING_INFO}
            underline="none"
            data-testid="test-link"
          >
            <Button>{t("errorMissingSiret:cta")}</Button>
          </Link>
        }
        imgSrc="/establishment-info.png"
      />
    </PreventManualRequestsProvider>
  );
};

export default ErrorMissingSiret;
