import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { Box, Link, Typography } from "@material-ui/core";
import Step from "components/Layout/Step";
import CenteredTitle from "components/Layout/CenteredTitle";
import MainContent from "components/Layout/MainContent";
import Illustration from "components/Layout/Illustration";
import ArrowLinkIcon from "icons/arrowLink";
import Actions from "components/Layout/Actions";
import { getReturnUrl } from "utils/UrlUtil";
import Button from "components/Button";
import useServiceName from "hook/useServiceName";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  textInfo: {
    lineHeight: "18px",
  },
  orText: {
    margin: "4px 0px",
  },
}));

const ErrorCompanyNonDiffusible = () => {
  const classes = useStyles();

  const serviceName = useServiceName();
  const { t } = useTranslation();

  const title = t("errorCompanyNonDiffusible:title");

  return (
    <Step progress={100}>
      <Illustration>
        <img
          src="/establishment-info.png"
          alt={title}
          aria-hidden
          title={title}
        />
      </Illustration>
      <CenteredTitle>{title}</CenteredTitle>
      <MainContent>
        <Typography align="center" variant="body1">
          {t("errorCompanyNonDiffusible:content.part1")}
        </Typography>
        <ul>
          <li style={{ paddingBottom: "0px" }}>
            <Typography component="span" variant="body1">
              {t("errorCompanyNonDiffusible:content.part2")}{" "}
              <Link
                href="https://statut-diffusion-sirene.insee.fr/"
                target="_blank"
                underline="none"
              >
                {t("errorCompanyNonDiffusible:content.part3")}{" "}
                <ArrowLinkIcon viewBox="0 0 12 6" />
              </Link>
            </Typography>
            <Typography variant="body2" className={classes.textInfo}>
              {t("errorCompanyNonDiffusible:content.part4")}
            </Typography>
          </li>
          <Typography variant="body1" align="center" className={classes.orText}>
            {t("common:or")}
          </Typography>
          <li>
            <Typography component="span" variant="body1">
              {t("errorCompanyNonDiffusible:content.part5")}{" "}
              <Link
                href="https://aide.laposte.fr/professionnel/contenu/pourquoi-mon-numero-de-siret-n-est-pas-reconnu?t=cc"
                target="_blank"
                underline="none"
              >
                {t("errorCompanyNonDiffusible:content.part6")}{" "}
                <ArrowLinkIcon viewBox="0 0 12 6" />
              </Link>
            </Typography>
            <Typography variant="body2" className={classes.textInfo}>
              {t("errorCompanyNonDiffusible:content.part7")}
            </Typography>
          </li>
        </ul>
      </MainContent>
      <Actions>
        <Box mb={2} style={{ width: "100% " }}>
          <Link
            href={getReturnUrl({ error: true })}
            underline="none"
            data-testid="test-link"
          >
            <Button>
              {t("common:goTo")} {serviceName}
            </Button>
          </Link>
        </Box>
      </Actions>
    </Step>
  );
};

export default ErrorCompanyNonDiffusible;
