import React from "react";
import MuiButton from "@material-ui/core/Button";

const Button = (props) => (
  <MuiButton
    color="primary"
    variant="contained"
    disableElevation
    fullWidth
    {...props}
  />
);

export default Button;
