import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Actions from "components/Layout/Actions";
import CenteredTitle from "components/Layout/CenteredTitle";
import Illustration from "components/Layout/Illustration";
import MainContent from "components/Layout/MainContent";
import Button from "components/Button";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/styles/makeStyles";
import { getReturnUrl } from "utils/UrlUtil";
import UrlParametersKey from "utils/UrlParametersKey";
import Step from "components/Layout/Step";
import { useTranslation } from "react-i18next";
import usePartners from "hook/usePartners";

const useStyles = makeStyles(() => ({
  image: {
    width: 100,
    height: 100,
  },
  informationContainer: {
    textAlign: "center",
  },
}));

const CertificationSuccessAutomatic = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const serviceName = sessionStorage.getItem(UrlParametersKey.SERVICE_NAME);
  const {
    hasPartnerOffer,
    getPartnerCertificationSuccessAutomaticContent,
    getPartnerCertificationSuccessAutomaticCta,
  } = usePartners();

  const entry = sessionStorage.getItem(UrlParametersKey.ENTRY);

  const sendValidateButtonEvent = (event) => {
    if (typeof window.tc_events_20 === "function") {
      window.tc_events_20(event, "click", {
        event: "C",
        type_clic: "exit",
        chapter1: "certification-rl",
        chapter2: "etape-10",
        chapter3: entry || "direct",
        name: "acceder_espace_pro",
      });
    }
  };

  const buttonLabel = () => {
    if (serviceName) {
      return `${t("common:goTo")} ${serviceName}`;
    }
    if (hasPartnerOffer()) {
      return getPartnerCertificationSuccessAutomaticCta();
    }
    return t("common:goToService");
  };

  return (
    <Step progress={100}>
      <Illustration>
        <img
          className={classes.image}
          src="/certification.png"
          alt="Succès certification"
          aria-hidden
          title="Succès certification"
        />
      </Illustration>
      <CenteredTitle>{t("certificationSuccessAutomatic:title")}</CenteredTitle>
      <MainContent>
        <Typography variant="body1" className={classes.informationContainer}>
          {hasPartnerOffer() ? (
            getPartnerCertificationSuccessAutomaticContent()
          ) : (
            <>{t("certificationSuccessAutomatic:content.default")}</>
          )}
        </Typography>
      </MainContent>
      <Actions>
        <Box style={{ width: "100%" }}>
          <Link
            href={getReturnUrl()}
            underline="none"
            data-testid="test-button"
          >
            <Button onClick={sendValidateButtonEvent}>{buttonLabel()}</Button>
          </Link>
        </Box>
      </Actions>
    </Step>
  );
};

export default CertificationSuccessAutomatic;
