import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ImageFile = (props) => (
  <SvgIcon {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#3C3C3C" fillRule="nonzero">
        <g>
          <g>
            <path
              d="M17.5 3.333c.46 0 .833.373.833.834v11.666c0 .461-.372.834-.833.834h-15c-.46 0-.833-.373-.833-.834V4.167c0-.461.372-.834.833-.834h15zM16.667 5H3.333v10h13.334V5zm-3.49 2.739l2.52 2.304c.087.079.136.19.136.307v3.817H4.167V12.35c0-.117.049-.228.136-.307l1.77-1.619c.159-.146.403-.146.562 0L7.813 11.5l4.826-3.782c.16-.126.388-.117.538.02zm-7.76-1.906c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z"
              transform="translate(-30 -535) translate(16 517) translate(14 18)"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default ImageFile;
