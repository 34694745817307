let UPLOAD_TRIES = 0;

export const getUploadTries = () => UPLOAD_TRIES;

export const setUploadTries = (tryNumber) => {
  UPLOAD_TRIES = tryNumber;
};

export default {
  getUploadTries,
  setUploadTries,
};
