import { useTranslation } from "react-i18next";
import UrlParametersKey from "utils/UrlParametersKey";

const useServiceName = () => {
  const { t } = useTranslation();

  const serviceName = sessionStorage.getItem(UrlParametersKey.SERVICE_NAME);

  return serviceName || t("common:yourProCustomArea");
};
export default useServiceName;
