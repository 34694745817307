let AUTOMATIC_STATUS = false;
let MANUAL_SWITCH_STATUS = "";

export const getAutomaticStatus = () => AUTOMATIC_STATUS;

export const setAutomaticStatus = (status) => {
  AUTOMATIC_STATUS = status;
};

export const getManualSwitchStatus = () => MANUAL_SWITCH_STATUS;

export const setManualSwitchStatus = (status) => {
  MANUAL_SWITCH_STATUS = status;
};

export default {
  getAutomaticStatus,
  setAutomaticStatus,
  getManualSwitchStatus,
  setManualSwitchStatus,
};
