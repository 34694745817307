import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MoreSurroundedIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10.5107C0 4.99685 4.48611 0.510742 10 0.510742C15.5139 0.510742 20 4.99685 20 10.5107C20 16.0246 15.5139 20.5107 10 20.5107C4.48611 20.5107 0 16.0246 0 10.5107ZM9.99973 19.3996C5.09862 19.3996 1.11084 15.4118 1.11084 10.5107C1.11084 5.60963 5.09862 1.62185 9.99973 1.62185C14.9008 1.62185 18.8886 5.60963 18.8886 10.5107C18.8886 15.4118 14.9008 19.3996 9.99973 19.3996ZM10.5528 9.95519V7.17741C10.5528 6.87074 10.3044 6.62185 9.99722 6.62185C9.69 6.62185 9.44167 6.87074 9.44167 7.17741V9.95519H6.66389C6.35667 9.95519 6.10833 10.2041 6.10833 10.5107C6.10833 10.8174 6.35667 11.0663 6.66389 11.0663H9.44167V13.8441C9.44167 14.1507 9.69 14.3996 9.99722 14.3996C10.3044 14.3996 10.5528 14.1507 10.5528 13.8441V11.0663H13.3306C13.6378 11.0663 13.8861 10.8174 13.8861 10.5107C13.8861 10.2041 13.6378 9.95519 13.3306 9.95519H10.5528Z"
      fill="#555555"
    />
  </SvgIcon>
);

export default MoreSurroundedIcon;
