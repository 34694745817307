import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import makeStyles from "@material-ui/styles/makeStyles";
import Footer from "Footer";
import MessageContext from "components/MessageContext";
import Toaster, { types as ToasterTypes } from "components/Toaster";
import { useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: "flex",
    minHeight: `calc(100vh - ${props.footerSize.height}px)`,
    [theme.breakpoints.up("lg")]: {
      background: `url("/background.jpg") center center no-repeat fixed`,
      backgroundSize: "cover",
      justifyContent: "space-between",
      margin: 0,
      padding: 0,
      outline: 0,
    },
  }),
  formWrapper: {
    flex: "1 auto",
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-end",
      alignItems: "start",
    },
  },
  form: {
    position: "relative",
    flex: "1 auto",
    borderTop: `6px solid ${theme.palette.primary.main}`,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette["background-light"],
    [theme.breakpoints.up("sm")]: {
      width: "414px",
    },
    [theme.breakpoints.up("md")]: {
      width: "530px",
    },
    [theme.breakpoints.up("lg")]: {
      flex: "0 auto",
      marginTop: "60px",
      marginRight: "100px",
      width: "320px",
      marginBottom: 50,
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: "80px",
      width: "414px",
      marginRight: "120px",
      marginBottom: 50,
    },
  },
  formContent: {
    flex: "1 auto",
    padding: "0px 16px",
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
    overflow: "auto",
    [theme.breakpoints.up("md")]: {
      padding: "0px calc((100vw - 530px) / 2)",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0px 16px",
      overflow: "hidden",
      minHeight: "calc(70vh)",
    },
  },
  messages: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  image: {
    marginTop: "10px",
    marginBottom: "13px",
  },
  cotepro: {
    height: "34px",
    width: "88px",
  },
  children: {
    flex: "1 auto",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "32px",
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "40px",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "24px",
    },
  },
}));

const Layout = (props) => {
  const { children } = props;

  const [footerSize, setFooterSize] = useState({});

  const classes = useStyles({ footerSize });

  const location = useLocation();

  const [message, setMessage] = useState(null);
  const [footerRef, setFooterRef] = useState(null);

  const dispatchMessage = useCallback(
    (type) => (content) => {
      setMessage(
        content && {
          type,
          content,
          id: uuidv4(),
        },
      );
    },
    [],
  );

  const handleCloseMessage = () => {
    setMessage(null);
  };

  const handleResize = useCallback(() => {
    if (footerRef !== null) {
      const size = footerRef.getBoundingClientRect();
      setFooterSize({
        height: size.height,
        width: size.width,
      });
    }
  }, [footerRef]);

  // side effects at each navigation
  React.useEffect(() => {
    handleResize();
    setMessage(null);
  }, [location, handleResize]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.formWrapper}>
          <div className={classes.form}>
            {message && (
              <div className={classes.messages}>
                <Toaster type={message.type} onClose={handleCloseMessage}>
                  {message.content}
                </Toaster>
              </div>
            )}
            <main role="main" className={classes.formContent}>
              <div className={classes.image}>
                <img
                  className={classes.cotepro}
                  src="/logo.svg"
                  alt="Logo cotepro"
                  title="Logo cotepro"
                />
              </div>
              <MessageContext.Provider
                value={{
                  dispatchError: dispatchMessage(ToasterTypes.ERROR),
                  dispatchWarning: dispatchMessage(ToasterTypes.WARNING),
                  dispatchSuccess: dispatchMessage(ToasterTypes.SUCCESS),
                }}
              >
                <div className={classes.children}>{children}</div>
              </MessageContext.Provider>
            </main>
          </div>
        </div>
      </div>
      <Footer onRefChange={(newRef) => setFooterRef(newRef)} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {};

export default Layout;
