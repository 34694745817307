import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const VisibleIdIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 74 54">
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M45.331 10.257V38.03c.02.46-.333.851-.794.879H3.846c-.462-.026-.817-.418-.794-.879V10.257c-.023-.461.332-.854.794-.88h40.691c.461.028.814.42.794.88z"
          transform="translate(12 7)"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M44.538 9.462v28.205c.031.458-.337.855-.826.893H1.448c-.488-.04-.853-.437-.822-.893V9.462c-.03-.457.334-.853.822-.894h42.264c.489.038.857.435.826.894z"
          transform="translate(12 7)"
        />
        <path
          fill="#20458F"
          d="M46.121 38.03V10.257c.03-.932-.694-1.715-1.627-1.76H1.628c-.932.048-1.655.829-1.627 1.76V38.03c-.028.93.695 1.712 1.627 1.759h42.866c.933-.045 1.657-.828 1.627-1.759zm-1.583-27.747v27.738c-.004.009-.017.018-.044 0H1.629c-.014.006-.03.006-.044 0V10.283c.014-.005.03-.005.044 0h42.909z"
          transform="translate(12 7)"
        />
        <path
          fill="#20458F"
          fillRule="nonzero"
          d="M1.154 9.746L45.493 9.746 45.493 16.063 1.154 16.063z"
          transform="translate(12 7)"
        />
        <path
          fill="#20458F"
          d="M17.62 20.482h14.567v1.896H17.619v-1.896zm0 3.159h17.734v1.896H17.619v-1.896zm14.567 3.162H17.619v1.896h14.568v-1.896zm-14.568 3.159h10.136v1.896H17.619v-1.896zm-2.534-9.479h-9.5v11.371h9.5V20.483z"
          transform="translate(12 7)"
        />
        <path
          fill="#EDF3FF"
          fillRule="nonzero"
          d="M13.371 31.853c-.026-3.219-.79-5.19-1.764-6.07.87-.598 1.244-1.694.92-2.697-.323-1.003-1.267-1.676-2.324-1.657-1.056.019-1.975.727-2.26 1.741-.287 1.014.127 2.096 1.018 2.662-1.037.945-1.853 2.986-1.853 6.021h6.263z"
          transform="translate(12 7)"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M46.155 12.881c2.11 0 3.82-1.664 3.82-3.717s-1.71-3.717-3.82-3.717c-2.109 0-3.819 1.664-3.819 3.717s1.71 3.717 3.819 3.717z"
          transform="translate(12 7)"
        />
        <path
          fill="#92004D"
          d="M45.325.64c0-.354.288-.64.642-.64.346 0 .63.274.641.619v3.403c0 .354-.287.64-.641.64-.354 0-.642-.286-.642-.64V.64zM39.2 2.946c.097-.227.317-.378.564-.388v-.02c.168-.002.331.061.452.177l2.42 2.414c.162.161.225.397.166.618-.059.221-.232.393-.454.452-.221.06-.457-.004-.62-.165L39.309 3.62c-.164-.185-.206-.447-.109-.674zm12.959-.388c-.169-.001-.332.066-.452.185l-2.42 2.413c-.206.15-.304.409-.248.657.055.249.254.441.505.489.251.048.507-.058.651-.269l2.42-2.413c.182-.183.236-.458.136-.697-.099-.238-.333-.393-.592-.393v.028zm-9.953 5.249c.63-1.514 2.112-2.499 3.755-2.498 2.241.005 4.055 1.816 4.058 4.051 0 1.638-.99 3.116-2.508 3.742-1.519.627-3.266.28-4.427-.88-1.161-1.159-1.508-2.902-.878-4.415zm3.757-1.218c1.535 0 2.779 1.24 2.779 2.771s-1.244 2.771-2.779 2.771V6.589zM36.56 9.36c0-.353.287-.639.641-.639h3.42c.354 0 .641.286.641.639 0 .353-.287.64-.641.64h-3.42c-.354 0-.641-.287-.641-.64zm14.755-.639c-.354 0-.641.286-.641.639 0 .353.287.64.641.64h3.42c.354 0 .642-.287.642-.64 0-.353-.288-.639-.642-.639h-3.42zm-9.573 3.953c.12-.119.282-.185.452-.185v-.004c.17-.001.334.066.455.185.25.252.25.657 0 .909l-2.419 2.414c-.251.25-.657.25-.908 0s-.251-.655 0-.905l2.42-2.414zm8.016-.184c-.171-.002-.335.065-.456.185-.249.252-.249.657 0 .909l2.42 2.413c.25.25.657.25.907 0 .251-.25.251-.655 0-.905l-2.42-2.413c-.119-.12-.282-.186-.451-.185v-.004zm-4.421 2.196c0-.354.287-.64.641-.64h-.012c.172-.002.337.064.46.184s.192.284.193.456v3.415c0 .353-.287.639-.641.639-.354 0-.641-.286-.641-.639v-3.415z"
          transform="translate(12 7)"
        />
      </g>
    </g>
  </SvgIcon>
);

export default VisibleIdIcon;
