export const isTestModeAuthorized =
  process.env.REACT_APP_ENV === "local" ||
  process.env.REACT_APP_ENV === "integration";

export const TEST_MODE_STATUS = "TEST_MODE";

export const AUTOMATIC_SUCCESS_OPTIONS = {
  REAL_PATH: "REAL_PATH",
  AUTOMATIC_SUCCESS: "AUTOMATIC_SUCCESS",
};

export const COMPANY_STATUS_OPTIONS = {
  REAL_PATH: "REAL_PATH",
  NO_COCLICO: "NO_COCLICO",
  INVALID_COCLICO: "INVALID_COCLICO",
  NO_SIRET: "NO_SIRET",
  INACTIVE_SIRET: "INACTIVE_SIRET",
  IS_NOT_DIFFUSABLE: "IS_NOT_DIFFUSABLE",
  INVALID_ADRESS: "INVALID_ADRESS",
};

export const ACCOUNT_STATUS_OPTIONS = {
  REAL_PATH: "REAL_PATH",
  CERTIFICATION_IN_PROCESS: "CERTIFICATION_IN_PROCESS",
  IS_ALREADY_CERTIFIED: "IS_ALREADY_CERTIFIED",
};

export const LEGAL_REPRESENTATION_OPTIONS = {
  REAL_PATH: "REAL_PATH",
  NO_LEGAL_REPRESENTATIVE: "NO_LEGAL_REPRESENTATIVE",
};

export const ID_STATUS_OPTIONS = {
  REAL_PATH: "REAL_PATH",
  NOT_READABLE: "NOT_READABLE",
  IS_EXPIRED: "IS_EXPIRED",
  NOT_VALID: "NOT_VALID",
};

export const RESULT_OPTIONS = {
  AUTOMATIC_CERTIFICATION: "AUTOMATIC_CERTIFICATION",
  MANUAL_CERTIFICATION: "MANUAL_CERTIFICATION",
  IMPOSSIBLE_CERTIFICATION: "IMPOSSIBLE_CERTIFICATION",
};

let TEST_MODE = sessionStorage.getItem(TEST_MODE_STATUS) === "true";
let AUTOMATIC_SUCCESS = null;
let COMPANY_STATUS = [];
let ACCOUNT_STATUS = null;
let LEGAL_REPRESENTATIVE_STATUS = null;
let ID_STATUS = null;
let RESULT = null;

export const setIsTestMode = (mode) => {
  TEST_MODE = mode;
};

export const getIsTestMode = () => TEST_MODE;

export const setIsAutomaticSuccess = (automaticSuccess) => {
  AUTOMATIC_SUCCESS = automaticSuccess;
};

export const getIsAutomaticSuccess = () => AUTOMATIC_SUCCESS;

export const setCompanyStatus = (status) => {
  COMPANY_STATUS = status;
};

export const getCompanyStatus = () => COMPANY_STATUS;

export const setAccountStatus = (status) => {
  ACCOUNT_STATUS = status;
};

export const getLegalRepresentativeStatus = () => LEGAL_REPRESENTATIVE_STATUS;

export const setLegalRepresentativeStatus = (status) => {
  LEGAL_REPRESENTATIVE_STATUS = status;
};

export const getAccountStatus = () => ACCOUNT_STATUS;

export const setIdStatus = (status) => {
  ID_STATUS = status;
};

export const getIdStatus = () => ID_STATUS;

export const setResult = (result) => {
  RESULT = result;
};

export const getResult = () => RESULT;

export default {
  setIsTestMode,
  getIsTestMode,
  setIsAutomaticSuccess,
  getIsAutomaticSuccess,
  setCompanyStatus,
  getCompanyStatus,
  setAccountStatus,
  getAccountStatus,
  setLegalRepresentativeStatus,
  getLegalRepresentativeStatus,
  setIdStatus,
  getIdStatus,
  setResult,
  getResult,
};
