import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import makeStyles from "@material-ui/styles/makeStyles";
import Step from "components/Layout/Step";
import MessageContext from "components/MessageContext";
import { Box, Link, Typography } from "@material-ui/core";
import ImageFile from "icons/imageFile";
import PdfFile from "icons/pdfFile";
import MoreSurroundedIcon from "icons/moreSurrounded";
import StaticLoaderIcon from "icons/StaticLoader";
import RedTrashIcon from "icons/redTrash";
import FileService from "services/FileService";
import Button from "components/Button";
import Actions from "components/Layout/Actions";
import MainContent from "components/Layout/MainContent";
import { addFile, deleteFile, getNumberOfCompanyFiles } from "utils/FileUtil";
import classnames from "classnames";
import UploadTypeStatus from "utils/UploadTypeStatus";
import Toaster, { types as toasterTypes } from "components/Toaster";
import { useTranslation } from "react-i18next";
import useUpload from "hook/useUpload";

const useStyles = makeStyles((theme) => ({
  labelDetails: {
    fontSize: 14,
    marginBottom: theme.spacing(2),
    "& .detailsList": {
      "& ul": {
        listStylePosition: "initial",
        listStyleType: "disc",
        color: theme.palette["background-dark"],
        fontSize: 14,
        lineHeight: "20px",
        "& li": {
          "&::marker": {
            color: theme.palette["background-dark"],
            fontSize: 18,
          },
          "& .strongLabel": {
            fontWeight: "bold",
          },
        },
      },
    },
  },
  headerFileDesc: {
    fontSize: 12,
    lineHeight: "18px",
    color: theme.palette["01-body-background-charte-gris-6-tex"],
    margin: 0,
    textAlign: "center",
  },
  uploadItemContainer: {
    backgroundColor: "white",
    color: theme.palette["01-body-background-charte-gris-6-tex"],
    border: `1px solid ${theme.palette["body-background-charte-gris-4"]}`,
    height: 49,
    padding: "12px 12px 12px 42px",
    position: "relative",
    userSelect: "none",
    cursor: "pointer",
    borderRadius: 10,
    marginBottom: "12px",
  },
  uploadItemContainerDone: {
    borderWidth: 0,
  },
  uploading: {
    height: 57,
    padding: "16px 42px 16px 42px",
    cursor: "default",
    "& p": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 234,
    },
  },
  plusIcon: {
    position: "absolute",
    top: 13,
    left: 14,
    width: 20,
    height: 21,
    margin: "0 8px 0 0",
    objectFit: "contain",
  },
  fileIcon: {
    position: "absolute",
    top: 16,
    left: 14,
    width: 20,
    height: 20,
    margin: "0 8px 0 0",
    objectFit: "contain",
    zIndex: 10,
  },
  fileNameLabel: {
    zIndex: 10,
    position: "relative",
    fontWeight: 500,
    color: theme.palette["01-body-background-charte-gris-6-tex"],
  },
  uploadProgress: {
    zIndex: 1,
    backgroundColor: theme.palette["-body-background-charte-gris-2"],
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    transition: "width 0.5s ease-in-out",
    borderBottomLeftRadius: 10,
    borderTopLeftRadius: 10,
  },
  uploadDone: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: "white",
  },
  deleteFileBtnContainer: {
    height: 20,
    width: 20,
    position: "absolute",
    top: 16,
    right: 16,
    zIndex: 10,
    cursor: "pointer",
  },
  finishedFileBtnContainer: {
    height: 22,
    width: 22,
    position: "absolute",
    top: 22,
    right: 16,
    zIndex: 10,
  },
  toasterPappers: {
    borderRadius: "8px",
  },
}));

const UploadFile = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const { location, uploadType, subdelegate } = props;
  const { state } = location;
  const { access } = state;
  const [progress, setProgress] = useState(0);
  const { dispatchError } = useContext(MessageContext);
  const {
    getUploadItems,
    uploadItems,
    getUploadLabelDetails,
    getReturnUrl,
    getUploadTitlePage,
    getFileIcon,
    getWarningLabels,
  } = useUpload();

  const isCompanyFiles =
    state?.access.split("_")[0] === "COMPANY" &&
    state?.access.split("_")[1] !== "SUBDOC";

  const toUploadItems = [];
  if (state !== undefined) {
    getUploadItems(state.access).forEach((item) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      toUploadItems.push({ id: item.id, reference: useRef(null) });
    });
  }

  const getRedirectUrl = useCallback(() => {
    switch (uploadType) {
      case UploadTypeStatus.COMPANY:
        return "/choix-statut-societe";
      case UploadTypeStatus.ID:
        return "/choix-piece-identite";
      case UploadTypeStatus.ID_RL:
        return "/certification-rl-subdelegataire/choix-piece-identite-representant-legal";
      default:
        return "/";
    }
  }, [uploadType]);

  useEffect(() => {
    if (state === undefined) {
      history.push(getRedirectUrl());
    }
  }, [getRedirectUrl, history, state]);

  useEffect(() => {
    setItems(getUploadItems(access));
  }, [access, getUploadItems, uploadItems]);

  const getFile = (itemId) => {
    toUploadItems.find((item) => item.id === itemId).reference.current.click();
  };

  const deleteFileFunc = (id) => {
    const newUploadItems = [...items];

    newUploadItems.find((item) => item.id === id).file = null;
    newUploadItems.find((item) => item.id === id).documentId = null;
    newUploadItems.find((item) => item.id === id).progress = 0;

    deleteFile(id);
    setItems(newUploadItems);
  };

  const uploadFile = (file, id) => {
    // Delete previous messages if there are any
    dispatchError(null);
    const getFileUploadProgress = (progressValue) => {
      const newUploadItems = [...items];
      newUploadItems.find((item) => item.id === id).progress = progressValue;

      setItems(newUploadItems);
    };

    FileService.upload(file, access.split("_")[0], getFileUploadProgress)
      .then((response) => {
        const { data } = response;
        const documentId = data.id;

        const newUploadItems = [...items];
        newUploadItems.find((item) => item.id === id).documentId = documentId;
        addFile({ id, documentId, uploadType });
        setItems(newUploadItems);
      })
      .catch((error) => {
        const { response } = error;
        let warningLabels = "none";

        if (response && response.status) {
          const { status } = response;

          if (response.data) {
            const { data } = response;
            const errorTitle = data.title;

            warningLabels = getWarningLabels(status, errorTitle);
          } else {
            warningLabels = getWarningLabels(status, "error");
          }
        }

        deleteFileFunc(id);
        dispatchError(
          <>
            <Box> {warningLabels.title}</Box>
            <Box>
              <strong>{warningLabels.content}</strong>
            </Box>
          </>,
        );
      });
  };

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    const { id } = event.target;
    const newUploadItems = [...items];

    // On vérifie que le document ne dépasse pas la taille maximum
    if (file.size <= parseInt(process.env.REACT_APP_FILE_MAX_SIZE, 10)) {
      newUploadItems.find((item) => item.id === id).file = file;

      setItems(newUploadItems);
      uploadFile(file, id);
    } else {
      dispatchError(null);

      const warningLabels = getWarningLabels(413, "error");

      dispatchError(
        <>
          <Box> {warningLabels.title}</Box>
          <Box>
            <strong>{warningLabels.content}</strong>
          </Box>
        </>,
      );
    }
  };

  const isUploadFinished = (item) =>
    item.progress === 100 && item.documentId === null;

  const isUploadDone = (item) =>
    item.progress === 100 && item.documentId !== null;

  const allDocumentsAreUploaded = () =>
    items.every((item) => item.documentId !== null);

  const goToNextPage = () => {
    if (access.split("_")[0] === "COMPANY" && !subdelegate) {
      history.push("/choix-piece-identite");
    } else if (access.split("_")[0] === "COMPANY" && subdelegate) {
      history.push("/analyse-pi");
    } else if (subdelegate) {
      if (access.slice(-2) !== "RL") {
        history.push(
          "/certification-rl-subdelegataire/choix-piece-identite-representant-legal",
        );
      } else {
        history.push({
          pathname:
            "/certification-rl-subdelegataire/import/attestation-subdelegation",
          state: { access: "COMPANY_SUBDOC" },
        });
      }
    } else {
      history.push("/analyse-pi");
    }
  };

  useEffect(() => {
    let newProgress = 0;
    if (subdelegate) {
      if (uploadType === "piece-identite") {
        newProgress = 55;
      }
      if (uploadType === "piece-identite-representant-legal") {
        newProgress = 69;
      }
      if (uploadType === "attestation-subdelegation") {
        newProgress = 77;
      }
    } else {
      newProgress = 73;
      if (uploadType === "justificatifs-societe") {
        newProgress = 54;
      }
      // Si il y a des justificatifs de societe upload donc pas cas nominal
      if (getNumberOfCompanyFiles() > 0) {
        newProgress = 91;
      }
    }

    setProgress(newProgress);
  }, [subdelegate, uploadType]);

  return (
    <Step
      title={getUploadTitlePage(access, subdelegate)}
      previousStepPath={getReturnUrl(access, subdelegate) || "/"}
      progress={progress}
    >
      <MainContent>
        <Box className={classes.labelDetails}>
          {getUploadLabelDetails(access, subdelegate)}
        </Box>
        {items.length > 0 && (
          <>
            {isCompanyFiles && (
              <Box mt={1.5} mb={2.5}>
                <Toaster
                  type={toasterTypes.INFO}
                  className={classes.toasterPappers}
                >
                  {t("uploadFile:pappers.part1")}{" "}
                  <Link href="https://www.pappers.fr" target="_blank">
                    Pappers.fr
                  </Link>{" "}
                  {t("uploadFile:pappers.part2")}
                </Toaster>
              </Box>
            )}
            <Box>
              {items.map((uploadItem) => (
                <Box key={uploadItem.id}>
                  {uploadItem.file !== null ? (
                    <Box
                      className={classnames(
                        classes.uploadItemContainer,
                        classes.uploading,
                        uploadItem.progress === 100 &&
                          classes.uploadItemContainerDone,
                      )}
                    >
                      {getFileIcon(uploadItem.file.type) === "IMAGE" ? (
                        <ImageFile
                          className={classes.fileIcon}
                          viewBox="0 0 20 20"
                        />
                      ) : (
                        <PdfFile
                          className={classes.fileIcon}
                          viewBox="0 0 20 20"
                        />
                      )}
                      <Typography
                        variant="body1"
                        className={classes.fileNameLabel}
                      >
                        {uploadItem.file.name}
                      </Typography>
                      <Box
                        className={classnames(
                          classes.uploadProgress,
                          uploadItem.progress === 100 && classes.uploadDone,
                        )}
                        style={{ width: `${uploadItem.progress}%` }}
                      />
                      {isUploadFinished(uploadItem) && (
                        <Box className={classes.finishedFileBtnContainer}>
                          <StaticLoaderIcon viewBox="0 0 22 22" />
                        </Box>
                      )}
                      {isUploadDone(uploadItem) && (
                        <Box
                          tabindex="0"
                          onClick={() => deleteFileFunc(uploadItem.id)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              deleteFileFunc(uploadItem.id);
                            }
                          }}
                          className={classes.deleteFileBtnContainer}
                        >
                          <RedTrashIcon viewBox="0 0 20 20" />
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <Box
                      tabindex="0"
                      className={classes.uploadItemContainer}
                      onClick={() => getFile(uploadItem.id)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          getFile(uploadItem.id);
                        }
                      }}
                    >
                      <MoreSurroundedIcon
                        className={classes.plusIcon}
                        viewBox="0 0 20 21"
                      />
                      <Typography variant="body1">
                        {uploadItem.label()}
                      </Typography>
                      <input
                        id={uploadItem.id}
                        type="file"
                        accept=".png,.jpg,.jpeg,.pdf"
                        ref={
                          toUploadItems.find(
                            (item) => item.id === uploadItem.id,
                          ).reference
                        }
                        onChange={onChangeFile}
                        style={{ display: "none" }}
                      />
                    </Box>
                  )}
                </Box>
              ))}
              <Box component="p" className={classes.headerFileDesc}>
                {t("uploadFile:acceptedFormats")}
              </Box>
              <Box component="p" className={classes.headerFileDesc}>
                {t("uploadFile:maxSize")}
              </Box>
            </Box>
          </>
        )}
      </MainContent>
      <Actions>
        <Button
          data-testid="nextpageBtn"
          disabled={!allDocumentsAreUploaded()}
          onClick={() => {
            goToNextPage();
          }}
        >
          {t("common:continue")}
        </Button>
      </Actions>
    </Step>
  );
};

UploadFile.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      access: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  uploadType: PropTypes.string.isRequired,
  subdelegate: PropTypes.bool,
};

UploadFile.defaultProps = {
  subdelegate: false,
};

export default UploadFile;
