import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Actions from "components/Layout/Actions";
import CenteredTitle from "components/Layout/CenteredTitle";
import Illustration from "components/Layout/Illustration";
import MainContent from "components/Layout/MainContent";
import Button from "components/Button";
import Link from "components/Link";
import MuiLink from "@material-ui/core/Link";
import makeStyles from "@material-ui/styles/makeStyles";
import { Checkbox, FormControlLabel, Link as LinkECP } from "@material-ui/core";
import UserService from "services/UserService";
import { getReturnUrl } from "utils/UrlUtil";
import Step from "components/Layout/Step";
import useServiceName from "hook/useServiceName";
import { useTranslation } from "react-i18next";
import usePartners from "hook/usePartners";
import Toaster from "components/Toaster";

const useStyles = makeStyles((theme) => ({
  link: {
    fontWeight: 700,
    color: theme.palette.cerulean,
  },
  labelCgu: {
    fontSize: "12px",
  },
  cgu: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: "30px",
  },
  toaster: {
    margin: theme.spacing(2.5, 0),
  },
}));

const Home = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const {
    hasPartnerOffer,
    getPartnerHomeLogo,
    getPartnerHomeTitle,
    getPartnerHomeContent,
  } = usePartners();
  const serviceName = useServiceName();

  const [checked, setChecked] = useState(false);

  const title = hasPartnerOffer() ? getPartnerHomeTitle() : t("home:title");

  const cancelSubButtonUrl = `${process.env.REACT_APP_CLIENT_LOGOUT_URL}?source=switch&callbackUrl=${process.env.REACT_APP_CLIENT_AUTH_URL}`;

  const createCertificationEvent = (event) => {
    if (typeof window.tc_events_20 === "function") {
      window.tc_events_20(event, "click", {
        event: "C",
        type_clic: "action",
        chapter1: "certification-rl",
        chapter2: "etape-1",
        chapter3: "",
        name: "certifier_mon_compte",
      });
    }
  };

  const accessClientSpaceEvent = (event) => {
    if (typeof window.tc_events_20 === "function") {
      window.tc_events_20(event, "click", {
        event: "C",
        type_clic: "navigation",
        chapter1: "certification-rl",
        chapter2: "etape-1",
        chapter3: "",
        name: "acceder_a_mon_espace_client",
      });
    }
  };

  const handleChange = (event) => {
    const checkedValue = event.target.checked;
    setChecked(checkedValue);
    UserService.setAcceptCgu(checkedValue);
  };

  return (
    <Step progress={9}>
      <Illustration>
        <img src={getPartnerHomeLogo()} alt={title} aria-hidden title={title} />
      </Illustration>
      <CenteredTitle>{title}</CenteredTitle>
      <MainContent>
        <Typography variant="body1" component="div">
          <Grid container spacing={1} direction="column">
            {hasPartnerOffer() ? (
              <Grid item style={{ textAlign: "center" }}>
                {t("home:partnerContent")}{" "}
                <span style={{ fontWeight: "bold" }}>
                  {getPartnerHomeContent()}
                </span>
              </Grid>
            ) : (
              <Grid item>{t("home:content")}</Grid>
            )}
            {hasPartnerOffer() ? (
              <Toaster className={classes.toaster}>
                {t("home:partnerInfoIdentityCard.part1")}{" "}
                <span style={{ fontWeight: "bold" }}>
                  {t("home:partnerInfoIdentityCard.part2")}
                </span>{" "}
                {t("home:partnerInfoIdentityCard.part3")}
              </Toaster>
            ) : (
              <Grid item>
                {t("home:makeSureValidId.part1")}{" "}
                <b>{t("home:makeSureValidId.part2")}</b>
                {t("home:makeSureValidId.part3")}
              </Grid>
            )}
          </Grid>
        </Typography>
      </MainContent>
      <Box mt={3}>
        <FormControlLabel
          style={{ alignItems: "flex-start" }}
          control={
            <Checkbox
              checked={checked}
              onChange={handleChange}
              style={{ padding: "0px 9px" }}
              data-testid="test-checkbox"
            />
          }
          label={
            <span className={classes.labelCgu}>
              {t("home:acceptCgu.part1")}{" "}
              <Typography
                className={classes.link}
                variant="body2"
                component={MuiLink}
                href={process.env.REACT_APP_CGU_URL}
                target="_blank"
              >
                {t("home:acceptCgu.part2")}
              </Typography>
            </span>
          }
        />
      </Box>
      <Actions>
        <Box mb={2} style={{ width: "100%" }}>
          <Link
            to="/verification-societe"
            underline="none"
            onClick={(event) => {
              createCertificationEvent(event);
            }}
          >
            <Button disabled={!checked} data-testid="test-button">
              {hasPartnerOffer ? t("home:partner.cta") : t("common:continue")}
            </Button>
          </Link>
        </Box>
        {hasPartnerOffer() ? (
          <LinkECP href={cancelSubButtonUrl} className={classes.link}>
            <Typography variant="body2" className={classes.link}>
              {t("common:unsubscribe")}
            </Typography>
          </LinkECP>
        ) : (
          <LinkECP
            href={getReturnUrl()}
            className={classes.link}
            onClick={(event) => {
              accessClientSpaceEvent(event);
            }}
          >
            <Typography variant="body2" className={classes.link}>
              {t("common:access")} {serviceName}
            </Typography>
          </LinkECP>
        )}
      </Actions>
    </Step>
  );
};

export default Home;
