export const formatSettings = (settingsArray) => {
  let newSettings = {};

  (settingsArray || []).forEach((setting) => {
    newSettings = { ...newSettings, [setting.key]: setting.value };
  });

  return newSettings;
};

export default {
  formatSettings,
};
