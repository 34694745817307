import React from "react";
import { Link } from "@material-ui/core";
import Button from "components/Button";
import ErrorPage from "pages/ErrorPage";
import { useTranslation } from "react-i18next";

const ErrorMissingCoclico = () => {
  const { t } = useTranslation();

  return (
    <ErrorPage
      title={t("errorMissingCoclico:title")}
      subtitle={t("errorMissingCoclico:subTitle")}
      action={
        <Link
          style={{ width: "100%" }}
          href={process.env.REACT_APP_ADV_MISSING_COCLICO}
          underline="none"
          data-testid="test-link"
        >
          <Button>{t("errorMissingCoclico:action")}</Button>
        </Link>
      }
      imgSrc="/establishment-location.png"
    />
  );
};

export default ErrorMissingCoclico;
