import React, { useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Lottie from "lottie-react";
import Typography from "@material-ui/core/Typography";
import Step from "components/Layout/Step";
import animationData from "animations/analysis.json";
import { getFiles } from "utils/FileUtil";
import {
  getAutomaticStatus,
  setManualSwitchStatus,
  getManualSwitchStatus,
  setAutomaticStatus,
} from "utils/AutomaticStatusUtil";
import CertificationService from "services/CertificationService";
import CertificationStatus from "utils/CertificationStatus";
import { useSettings } from "components/Settings";
import ManualSwitchStatus from "utils/ManualSwitchStatus";
import CertificationProcessType from "utils/CertificationProcessType";
import { setIsCertified } from "utils/UserUtil";
import CenteredTitle from "components/Layout/CenteredTitle";
import { useTranslation } from "react-i18next";
import { getUploadTries } from "utils/UploadFileUtil";

const IdAnalysis = () => {
  const history = useHistory();
  const { automaticProcessEnabled } = useSettings();
  const { t } = useTranslation();
  const errorCount = useRef(0);
  const maxRetry = process.env.REACT_APP_MAX_RETRY_GET_CERTIFICATION || 10;
  const maxRetryUpload = process.env.REACT_APP_MAX_RETRY_UPLOAD || 3;
  const IntervalRetryUpload =
    process.env.REACT_APP_INTERVAL_RETRY_UPLOAD || 10000;

  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const updateCertificationStatus = useCallback(
    (certificationId, type) => {
      const keepGoingStatus = [
        CertificationStatus.CERTIFICATION_STARTED,
        CertificationStatus.LINK_CREATION,
        CertificationStatus.READ,
        CertificationStatus.CERTIFICATION_CREATION,
      ];

      const unavailableServiceStatus = [
        CertificationStatus.CERTIFICATION_STARTED_ERROR,
        CertificationStatus.CERTIFICATION_CREATION_ERROR,
        CertificationStatus.LINK_CREATION_ERROR,
        CertificationStatus.LINK_MANAGEMENT_ERROR,
      ];

      const notValidDocumentsStatus = [
        CertificationStatus.READ_RETRY_DOCUMENT_NOT_READABLE,
        CertificationStatus.READ_RETRY_DOCUMENT_MULTIPLE_MRZ,
      ];

      CertificationService.get(certificationId)
        .then((response) => {
          const { type: responseType, status } = response.data;

          if (errorCount.current < maxRetry) {
            if (!keepGoingStatus.includes(status)) {
              if (unavailableServiceStatus.includes(status)) {
                history.push("/service-indisponible");
              } else if (
                status === CertificationStatus.READ_RETRY_DOCUMENT_BAD_SCORE
              ) {
                history.push("/analyse-pi-erreur1");
              } else if (notValidDocumentsStatus.includes(status)) {
                history.push("/analyse-pi-erreur2");
              } else if (
                status === CertificationStatus.READ_RETRY_DOCUMENT_NOT_VALID
              ) {
                history.push("/analyse-pi-erreur3");
              } else if (
                status === CertificationStatus.CERTIFICATION_COMPLETED
              ) {
                if (responseType === CertificationProcessType.AUTOMATIC) {
                  setIsCertified(true);
                  history.push("/compte-certifie");
                } else {
                  setAutomaticStatus(false);
                  const motifBascule = getManualSwitchStatus();
                  if (motifBascule === undefined || motifBascule === "") {
                    setManualSwitchStatus(ManualSwitchStatus.API_READ_BACK);
                  }
                  history.push("/demande-certification");
                }
              } else {
                // pour les cas non gérés
                history.push("/service-indisponible");
              }
            } else {
              setTimeout(() => {
                updateCertificationStatus(certificationId, type);
              }, IntervalRetryUpload);
            }
          } else {
            history.push("/service-indisponible");
          }
        })
        .catch(() => {
          errorCount.current += 1;
          if (errorCount.current < maxRetry) {
            setTimeout(() => {
              updateCertificationStatus(certificationId, type);
            }, IntervalRetryUpload);
          } else {
            // Si pas de réponse au bout de maxRetry alors service indisponible
            history.push("/service-indisponible");
          }
        });
    },
    [history, maxRetry, IntervalRetryUpload],
  );

  const getProcessType = useCallback(() => {
    if (
      CertificationService.getProcurationStatus() ===
      "FOUND_WITH_DIFFERENT_EMAIL"
    ) {
      return CertificationProcessType.DIRECT;
    }
    if (
      automaticProcessEnabled &&
      getAutomaticStatus() &&
      getUploadTries() < maxRetryUpload
    ) {
      return CertificationProcessType.AUTOMATIC;
    }
    return CertificationProcessType.MANUAL;
  }, [automaticProcessEnabled, maxRetryUpload]);

  useEffect(() => {
    const type = getProcessType();

    const filteredFiles = getFiles().filter(
      (file) =>
        type === CertificationProcessType.DIRECT ||
        type === CertificationProcessType.MANUAL ||
        (type === CertificationProcessType.AUTOMATIC &&
          file.uploadType === "piece-identite"),
    );

    const documentIds = filteredFiles.map((file) => file.documentId);

    if (!getAutomaticStatus()) {
      if (!automaticProcessEnabled) {
        setManualSwitchStatus(ManualSwitchStatus.AUTOMATIC_PROCESS_DISABLED);
      }

      if (getUploadTries() >= maxRetryUpload) {
        setManualSwitchStatus(ManualSwitchStatus.MAX_TRIALS_EXCEEDED);
      }
    }

    CertificationService.create(documentIds, type)
      .then((response) => {
        if (response && response.status === 201) {
          const { id } = response.data || {};
          const certificationId = id;
          setTimeout(() => {
            updateCertificationStatus(certificationId, type);
          }, IntervalRetryUpload);
        }
      })
      .catch(() => {
        history.push("/service-indisponible");
      });
  }, [
    updateCertificationStatus,
    automaticProcessEnabled,
    history,
    maxRetryUpload,
    IntervalRetryUpload,
    getProcessType,
  ]);

  return (
    <>
      <Step title="" progress={91}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
        >
          <Lottie
            {...animationOptions}
            style={{ height: "150px", width: "140px" }}
          />
          <CenteredTitle>{t("idAnalysis:idCardBeignAnalyzed")}</CenteredTitle>
          <Typography variant="body1">
            {t("idAnalysis:takeFewMinutes")}
          </Typography>
        </Grid>
      </Step>
    </>
  );
};

export default IdAnalysis;
