import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const FullIdIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 74 54">
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          fill="#20458F"
          fillRule="nonzero"
          d="M58.346 13.225V39.1c.029.867-.671 1.597-1.572 1.639H15.358c-.901-.044-1.599-.773-1.573-1.639V13.225c-.026-.867.672-1.595 1.573-1.639h41.416c.901.042 1.601.772 1.572 1.639z"
          transform="translate(1 1)"
        />
        <path
          fill="#20458F"
          fillRule="nonzero"
          d="M14.526 12.686L57.786 12.686 57.786 18.621 14.526 18.621z"
          transform="translate(1 1)"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M14.879 18.568L57.089 18.568 57.089 39.298 14.879 39.298z"
          transform="translate(1 1)"
        />
        <path
          fill="#20458F"
          d="M28.121 22.775h-9.269v10.686h9.269V22.775zm2.476.001h14.214v1.781H30.597v-1.781zm0 2.967h17.304v1.782H30.597v-1.782zm14.214 2.973H30.597v1.781h14.214v-1.781zm-14.214 2.968h9.889v1.781h-9.889v-1.781z"
          transform="translate(1 1)"
        />
        <path
          fill="#EDF3FF"
          fillRule="nonzero"
          d="M26.447 33.461c-.026-3.026-.77-4.878-1.721-5.705.848-.562 1.213-1.592.898-2.534-.316-.943-1.237-1.576-2.268-1.558-1.03.019-1.927.683-2.206 1.636-.279.953.124 1.97.994 2.502-1.011.888-1.808 2.806-1.808 5.659h6.111z"
          transform="translate(1 1)"
        />
        <path
          stroke="#92004D"
          strokeDasharray="2.85"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.004"
          d="M62.711 0L62.711 52.19M9.059 0L9.059 52.19M71.287 9.022L0 9.022M71.789 43.659L.529 43.659"
          transform="translate(1 1)"
        />
      </g>
    </g>
  </SvgIcon>
);

export default FullIdIcon;
