import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Step from "components/Layout/Step";
import IdSelectionStep from "components/IdSelectionStep";
import Box from "@material-ui/core/Box";
import Actions from "components/Layout/Actions";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

const SubIdSelection = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [idType, setIdType] = useState("IDENTITY_IDCARD");

  const goToUpload = () => {
    history.push({
      pathname: "/certification-rl-subdelegataire/import/piece-identite",
      state: { access: idType },
    });
  };

  return (
    <Step
      title={t("subIdSelection:title")}
      previousStepPath="/certification-rl-subdelegataire"
      progress={46}
    >
      <IdSelectionStep idType={idType} setIdType={setIdType} />
      <Actions>
        <Box mb={2} style={{ width: "100%" }} onClick={goToUpload}>
          <Button>{t("common:continue")}</Button>
        </Box>
      </Actions>
    </Step>
  );
};

export default SubIdSelection;
