import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Icon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.414 12l5.293-5.293c.391-.39.391-1.023 0-1.414-.39-.39-1.023-.39-1.414 0L12 10.586 6.707 5.293c-.39-.39-1.023-.39-1.414 0-.39.391-.39 1.023 0 1.414L10.586 12l-5.293 5.293c-.39.391-.39 1.023 0 1.414.391.391 1.023.391 1.414 0L12 13.414l5.293 5.293c.195.195.451.293.707.293.256 0 .512-.098.707-.293.391-.39.391-1.023 0-1.414L13.414 12z"
    />
  </SvgIcon>
);

export default Icon;
