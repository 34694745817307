import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    borderRadius: 8,
    cursor: "pointer",
    fontSize: 14,
    padding: "12px 16px 12px 18px",
  },
  primary: {
    background: theme.palette["brand-02-blue-01-default"],
    color: theme.palette["background-light"],
    "& p": {
      color: theme.palette["background-light"],
      fontSize: 14,
    },
  },
  secondary: {
    background: "#f0f6ff",
    color: theme.palette["01-body-background-charte-gris-6-tex"],
    "& p": {
      color: theme.palette["01-body-background-charte-gris-6-tex"],
      fontSize: 14,
    },
  },
  tertiary: {
    background: theme.palette["background-light"],
    border: `1px solid ${theme.palette["neutral-neutral-3"]}`,
    color: "#363636",
    "& p": {
      color: "#363636",
      fontSize: 14,
    },
  },
}));
const MiniCard = (props) => {
  const { children, className, type, onClick, icon, ...others } = props;
  const classes = useStyles();

  return (
    <Box
      className={classnames(classes.root, classes[type], className)}
      onClick={onClick}
      {...others}
    >
      {children}
    </Box>
  );
};
MiniCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  onClick: PropTypes.func,
  icon: PropTypes.node,
};
MiniCard.defaultProps = {
  children: null,
  className: null,
  type: "tertiary",
  onClick: null,
  icon: null,
};
export default MiniCard;
