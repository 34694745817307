import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Icon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      d="M21.414 11H5.828l4.293-4.293c.391-.39.391-1.023 0-1.414-.391-.39-1.023-.39-1.414 0L2 12l6.707 6.707c.195.195.451.293.707.293.256 0 .512-.098.707-.293.391-.39.391-1.023 0-1.414L5.828 13h15.586v-2z"
    />
  </SvgIcon>
);

export default Icon;
