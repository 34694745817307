import React, { useCallback, useState } from "react";
import { useGlobalStyles } from "theme";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import AuthCCUProvider from "components/AuthCCU/AuthCCUProvider";
import Link from "components/Link";
import Layout from "Layout";
import StyleGuide from "StyleGuide";
import Routes, { TEST_MODE_ROUTES } from "Routes";
import {
  HOME_DISCONNECTED,
  SERVICE_UNAVAILABLE,
  LOGOUT,
  URL_NOT_AUTHORIZED,
} from "PublicRoutes";
import UrlParametersKey from "utils/UrlParametersKey";

import {
  setIsTestMode,
  getIsTestMode,
  isTestModeAuthorized,
} from "utils/TestModeParametersUtil";
import Box from "@material-ui/core/Box";
import Button from "components/Button";

const Router = () => {
  const location = useLocation();
  const history = useHistory();
  const { search, pathname } = location;

  const [disconnected, setDisconnected] = useState(false);

  const isTestMode = getIsTestMode();

  const handleAuthError = useCallback(() => {
    setDisconnected(true);
  }, []);

  useGlobalStyles();

  const entry = new URLSearchParams(search).get(UrlParametersKey.ENTRY);
  if (entry) {
    sessionStorage.setItem(UrlParametersKey.ENTRY, entry);
  }

  const TEST_MODE_ROUTES_PATHES = Object.values(TEST_MODE_ROUTES).map(
    (route) => route.path,
  );

  const handleTestMode = (testMode) => {
    setIsTestMode(testMode);
    sessionStorage.setItem("TEST_MODE", testMode ? "true" : "false");
  };

  return (
    <Layout>
      <Box
        sx={{
          position: "fixed",
          top: 7,
          right: 16,
        }}
      >
        {isTestModeAuthorized && (
          <>
            {isTestMode && !TEST_MODE_ROUTES_PATHES.includes(pathname) && (
              <Button
                style={{
                  backgroundColor: "#fff",
                  color: "#20458f",
                  borderRadius: "12px",
                  padding: "10px",
                  marginRight: "1rem",
                  height: "auto",
                  width: "fit-content",
                  border: "none",
                }}
                onClick={() => {
                  if (pathname === "/service-indisponible") {
                    history.replace(TEST_MODE_ROUTES.TEST_MODE_HOME.path);
                  }
                  history.go(0);
                }}
              >
                Faire un autre test
              </Button>
            )}
            <Button
              style={{
                backgroundColor: "#fff",
                color: "#20458f",
                borderRadius: "12px",
                padding: "10px",
                marginRight: "1rem",
                height: "auto",
                width: "fit-content",
                border: "none",
              }}
              onClick={() => {
                if (isTestMode) {
                  handleTestMode(false);
                  if (pathname === "/service-indisponible") {
                    history.replace("/");
                  }
                  history.go(0);
                } else {
                  handleTestMode(true);
                  history.push(TEST_MODE_ROUTES.TEST_MODE_HOME.path);
                }
              }}
            >
              {isTestMode ? "Quitter Mode Test" : "Mode Test"}
            </Button>
          </>
        )}
        {process.env.NODE_ENV === "development" && (
          <Link
            to="/charte-graphique"
            style={{
              backgroundColor: "#fff",
              color: "#20458f",
              borderRadius: "12px",
              padding: "10px",
            }}
          >
            Charte graphique
          </Link>
        )}
      </Box>
      <Switch>
        {process.env.NODE_ENV === "development" && (
          <Route path="/charte-graphique" component={StyleGuide} exact />
        )}
        <Route
          exact
          path={SERVICE_UNAVAILABLE.path}
          component={SERVICE_UNAVAILABLE.Component}
        />
        <Route
          exact
          path={URL_NOT_AUTHORIZED.path}
          component={URL_NOT_AUTHORIZED.Component}
        />
        <Route exact path={LOGOUT.path} component={LOGOUT.Component} />
        {!disconnected && (
          <AuthCCUProvider
            onError={handleAuthError}
            data-testid="connectedHome"
          >
            <Routes />
          </AuthCCUProvider>
        )}
        {disconnected && (
          <Route
            exact
            path={HOME_DISCONNECTED.path}
            component={HOME_DISCONNECTED.Component}
            data-testid="disconnectedHome"
          />
        )}
      </Switch>
    </Layout>
  );
};

export default Router;
