import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const RedTrashIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.681 3.33301H17.4993C17.9596 3.33301 18.3327 3.7061 18.3327 4.16634C18.3327 4.62658 17.9596 4.99967 17.4993 4.99967H17.0717V16.9163C17.0717 18.1549 16.0801 19.1663 14.8495 19.1663H5.12727C3.89663 19.1663 2.90505 18.1549 2.90505 16.9163V4.99967H2.49935C2.03911 4.99967 1.66602 4.62658 1.66602 4.16634C1.66602 3.7061 2.03911 3.33301 2.49935 3.33301H5.31765L6.33733 1.29366C6.47849 1.01134 6.76704 0.833008 7.08268 0.833008H12.916C13.2317 0.833008 13.5202 1.01134 13.6614 1.29366L14.681 3.33301ZM12.8178 3.33301L12.4011 2.49967H7.59782L7.18115 3.33301H12.8178ZM14.1496 4.99967C14.1612 4.99992 14.1728 4.99992 14.1845 4.99967H15.4054V16.9163C15.4054 17.2418 15.1526 17.4997 14.8499 17.4997H5.12766C4.82496 17.4997 4.5721 17.2418 4.5721 16.9163V4.99967H5.81501C5.82664 4.99992 5.83824 4.99992 5.84983 4.99967H14.1496ZM8.74935 14.1663C8.74935 14.6266 8.37625 14.9997 7.91602 14.9997C7.45578 14.9997 7.08268 14.6266 7.08268 14.1663V8.74967C7.08268 8.28944 7.45578 7.91634 7.91602 7.91634C8.37625 7.91634 8.74935 8.28944 8.74935 8.74967V14.1663ZM12.0827 14.9997C12.5429 14.9997 12.916 14.6266 12.916 14.1663V8.74967C12.916 8.28944 12.5429 7.91634 12.0827 7.91634C11.6224 7.91634 11.2493 8.28944 11.2493 8.74967V14.1663C11.2493 14.6266 11.6224 14.9997 12.0827 14.9997Z"
      fill="#CD3939"
    />
  </SvgIcon>
);

export default RedTrashIcon;
