import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Actions from "components/Layout/Actions";
import CenteredTitle from "components/Layout/CenteredTitle";
import Illustration from "components/Layout/Illustration";
import MainContent from "components/Layout/MainContent";
import Button from "components/Button";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/styles/makeStyles";
import { getReturnUrl } from "utils/UrlUtil";
import Step from "components/Layout/Step";
import { useTranslation } from "react-i18next";
import usePartners from "hook/usePartners";
import UrlParametersKey from "utils/UrlParametersKey";

const useStyles = makeStyles(() => ({
  image: {
    width: 100,
    height: 100,
  },
  informationContainer: {
    textAlign: "center",
  },
}));

const CertificationSuccessManual = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const serviceName = sessionStorage.getItem(UrlParametersKey.SERVICE_NAME);
  const {
    hasPartnerOffer,
    getPartnerCertificationSuccessManualContent,
    getPartnerCertificationSuccessManualCta,
  } = usePartners();

  const buttonLabel = () => {
    if (serviceName) {
      return `${t("common:goTo")} ${serviceName}`;
    }
    if (hasPartnerOffer()) {
      return getPartnerCertificationSuccessManualCta();
    }
    return t("common:goToService");
  };

  return (
    <Step progress={100}>
      <Illustration>
        <img
          className={classes.image}
          src="/certification.png"
          alt="Succès certification"
          aria-hidden
          title="Succès certification"
        />
      </Illustration>
      <CenteredTitle>{t("certificationSuccessManual:title")}</CenteredTitle>
      <MainContent>
        <Typography variant="body1" className={classes.informationContainer}>
          {t("certificationSuccessManual:content.part1")}
        </Typography>
        <Typography variant="body1" className={classes.informationContainer}>
          {hasPartnerOffer() ? (
            getPartnerCertificationSuccessManualContent()
          ) : (
            <>{t("certificationSuccessManual:content.part2")}</>
          )}
        </Typography>
      </MainContent>
      <Actions>
        <Box style={{ width: "100%" }}>
          <Link
            href={getReturnUrl()}
            underline="none"
            data-testid="test-button"
          >
            <Button>{buttonLabel()}</Button>
          </Link>
        </Box>
      </Actions>
    </Step>
  );
};

export default CertificationSuccessManual;
