import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Actions from "components/Layout/Actions";
import CenteredTitle from "components/Layout/CenteredTitle";
import Illustration from "components/Layout/Illustration";
import MainContent from "components/Layout/MainContent";
import Button from "components/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import MuiLink from "@material-ui/core/Link";
import { getLogoutUrlWithCallback } from "utils/UrlUtil";
import { useHistory } from "react-router-dom";
import Loader from "components/Loader";
import SettingsService from "services/SettingsService";
import { formatSettings } from "utils/SettingsUtil";
import UrlParametersKey from "utils/UrlParametersKey";
import useServiceName from "hook/useServiceName";
import { useTranslation } from "react-i18next";
import {
  getAutomaticStatus,
  getManualSwitchStatus,
} from "utils/AutomaticStatusUtil";

const useStyles = makeStyles((theme) => ({
  image: {
    width: 100,
    height: 100,
  },
  link: {
    fontWeight: 700,
    color: theme.palette.cerulean,
  },
  labelCgu: {
    fontSize: "12px",
  },
  cgu: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: "30px",
  },
}));

const title = "Demande de certification";

const DisconnectedHome = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const serviceName = useServiceName();

  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const history = useHistory();
  const urlb = sessionStorage.getItem(UrlParametersKey.URL_BACK);
  const urlba = sessionStorage.getItem(UrlParametersKey.URL_BACK_AUTOMATIC);
  const urlbm = sessionStorage.getItem(UrlParametersKey.URL_BACK_MANUAL);
  const sn = sessionStorage.getItem(UrlParametersKey.SERVICE_NAME);
  const getBackLink = () => {
    if (sn && (urlb || urlba || urlbm)) {
      if (getAutomaticStatus() && urlba) {
        return urlba;
      }
      if (getManualSwitchStatus() && urlbm) {
        return urlbm;
      }
      return urlb || process.env.REACT_APP_ECP_DASHBOARD;
    }
    return getLogoutUrlWithCallback(process.env.REACT_APP_CLIENT_AUTH_URL);
  };

  useEffect(() => {
    SettingsService.get()
      .then((settingsResponse) => {
        const settings = formatSettings(settingsResponse);
        const { processEnabled = true } = settings;
        if (processEnabled === false) {
          history.push("/service-indisponible");
        }
      })
      .catch(() => {
        // do nothing
      })
      .finally(() => {
        setSettingsLoaded(true);
      });
  }, [history]);

  return (
    <Loader isLoading={!settingsLoaded}>
      <Illustration>
        <img
          className={classes.image}
          src="/certification.png"
          alt={title}
          aria-hidden
          title={title}
        />
      </Illustration>
      <CenteredTitle>{title}</CenteredTitle>
      <MainContent>
        <Typography variant="body1" component="div">
          <Grid container spacing={1} direction="column">
            <Grid item>{t("disconnectedHome:content.part1")}</Grid>
            <Grid item>
              {t("disconnectedHome:content.part2")}{" "}
              <span style={{ whiteSpace: "nowrap" }}>
                {t("disconnectedHome:content.part3")}
              </span>
            </Grid>
            <ul>
              <li>
                {t("disconnectedHome:content.part4")}{" "}
                <b>{t("disconnectedHome:content.part5")}</b>
              </li>
            </ul>
          </Grid>
        </Typography>
      </MainContent>
      <Actions>
        <Box mb={2} style={{ width: "100%" }}>
          <MuiLink
            href={getLogoutUrlWithCallback()}
            underline="none"
            data-testid="test-button"
          >
            <Button>{t("disconnectedHome:certifyMyAccount")}</Button>
          </MuiLink>
        </Box>
        <MuiLink
          href={getBackLink()}
          className={classes.link}
          data-testid="test-link"
        >
          <Typography variant="body2" className={classes.link}>
            {t("common:goTo")} {serviceName}
          </Typography>
        </MuiLink>
      </Actions>
    </Loader>
  );
};

export default DisconnectedHome;
