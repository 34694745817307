export default {
  NO_SIRET: "absence_siret",
  USER_IS_NOT_DIRECTOR: "demandeur_pas_directeur",
  NO_PHYSICAL_DIRECTOR: "aucun_directeur_physique",
  MAX_TRIALS_EXCEEDED: "nombre_essais_depasses",
  API_READ_BACK: "retour_api_read",
  AUTOMATIC_PROCESS_DISABLED: "processus_automatique_clos",
  SUBDELEGATION: "subdelegation",
  MISSING_COMPANY_INFOS: "missing_company_infos",
};
