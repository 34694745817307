import Company from "models/Company";

export default class User {
  constructor({
    userId = "",
    gender = "",
    familyName = "",
    givenName = "",
    status = "",
    mobilePhone = "",
    mobilePhoneChecked = "",
    company,
    ...others
  } = {}) {
    this.userId = userId;
    this.gender = gender;
    this.familyName = familyName;
    this.givenName = givenName;
    this.status = status;
    this.mobilePhone = mobilePhone;
    this.mobilePhoneChecked = mobilePhoneChecked;
    this.company =
      company && Object.keys(company).length !== 0 && new Company(company);
    Object.keys(others).forEach((key) => {
      this[key] = others[key];
    });
  }
}
