import React, { useEffect } from "react";
import { deleteIdFiles } from "utils/FileUtil";
import { useTranslation } from "react-i18next";
import useUpload from "hook/useUpload";
import IdIllegible from "./IdIllegible";

const NotReadableIdError = () => {
  const { t } = useTranslation();
  const { deleteIdFilesFromUpload } = useUpload();

  useEffect(() => {
    deleteIdFiles();
    deleteIdFilesFromUpload();
  }, [deleteIdFilesFromUpload]);

  return (
    <IdIllegible
      title={t("notReadableIdError:title")}
      label={t("notReadableIdError:label")}
      displayModalButton
      btnLabel={t("notReadableIdError:btnLabel")}
    />
  );
};

export default NotReadableIdError;
