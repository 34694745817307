import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ClearIdIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 74 54">
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          fill="#D6E4FF"
          fillRule="nonzero"
          d="M57.566 5.411v27.87c.04.941-.685 1.738-1.623 1.785H13.177c-.938-.047-1.662-.844-1.623-1.785V5.411c-.039-.941.685-1.739 1.623-1.786h42.766c.938.047 1.663.845 1.623 1.786z"
          transform="translate(5 5)"
        />
        <path
          fill="#BFD6FF"
          d="M55.585 35.043V7.177c.042-.943-.682-1.744-1.623-1.794H11.196c-.941.05-1.665.851-1.623 1.794v27.866c-.04.941.685 1.738 1.623 1.786h42.766c.938-.048 1.663-.845 1.623-1.786z"
          transform="translate(5 5)"
        />
        <path
          fill="#20458F"
          fillRule="nonzero"
          d="M54 8.743v27.514c.03.922-.709 1.698-1.659 1.743H8.66c-.95-.047-1.687-.822-1.66-1.743V8.743C6.974 7.82 7.71 7.047 8.66 7H52.34c.95.045 1.689.82 1.658 1.743z"
          transform="translate(5 5)"
        />
        <path
          fill="#20458F"
          fillRule="nonzero"
          d="M8 8L54 8 54 14 8 14z"
          transform="translate(5 5)"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M8 14L53 14 53 36 8 36z"
          transform="translate(5 5)"
        />
        <path
          fill="#20458F"
          d="M21.892 19H12v10.996h9.892V19zm2.642.001h15.168v1.833H24.534V19zm0 3.053H43v1.834H24.534v-1.834zm15.168 3.06H24.534v1.832h15.168v-1.833zm-15.168 3.053h10.553V30H24.534v-1.833z"
          transform="translate(5 5)"
        />
        <path
          fill="#EDF3FF"
          fillRule="nonzero"
          d="M20 30c-.026-3.089-.756-4.979-1.69-5.823.833-.574 1.191-1.625.882-2.586-.31-.963-1.215-1.609-2.227-1.59-1.011.019-1.892.697-2.166 1.67-.274.972.122 2.01.976 2.553C14.783 25.13 14 27.088 14 30h6z"
          transform="translate(5 5)"
        />
        <path
          fill="#92004D"
          d="M7.356.987c.271-.019.492-.238.492-.509 0-.273-.222-.495-.494-.477C3.411.261.258 3.423.001 7.378c-.018.271.204.493.476.493.27 0 .488-.22.507-.49.243-3.418 2.965-6.149 6.372-6.394zm.004 1.985c.269-.026.489.195.489.465s-.219.485-.487.519c-1.78.226-3.19 1.64-3.416 3.425-.034.269-.25.489-.521.489-.272 0-.495-.221-.468-.492.234-2.327 2.082-4.176 4.403-4.406zm49.176 41.306c-.278.018-.505-.209-.505-.487s.226-.501.503-.522c3.404-.25 6.121-2.981 6.361-6.398.019-.271.238-.493.511-.493.272 0 .495.221.478.493-.243 3.966-3.397 7.143-7.348 7.407zm-.505-3.449c0-.272.221-.49.491-.524 1.782-.228 3.192-1.648 3.413-3.436.033-.27.25-.491.522-.491.271 0 .493.22.468.489-.109 1.131-.605 2.196-1.412 3.007-.806.811-1.864 1.311-2.99 1.424-.271.027-.492-.197-.492-.469z"
          transform="translate(5 5)"
        />
      </g>
    </g>
  </SvgIcon>
);

export default ClearIdIcon;
