export default {
  CERTIFICATION_STARTED: "CERTIFICATION_STARTED",
  CERTIFICATION_STARTED_ERROR: "CERTIFICATION_STARTED_ERROR",
  LINK_CREATION: "LINK_CREATION",
  LINK_CREATION_ERROR: "LINK_CREATION_ERROR",
  LINK_MANAGEMENT_ERROR: "LINK_MANAGEMENT_ERROR",
  READ: "READ",
  READ_RETRY_DOCUMENT_NOT_VALID: "READ_RETRY_DOCUMENT_NOT_VALID",
  READ_RETRY_DOCUMENT_BAD_SCORE: "READ_RETRY_DOCUMENT_BAD_SCORE",
  READ_RETRY_DOCUMENT_NOT_READABLE: "READ_RETRY_DOCUMENT_NOT_READABLE",
  READ_RETRY_DOCUMENT_MULTIPLE_MRZ: "READ_RETRY_DOCUMENT_MULTIPLE_MRZ",
  CERTIFICATION_CREATION: "CERTIFICATION_CREATION",
  CERTIFICATION_CREATION_ERROR: "CERTIFICATION_CREATION_ERROR",
  CERTIFICATION_FINISHED: "CERTIFICATION_FINISHED",
  CERTIFICATION_COMPLETED: "CERTIFICATION_COMPLETED",
};
