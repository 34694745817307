import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const CenteredTitle = (props) => {
  const { children, ...others } = props;

  return (
    <Box mb={3} component={Typography} variant="h2" align="center" {...others}>
      {children}
    </Box>
  );
};

CenteredTitle.propTypes = {
  children: PropTypes.node,
};

CenteredTitle.defaultProps = {
  children: null,
};

export default CenteredTitle;
