import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useSettings } from "components/Settings";
import Loader from "components/Loader";
import Home from "pages/Home";
import CompanyInfo from "pages/CompanyInfo";
import UpdateUserInfo from "pages/UpdateUserInfo";
import ErrorInactiveCoclico from "pages/ErrorInactiveCoclico";
import ErrorMissingCoclico from "pages/ErrorMissingCoclico";
import ServiceUnavailable from "pages/ServiceUnavailable";
import CompanyStatus from "pages/CompanyStatus";
import IdSelection from "pages/IdSelection";
import SupportingDocumentsUpload from "pages/SupportingDocumentsUpload";
import IdAnalysis from "pages/IdAnalysis";
import NotReadableIdError from "pages/NotReadableIdError";
import CertificationSuccessAutomatic from "pages/CertificationSuccessAutomatic";
import CertificationSuccessManual from "pages/CertificationSuccessManual";
import InvalidId from "pages/InvalidId";
import UserService from "services/UserService";
import CompanyService from "services/CompanyService";
import CompanyDirectorStatus from "utils/CompanyDirectorStatus";
import { getNumberOfCompanyFiles, getNumberOfIdFiles } from "utils/FileUtil";
import { getIsCertified } from "utils/UserUtil";
import CertificationControlInProgress from "pages/CertificationControlInProgress";
import CertifiedAccount from "pages/CertifiedAccount";
import OutOfDateIdError from "pages/OutOfDateIdError";
import SubHome from "pages/SubHome";
import SubIdSelection from "pages/SubIdSelection";
import SubUpload from "pages/SubUpload";
import SubRLIdSelection from "pages/SubRLIdSelection";
import {
  setManualSwitchStatus,
  setAutomaticStatus,
  getAutomaticStatus,
  getManualSwitchStatus,
} from "utils/AutomaticStatusUtil";
import ManualSwitchStatus from "utils/ManualSwitchStatus";
import withTracker from "components/withTracker";
import { getTags } from "utils/TagUtil";
import UploadTypeStatus from "utils/UploadTypeStatus";
import ErrorCompanyNonDiffusible from "pages/ErrorCompanyNonDiffusible";
import CertificationService from "services/CertificationService";
import VerifyProcuration from "pages/VerifyProcuration";
import ChoiceOfLegalRepresentative from "pages/ChoiceOfLegalRepresentative";
import UnknownLeader from "pages/UnknownLeader";
import UnknownCompany from "pages/UnknownCompany";
import ErrorMissingSiret from "pages/ErrorMissingSiret";
import ErrorMissingAddress from "pages/ErrorMissingAddress";
import ErrorInactiveSiret from "pages/ErrorInactiveSiret";
import TestModeHome from "pages/test_mode/TestModeHome";
import CompanyConditions from "pages/test_mode/CompanyConditions";
import AccountConditions from "pages/test_mode/AccountConditions";
import LegalRepresentativeConditions from "pages/test_mode/LegalRepresentativeConditions";
import IdConditions from "pages/test_mode/IdConditions";
import ResultConditions from "pages/test_mode/ResultConditions";
import StartTest from "pages/test_mode/StartTest";
import AutomaticSuccess from "pages/test_mode/AutomaticSuccess";
import ScreenTesting from "pages/test_mode/ScreenTesting";

const TEST_MODE_HOME = {
  path: "/mode-de-test",
  Component: TestModeHome,
  canAccess: () => true,
};

const AUTOMATIC_SUCCESS_TEST = {
  path: "/automatic-success",
  Component: AutomaticSuccess,
  canAccess: () => true,
};

const SCREEN_TESTING = {
  path: "/description-simulateur",
  Component: ScreenTesting,
  canAccess: () => true,
};

const COMPANY_CONDITIONS = {
  path: "/conditions-entreprise",
  Component: CompanyConditions,
  canAccess: () => true,
};

const ACCOUNT_CONDITIONS = {
  path: "/conditions-compte",
  Component: AccountConditions,
  canAccess: () => true,
};

const LEGAL_REPRESENTATION_CONDITIONS = {
  path: "/conditions-representant-legal",
  Component: LegalRepresentativeConditions,
  canAccess: () => true,
};

const ID_CONDITIONS = {
  path: "/conditions-piece-d-identite",
  Component: IdConditions,
  canAccess: () => true,
};

const RESULT_CONDITIONS = {
  path: "/conditions-resultat",
  Component: ResultConditions,
  canAccess: () => true,
};

const START_TEST = {
  path: "/commencer-test",
  Component: StartTest,
  canAccess: () => true,
};

const MISSING_COCLICO = {
  path: "/coclico-manquant",
  Component: ErrorMissingCoclico,
  canAccess: async () => {
    const hasCoclico = await UserService.hasCoclico();

    return !hasCoclico || { Component: <Redirect to="/" /> };
  },
  tcVars: {
    ch2: "etape-0",
    ch3: "",
    pageName: "etape-0.1-identification_etablissement_impossible",
    step: "0.1",
  },
};

const INACTIVE_COCLICO = {
  path: "/coclico-erreur",
  Component: ErrorInactiveCoclico,
  canAccess: async () => {
    const hasValidCoclico = await UserService.hasValidCoclico();

    return !hasValidCoclico || { Component: <Redirect to="/" /> };
  },
  tcVars: {
    ch2: "etape-0",
    ch3: "",
    pageName: "etape-0.2-certification_compte_pro_impossible",
    step: "0.2",
  },
};

const INACTIVE_SIRET = {
  path: "/siret-erreur",
  Component: ErrorInactiveSiret,
  canAccess: () => true,
  tcVars: {
    ch2: "etape-0",
    ch3: "",
    pageName: "etape-0.2-certification_compte_pro_impossible",
    step: "0.2",
  },
};

const COMPANY_NON_DIFFUSIBLE = {
  path: "/non-diffusible-INSEE",
  Component: ErrorCompanyNonDiffusible,
  canAccess: async () => {
    const isNonDiffusibleCompany = await UserService.isNonDiffusibleCompany();

    return isNonDiffusibleCompany || { Component: <Redirect to="/" /> };
  },
  tcVars: {
    // TODO
    ch2: "etape-0",
    ch3: "",
    pageName: "etape-0.4-entreprise_invalide",
    step: "0.4",
  },
};

const MISSING_SIRET = {
  path: "/siret-manquant",
  Component: ErrorMissingSiret,
  canAccess: async () => true,
  tcVars: {
    ch2: "etape-0",
    ch3: "",
    pageName: "etape-0.3-informations_incompletes",
    step: "0.3",
  },
};

const MISSING_ADDRESS = {
  path: "/adresse-manquante",
  Component: ErrorMissingAddress,
  canAccess: async () => true,
  tcVars: {
    ch2: "etape-0",
    ch3: "",
    pageName: "etape-0.3-informations_incompletes",
    step: "0.3",
  },
};

const CERTIFICATION_INPROGRESS = {
  path: "/certification-erreur1",
  Component: CertificationControlInProgress,
  canAccess: async () => {
    const hasCertificationInProgress =
      await UserService.hasCertificationInProgress();

    return hasCertificationInProgress || { Component: <Redirect to="/" /> };
  },
  tcVars: {
    ch2: "etape-0",
    ch3: "",
    pageName: "etape-0.7-compte_pro_en_cours_certif",
    step: "0.7",
  },
};

const HAS_CERTIFICATION = {
  path: "/certification-erreur2",
  Component: CertifiedAccount,
  canAccess: async () => {
    const hasCertification = await UserService.hasCertification();

    return hasCertification || { Component: <Redirect to="/" /> };
  },
  tcVars: {
    ch2: "etape-0",
    ch3: "",
    pageName: "etape-0.6-compte_pro_certifie",
    step: "0.6",
  },
};

const HOME = {
  path: "/",
  Component: Home,
  canAccess: async () =>
    UserService.get()
      .then(async () => {
        const missingCoclico = await MISSING_COCLICO.canAccess();

        if (missingCoclico === true) {
          return { Component: <Redirect to={MISSING_COCLICO.path} /> };
        }

        const inactiveCoclico = await INACTIVE_COCLICO.canAccess();

        if (inactiveCoclico === true) {
          return { Component: <Redirect to={INACTIVE_COCLICO.path} /> };
        }

        const hasActiveSiret = await CompanyService.hasActiveSiret();

        if (hasActiveSiret !== true) {
          return { Component: <Redirect to={INACTIVE_SIRET.path} /> };
        }

        const companyNonDiffusible = await COMPANY_NON_DIFFUSIBLE.canAccess();

        if (companyNonDiffusible === true) {
          return {
            Component: <Redirect to={COMPANY_NON_DIFFUSIBLE.path} />,
          };
        }

        const hasCertification = await HAS_CERTIFICATION.canAccess();

        if (hasCertification === true) {
          return { Component: <Redirect to={HAS_CERTIFICATION.path} /> };
        }

        const certificationInProgress =
          await CERTIFICATION_INPROGRESS.canAccess();

        if (certificationInProgress === true) {
          return { Component: <Redirect to={CERTIFICATION_INPROGRESS.path} /> };
        }

        return true;
      })
      .catch((error) => {
        const { response } = error || {};

        if (response && response.status >= 400) {
          window.location.href = "/service-indisponible";
        }
      }),
  tcVars: {
    ch2: "etape-1",
    ch3: "",
    pageName: "prerequis",
    step: "1",
  },
};

const COMPANY_INFO = {
  path: "/verification-societe",
  Component: CompanyInfo,
  canAccess: async () => {
    const canAccessPrevStep = await HOME.canAccess();
    if (canAccessPrevStep !== true) {
      return canAccessPrevStep;
    }

    if (UserService.hasAcceptedCgu() === false) {
      return { Component: <Redirect to={HOME.path} /> };
    }

    return true;
  },
  tcVars: {
    ch2: "etape-2",
    ch3: "",
    pageName: "verification_information_entreprise",
    step: "2",
  },
};

const UPDATE_USER_INFO = {
  path: "/verification-client",
  Component: UpdateUserInfo,
  canAccess: COMPANY_INFO.canAccess,
  tcVars: {
    ch2: "etape-3",
    ch3: "",
    pageName: "verification_information_personnelles",
    step: "3",
  },
};

const CHECK_PROCURATION = {
  path: "/verification-procuration",
  Component: VerifyProcuration,
  canAccess: async () => {
    const canAccessUpdateUserInfo = await UPDATE_USER_INFO.canAccess();
    if (canAccessUpdateUserInfo !== true) {
      return false;
    }

    return true;
  },
};

const CHECK_LEGAL_REPRESENTATIVE = {
  path: "/verification-representant-legal",
  Component: () => <Loader isLoading />,
  canAccess: async ({ automaticProcessEnabled, fromNextStep }) => {
    if (!automaticProcessEnabled) {
      return {
        Component: <Redirect to="/choix-piece-identite" />,
      };
    }

    if (!automaticProcessEnabled) {
      // cas où l'on est sur une étape d'aprés
      return true;
    }

    return UserService.get().then(async () => {
      const hasSiretNumber = await UserService.hasSiretNumber();
      const hasCompanyInfo = await UserService.hasCompanyInfo();

      if ((!hasSiretNumber || !hasCompanyInfo) && !fromNextStep) {
        setManualSwitchStatus(
          !hasCompanyInfo
            ? ManualSwitchStatus.MISSING_COMPANY_INFOS
            : ManualSwitchStatus.NO_SIRET,
        );

        return { Component: <Redirect to="/choix-statut-societe" /> };
      }

      if (!hasSiretNumber && fromNextStep) {
        return true;
      }

      const companyDirectorStatus = await CompanyService.getStatus();

      if (
        (companyDirectorStatus === CompanyDirectorStatus.ERROR ||
          companyDirectorStatus ===
            CompanyDirectorStatus.NO_PHYSICAL_DIRECTOR) &&
        !fromNextStep
      ) {
        setManualSwitchStatus(ManualSwitchStatus.NO_PHYSICAL_DIRECTOR);

        return { Component: <Redirect to="/choix-statut-societe" /> };
      }

      if (
        companyDirectorStatus === CompanyDirectorStatus.USER_IS_DIRECTOR &&
        !fromNextStep
      ) {
        setAutomaticStatus(true);

        return {
          Component: <Redirect to="/choix-piece-identite" />,
        };
      }

      if (!fromNextStep) {
        setManualSwitchStatus(ManualSwitchStatus.USER_IS_NOT_DIRECTOR);
        return {
          Component: <Redirect to="/choix-statut-societe" />,
        };
      }
      return true;
    });
  },
};

const COMPANY_STATUS = {
  path: "/choix-statut-societe",
  Component: CompanyStatus,
  canAccess: async ({ automaticProcessEnabled }) => {
    const legalRepresentativeCanAccess =
      await CHECK_LEGAL_REPRESENTATIVE.canAccess({
        automaticProcessEnabled,
        fromNextStep: true,
      });

    if (legalRepresentativeCanAccess !== true) {
      return legalRepresentativeCanAccess;
    }

    if (automaticProcessEnabled) {
      const companyDirectorStatus = await CompanyService.getStatus();

      if (companyDirectorStatus === CompanyDirectorStatus.USER_IS_DIRECTOR) {
        return {
          Component: <Redirect to="/choix-piece-identite" />,
        };
      }
    } else {
      return {
        Component: <Redirect to="/choix-piece-identite" />,
      };
    }

    return true;
  },
  tcVars: {
    ch2: "etape-5",
    ch3: "",
    pageName: "etape-5.2-statut_entreprise",
    step: "5.2",
  },
};

const ID_SELECT = {
  path: "/choix-piece-identite",
  Component: IdSelection,
  canAccess: async ({ automaticProcessEnabled }) => {
    const legalRepresentativeCanAccess =
      await CHECK_LEGAL_REPRESENTATIVE.canAccess({
        automaticProcessEnabled,
        fromNextStep: true,
      });

    if (legalRepresentativeCanAccess !== true) {
      return legalRepresentativeCanAccess;
    }

    if (automaticProcessEnabled) {
      const companyDirectorStatus = await CompanyService.getStatus();

      setAutomaticStatus(
        companyDirectorStatus === CompanyDirectorStatus.USER_IS_DIRECTOR,
      );

      if (
        companyDirectorStatus === CompanyDirectorStatus.USER_IS_DIRECTOR ||
        (companyDirectorStatus !== CompanyDirectorStatus.USER_IS_DIRECTOR &&
          getNumberOfCompanyFiles() > 0)
      ) {
        return true;
      }

      if (CertificationService.getProcurationStatus()) {
        return true;
      }
    } else {
      return true;
    }

    return {
      Component: <Redirect to={COMPANY_STATUS.path} />,
    };
  },
  tcVars: () => ({
    ch2: getAutomaticStatus() ? "etape-6" : "etape-5",
    ch3: "",
    pageName: getAutomaticStatus()
      ? "etape-6-selectionner_piece_identite"
      : "etape-5.4-selectionner_piece_identite",
    step: getAutomaticStatus() ? "6" : "5.4",
  }),
};

const UPLOAD = {
  path: "/import/:uploadType",
  Component: SupportingDocumentsUpload,
  canAccess: async ({ location, automaticProcessEnabled }) => {
    const legalRepresentativeCanAccess =
      await CHECK_LEGAL_REPRESENTATIVE.canAccess({
        automaticProcessEnabled,
        fromNextStep: true,
      });

    if (legalRepresentativeCanAccess !== true) {
      return legalRepresentativeCanAccess;
    }

    const uploadType = location.pathname.replace("/import/", "");

    if (automaticProcessEnabled) {
      const companyDirectorStatus = await CompanyService.getStatus();

      if (companyDirectorStatus === CompanyDirectorStatus.USER_IS_DIRECTOR) {
        if (uploadType === UploadTypeStatus.COMPANY) {
          return {
            Component: <Redirect to="/choix-piece-identite" />,
          };
        }

        return true;
      }

      if (
        CertificationService.getProcurationStatus() ===
        "FOUND_WITH_DIFFERENT_EMAIL"
      ) {
        return true;
      }

      if (uploadType === UploadTypeStatus.ID && getNumberOfCompanyFiles() < 1) {
        return {
          Component: <Redirect to="/choix-statut-societe" />,
        };
      }
    } else {
      if (uploadType === UploadTypeStatus.COMPANY) {
        return {
          Component: <Redirect to="/choix-piece-identite" />,
        };
      }

      return true;
    }

    return true;
  },
  tcVars: ({ location }) => {
    const uploadType = location.pathname.replace("/import/", "");
    const piType = location.state;

    if (uploadType === UploadTypeStatus.ID) {
      if (piType.access === "IDENTITY_IDCARD") {
        return {
          ch2: getAutomaticStatus() ? "etape-7" : "etape-5",
          ch3: "",
          pageName: `etape-${
            getAutomaticStatus() ? "7.1" : "5.5"
          }-importer_carte_identite`,
          step: getAutomaticStatus() ? "7.1" : "5.5",
        };
      }

      if (piType.access === "IDENTITY_PASSPORT") {
        return {
          ch2: getAutomaticStatus() ? "etape-7" : "etape-5",
          ch3: "",
          pageName: `etape-${
            getAutomaticStatus() ? "7.2" : "5.5"
          }-importer_passeport`,
          step: getAutomaticStatus() ? "7.2" : "5.5",
        };
      }

      if (piType.access === "IDENTITY_RESIDENTPERMIT") {
        return {
          ch2: getAutomaticStatus() ? "etape-7" : "etape-5",
          ch3: "",
          pageName: `etape-${
            getAutomaticStatus() ? "7.3" : "5.5"
          }-importer_titre_de_sejour`,
          step: getAutomaticStatus() ? "7.3" : "5.5",
        };
      }
    }

    return {
      ch2: "etape-5",
      ch3: "",
      pageName: "etape-5.3-justificatifs_entreprise",
      step: "5.3",
    };
  },
};

const SUB_ENTRY = {
  path: "/certification-rl-subdelegataire",
  Component: SubHome,
  canAccess: async ({ automaticProcessEnabled }) => {
    const legalRepresentativeCanAccess =
      await CHECK_LEGAL_REPRESENTATIVE.canAccess({
        automaticProcessEnabled,
        fromNextStep: true,
      });

    if (legalRepresentativeCanAccess !== true) {
      return legalRepresentativeCanAccess;
    }
    setManualSwitchStatus(ManualSwitchStatus.SUBDELEGATION);

    return true;
  },
  tcVars: {
    ch2: "etape-5.1",
    ch3: "",
    pageName: "etape-sub_2-demande_certif_sub",
    step: "5.1",
  },
};

const SUB_ID_SELECT = {
  path: "/certification-rl-subdelegataire/choix-piece-identite",
  Component: SubIdSelection,
  canAccess: async ({ automaticProcessEnabled }) => {
    const subEntryCanAccess = await SUB_ENTRY.canAccess({
      automaticProcessEnabled,
      fromNextStep: true,
    });

    if (subEntryCanAccess !== true) {
      return subEntryCanAccess;
    }

    return true;
  },
  tcVars: {
    ch2: "etape-5.1",
    ch3: "",
    pageName: "etape-sub_3-demande_pi_subdelegataire",
    step: "5.1",
  },
};

const SUB_UPLOAD = {
  path: "/certification-rl-subdelegataire/import/:uploadType",
  Component: SubUpload,
  canAccess: async ({ automaticProcessEnabled }) => {
    const subIdSelectionCanAccess = await SUB_ID_SELECT.canAccess({
      automaticProcessEnabled,
      fromNextStep: true,
    });

    if (subIdSelectionCanAccess !== true) {
      return subIdSelectionCanAccess;
    }

    return true;
  },
  tcVars: ({ location }) => {
    const { pathname } = location;
    const uploadType = pathname.substring(pathname.lastIndexOf("/") + 1);

    const piType = location.state;
    const { access } = piType || {};
    const isRlPi = access.includes("_RL");
    const vars = {
      ch2: "etape-5.1",
      ch3: "",
      step: "5.1",
    };
    const piSubStep = isRlPi ? "6" : "4";

    if (
      uploadType === UploadTypeStatus.ID ||
      uploadType === UploadTypeStatus.ID_RL
    ) {
      if (access === "IDENTITY_IDCARD" || access === "IDENTITY_IDCARD_RL") {
        vars.pageName = `etape-sub_${piSubStep}-importer_carte_identite`;
      }

      if (access === "IDENTITY_PASSPORT" || access === "IDENTITY_PASSPORT_RL") {
        vars.pageName = `etape-sub_${piSubStep}-importer_passeport`;
      }

      if (
        access === "IDENTITY_RESIDENTPERMIT" ||
        access === "IDENTITY_RESIDENTPERMIT_RL"
      ) {
        vars.pageName = `etape-sub_${piSubStep}-importer_titre_de_sejour`;
      }
    } else if (uploadType === UploadTypeStatus.SUBDELEGATION) {
      vars.pageName = "etape-sub_7-importer_attestation_subdelegation";
    }

    return vars;
  },
};

const SUB_RL_ID_SELECT = {
  path: "/certification-rl-subdelegataire/choix-piece-identite-representant-legal",
  Component: SubRLIdSelection,
  canAccess: async ({ automaticProcessEnabled }) => {
    const subUploadCanAccess = await SUB_UPLOAD.canAccess({
      automaticProcessEnabled,
      fromNextStep: true,
    });

    if (subUploadCanAccess !== true) {
      return subUploadCanAccess;
    }

    return true;
  },
  tcVars: {
    ch2: "etape-5.1",
    ch3: "",
    pageName: "etape-sub_5-demande_pi_RL",
    step: "5.1",
  },
};

const ID_ANALYSIS = {
  path: "/analyse-pi",
  Component: IdAnalysis,
  canAccess: () =>
    getNumberOfIdFiles() > 0 || { Component: <Redirect to={ID_SELECT.path} /> },
  tcVars: {
    ch2: "etape-8",
    ch3: "",
    pageName: "etape-8-analyse_piece_identite",
    step: "8",
  },
};

const ID_ILLEGIBLE = {
  path: "/analyse-pi-erreur1",
  Component: NotReadableIdError,
  canAccess: ID_ANALYSIS.canAccess,
  tcVars: {
    ch2: "etape-9",
    ch3: "",
    pageName: "etape-9.1-piece_identite_non_lisible",
    step: "9.1",
  },
};

const AUTOMATIC_SUCCESS = {
  path: "/compte-certifie",
  Component: CertificationSuccessAutomatic,
  canAccess: () => getIsCertified() || ID_ANALYSIS.canAccess,
  tcVars: {
    ch2: "etape-10",
    ch3: "",
    pageName: "etape-10.1-resultat_certif_auto",
    step: "10.1",
  },
};

const MANUAL_SUCCESS = {
  path: "/demande-certification",
  Component: CertificationSuccessManual,
  canAccess: ID_ANALYSIS.canAccess,
  tcVars: () => {
    const vars = {
      ch2: "etape-10",
      ch3: "",
      pageName: "etape-10.2-resultat_certif_manuel",
      step: "10.2",
    };
    const motifBascule = getManualSwitchStatus();
    if (motifBascule !== undefined) {
      vars.motifBascule = motifBascule;
    }

    return vars;
  },
};

const INVALID_ID = {
  path: "/analyse-pi-erreur2",
  Component: InvalidId,
  canAccess: ID_ANALYSIS.canAccess,
  tcVars: {
    ch2: "etape-9",
    ch3: "",
    pageName: "etape-9.2-piece_identite_non_valide",
    step: "9.2",
  },
};

const MULTIPLE_ID_ERROR = {
  path: "/analyse-pi-erreur3",
  Component: OutOfDateIdError,
  canAccess: ID_ANALYSIS.canAccess,
};

const CHOICE_OF_LEGAL_REPRESENTATIVE = {
  path: "/choix-representant-legal",
  Component: ChoiceOfLegalRepresentative,
  canAccess: COMPANY_INFO.canAccess,
};

const UNKNOWN_LEADER = {
  path: "/dirigeant-non-connu",
  Component: UnknownLeader,
  canAccess: () => true,
  tcVars: () => {
    const vars = {
      ch2: "etape-0",
      ch3: "",
      pageName: "etape-0.9-blocage_rl",
      step: "0.9",
    };
    const motifBascule = getManualSwitchStatus();
    if (motifBascule !== undefined) {
      vars.motifBascule = motifBascule;
    }

    return vars;
  },
};

const UNKNOWN_COMPANY = {
  path: "/societe-non-connue",
  Component: UnknownCompany,
  canAccess: () => true,
  tcVars: () => {
    const vars = {
      ch2: "etape-0",
      ch3: "",
      pageName: "etape-0.10-blocage_infos_incompletes",
      step: "0.10",
    };
    const motifBascule = getManualSwitchStatus();
    if (motifBascule !== undefined) {
      vars.motifBascule = motifBascule;
    }

    return vars;
  },
};

// TODO: waiting for the manual request feature
// const MANUAL_REQUEST = {
//   path: "/demande-manuelle",
//   Component: ManualRequest,
//   canAccess: () => true,
//   tcVars: {
//     ch2: "etape-0",
//     ch3: "",
//     pageName: "etape-0.11-blocage_infos_incompletes_demande_manuelle",
//     step: "0.11",
//   },
// };

export const TEST_MODE_ROUTES = {
  TEST_MODE_HOME,
  SCREEN_TESTING,
  COMPANY_CONDITIONS,
  ACCOUNT_CONDITIONS,
  LEGAL_REPRESENTATION_CONDITIONS,
  ID_CONDITIONS,
  RESULT_CONDITIONS,
  START_TEST,
  AUTOMATIC_SUCCESS_TEST,
};

const routes = {
  HOME,
  MISSING_COCLICO,
  INACTIVE_COCLICO,
  INACTIVE_SIRET,
  COMPANY_NON_DIFFUSIBLE,
  COMPANY_INFO,
  UPDATE_USER_INFO,
  CHECK_PROCURATION,
  CHECK_LEGAL_REPRESENTATIVE,
  COMPANY_STATUS,
  SUB_ENTRY,
  SUB_ID_SELECT,
  SUB_UPLOAD,
  SUB_RL_ID_SELECT,
  UPLOAD,
  ID_SELECT,
  ID_ANALYSIS,
  ID_ILLEGIBLE,
  AUTOMATIC_SUCCESS,
  MANUAL_SUCCESS,
  INVALID_ID,
  CERTIFICATION_INPROGRESS,
  HAS_CERTIFICATION,
  MULTIPLE_ID_ERROR,
  CHOICE_OF_LEGAL_REPRESENTATIVE,
  UNKNOWN_LEADER,
  UNKNOWN_COMPANY,
  MISSING_SIRET,
  MISSING_ADDRESS,
  TEST_MODE_HOME,
  SCREEN_TESTING,
  COMPANY_CONDITIONS,
  ACCOUNT_CONDITIONS,
  LEGAL_REPRESENTATION_CONDITIONS,
  ID_CONDITIONS,
  RESULT_CONDITIONS,
  START_TEST,
  AUTOMATIC_SUCCESS_TEST,
};

const PrivateRoute = (props) => {
  const { _route, ...componentProps } = props;
  const { canAccess, Component, tcVars } = _route;
  const userId = UserService.getUserId();
  const { processEnabled = true, automaticProcessEnabled = true } =
    useSettings();
  const location = useLocation();
  const renderComponent = useMemo(
    () => <Component {...componentProps} />,
    [componentProps],
  );
  const [render, setRender] = useState(
    typeof canAccess === "function" ? <Loader isLoading /> : renderComponent,
  );

  useEffect(() => {
    if (!processEnabled) {
      setRender(<ServiceUnavailable />);
    } else if (typeof canAccess === "function") {
      const hasAccess = canAccess({
        location,
        automaticProcessEnabled,
      });

      const handleAccessResponse = (response) => {
        if (response === true) {
          setRender(renderComponent);
        } else if (typeof response === "object") {
          const { Component: responseComponent } = response;
          setRender(responseComponent || null);
        } else {
          setRender(null);
        }
      };

      if (hasAccess instanceof Promise) {
        hasAccess.then(handleAccessResponse).catch(() => {
          setRender(<ServiceUnavailable />);
        });
      } else {
        handleAccessResponse(hasAccess);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const tcVarsValues =
    typeof tcVars === "function" ? tcVars({ location }) : tcVars;
  const notFinalComponent =
    render && (render.type === Loader || render.type === Redirect);

  const RenderWithTracker = notFinalComponent
    ? () => render
    : withTracker(() => render, {
        tcVars: getTags({ ...tcVarsValues, userId }),
      });

  return <RenderWithTracker />;
};

PrivateRoute.propTypes = {
  _route: PropTypes.shape({
    Component: PropTypes.func.isRequired,
    canAccess: PropTypes.func,
    tcVars: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  }).isRequired,
};

export default () =>
  Object.keys(routes).map((key) => {
    const route = routes[key];
    const { path } = route;

    return (
      <Route
        key={key}
        path={path}
        exact
        component={(props) => <PrivateRoute {...props} _route={route} />}
      />
    );
  });
