import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const PassportIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fill="#E0E9F6"
      d="M15.871 6.104H3.387c-1.108 0-2.014.905-2.014 2.01V24.65c0 1.105.906 2.009 2.014 2.009h12.484c1.107 0 2.013-.904 2.013-2.01V8.114c0-1.105-.906-2.009-2.013-2.009"
      transform="translate(-25 -271) translate(16 260) translate(9 11) translate(8 6)"
    />
    <path
      stroke="#194086"
      strokeLinecap="round"
      strokeWidth=".84"
      d="M4.808 17.872c2.386 2.386 6.302 2.386 8.688 0 2.387-2.386 2.387-6.302 0-8.688-2.386-2.386-6.302-2.387-8.688 0-2.386 2.386-2.386 6.302 0 8.688zm10.947 9.058H2.55c-1.17 0-2.13-.957-2.13-2.128V7.277c0-1.171.959-2.13 2.13-2.13h13.206c1.171 0 2.129.958 2.129 2.13v17.525c0 1.17-.958 2.128-2.129 2.128z"
      transform="translate(-25 -271) translate(16 260) translate(9 11) translate(8 6)"
    />
    <path
      fill="#194086"
      d="M11.865 16.088h1.967c.38-.698.605-1.49.638-2.33h-2.316c-.015.837-.116 1.625-.29 2.33M10.58 8.424c.49.502.895 1.257 1.173 2.18h1.83c-.7-1.047-1.76-1.833-3.003-2.18M12.153 13.346h2.317c-.032-.84-.259-1.633-.638-2.33h-1.968c.173.704.275 1.492.29 2.33M4.721 16.499c.7 1.048 1.76 1.834 3.002 2.18-.49-.5-.894-1.256-1.171-2.18h-1.83zM10.58 18.679c1.242-.347 2.302-1.132 3.002-2.18h-1.83c-.277.924-.681 1.679-1.172 2.18M6.995 16.499c.388 1.205.999 2.084 1.7 2.355.15.013.303.021.457.021.154 0 .306-.008.457-.02.7-.273 1.312-1.151 1.7-2.356H6.995zM6.15 13.757H3.834c.032.84.26 1.633.638 2.33h1.967c-.173-.704-.274-1.492-.289-2.33M6.439 11.016H4.472c-.38.698-.606 1.489-.639 2.33H6.15c.014-.838.116-1.626.289-2.33M6.875 16.088h4.554c.186-.703.297-1.496.313-2.33h-5.18c.015.834.127 1.627.313 2.33M11.429 11.016H6.875c-.186.703-.298 1.496-.314 2.33h5.181c-.016-.834-.127-1.627-.313-2.33M7.724 8.424c-1.243.347-2.304 1.133-3.003 2.18h1.83c.278-.923.682-1.678 1.173-2.18M11.308 10.604C10.921 9.4 10.31 8.52 9.61 8.25c-.151-.013-.303-.021-.457-.021-.154 0-.306.008-.456.02-.7.272-1.314 1.152-1.701 2.356h4.313z"
      transform="translate(-25 -271) translate(16 260) translate(9 11) translate(8 6)"
    />
    <path
      stroke="#194086"
      strokeLinecap="round"
      strokeWidth=".84"
      d="M5.697 3.937l.278-1.726C6.16 1.055 7.259.26 8.415.447l13.038 2.1c1.156.187 1.95 1.285 1.764 2.441L20.429 22.29c-.125.776-.661 1.39-1.35 1.651"
      transform="translate(-25 -271) translate(16 260) translate(9 11) translate(8 6)"
    />
  </SvgIcon>
);

export default PassportIcon;
