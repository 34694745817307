import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MiniCard from "components/Layout/MiniCard";
import PassportIcon from "icons/passport";
import TitreSejourIcon from "icons/titreSejour";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  cardLabel: {
    fontSize: "12px !important",
    color: `${theme.palette["01-body-background-charte-gris-6-tex"]}  !important`,
    marginTop: "4px",
  },
}));

const IdSelectionStep = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { idType, setIdType, isRlId } = props;

  return (
    <FormControl fullWidth component="fieldset">
      <RadioGroup
        aria-label="id"
        name="id"
        value={idType}
        onChange={(e) => setIdType(e.currentTarget.value)}
      >
        <MiniCard
          type="tertiary"
          data-testid="id-click"
          onClick={() => {
            setIdType(`IDENTITY_IDCARD${isRlId ? "_RL" : ""}`);
          }}
        >
          <FormControlLabel
            htmlFor="idTypeIdcard"
            value={`IDENTITY_IDCARD${isRlId ? "_RL" : ""}`}
            control={
              <Radio
                id="idTypeIdcard"
                inputProps={{ "data-testid": "option_1" }}
              />
            }
            label={
              <>
                <Typography variant="subtitle2">
                  {t("idSelectionStep:identityCard")}
                </Typography>
                <Typography variant="body2" className={classes.cardLabel}>
                  {t("idSelectionStep:currentlyValid")}
                </Typography>
              </>
            }
          />
        </MiniCard>
        <MiniCard
          type="tertiary"
          icon={
            <PassportIcon
              viewBox="0 0 40 40"
              style={{ color: "transparent" }}
            />
          }
          data-testid="passport-click"
          onClick={() => {
            setIdType(`IDENTITY_PASSPORT${isRlId ? "_RL" : ""}`);
          }}
        >
          <FormControlLabel
            htmlFor="idTypePassport"
            value={`IDENTITY_PASSPORT${isRlId ? "_RL" : ""}`}
            control={
              <Radio
                id="idTypePassport"
                inputProps={{ "data-testid": "option_2" }}
              />
            }
            label={
              <>
                <Typography variant="subtitle2">
                  {t("idSelectionStep:passeport")}
                </Typography>
                <Typography variant="body2" className={classes.cardLabel}>
                  {t("idSelectionStep:currentlyValid")}
                </Typography>
              </>
            }
          />
        </MiniCard>
        <MiniCard
          type="tertiary"
          icon={
            <TitreSejourIcon
              viewBox="0 0 40 40"
              style={{ color: "transparent" }}
            />
          }
          data-testid="tds-click"
          onClick={() => {
            setIdType(`IDENTITY_RESIDENTPERMIT${isRlId ? "_RL" : ""}`);
          }}
        >
          <FormControlLabel
            htmlFor="idTypeResidentpermit"
            value={`IDENTITY_RESIDENTPERMIT${isRlId ? "_RL" : ""}`}
            control={<Radio id="idTypeResidentpermit" />}
            label={
              <>
                <Typography variant="subtitle2">
                  {t("idSelectionStep:residentPermit")}
                </Typography>
                <Typography variant="body2" className={classes.cardLabel}>
                  {t("idSelectionStep:currentlyValid")}
                </Typography>
              </>
            }
          />
        </MiniCard>
      </RadioGroup>
    </FormControl>
  );
};

IdSelectionStep.propTypes = {
  idType: PropTypes.string.isRequired,
  setIdType: PropTypes.func.isRequired,
  isRlId: PropTypes.bool,
};

IdSelectionStep.defaultProps = {
  isRlId: false,
};

export default IdSelectionStep;
