import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import UploadFile from "components/UploadFile";
import useUpload from "hook/useUpload";
import useFile from "hook/useFile";

const SupportingDocumentsUpload = (props) => {
  const { location } = props;
  const { state } = location;
  const { access } = state;
  const { uploadType } = useParams();
  const { getUploadItems, uploadItems } = useUpload();
  const { cleanFiles } = useFile();

  const [items, setItems] = useState(getUploadItems(access));

  useEffect(() => {
    setItems(getUploadItems(access));
  }, [access, getUploadItems, uploadItems]);

  useEffect(() => {
    const itemsWithFile = items.filter((item) => item.file !== null);

    // Si un fichier existe pour la page d'upload sur laquelle on arrive, il reste
    // Sinon on clean
    if (itemsWithFile.length === 0) {
      cleanFiles(uploadType);
    }
  }, [cleanFiles, items, uploadType]);

  return <UploadFile location={location} uploadType={uploadType} />;
};

SupportingDocumentsUpload.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      access: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default SupportingDocumentsUpload;
