import CompanyAddress from "models/CompanyAddress";

export default class Company {
  constructor({
    name = "",
    siret = "",
    rnaNumber = "",
    active = "",
    address = {},
    ...others
  } = {}) {
    this.name = name;
    this.siret = siret;
    this.rnaNumber = rnaNumber;
    this.active = active;
    this.address = new CompanyAddress(address);
    Object.keys(others).forEach((key) => {
      this[key] = this[key] || others[key];
    });
  }
}
