export default class Upload {
  constructor({
    id = "",
    label = "",
    type = "",
    determinant = "",
    labelDetails = "",
    uploadItems = "",
  } = {}) {
    this.id = id;
    this.label = label;
    this.type = type;
    this.determinant = determinant;
    this.labelDetails = labelDetails;
    this.uploadItems = uploadItems;
  }
}
