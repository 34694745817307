import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Actions from "components/Layout/Actions";
import MainContent from "components/Layout/MainContent";
import Button from "components/Button";
import Link from "components/Link";
import Step from "components/Layout/Step";

function ScreenTesting() {
  return (
    <Step previousStepPath="/automatic-success" progress={200 / 8}>
      <MainContent>
        <Typography
          variant="body1"
          component="div"
          style={{
            marginTop: "4rem",
            marginBottom: "2rem",
            textAlign: "center",
          }}
        >
          Attention! La partie suivante de simulateur sert uniquement à tester
          les écrans du parcours. Certains données seront alors non-réelles et
          aucune certification ne sera effectuée à la fin (ni manuelle, ni
          automatique).
          <div style={{ marginTop: "1.25rem" }}>
            Vous allez maintenant pouvoir sélectionner les conditions dans
            lequelles vous voulez effectuer votre test.
          </div>
          <div style={{ marginTop: "1.25rem" }}>
            À chaque fois, si vous sélectionnez l&apos;option &quot;pas de
            conditions&quot;, le parcours se basera sur vos données réelles.
            Sinon, il simulera le comporttemment souhaité en fonction de votre
            choix d&apos;option.
          </div>
        </Typography>
      </MainContent>
      <Actions>
        <Box mb={2} style={{ width: "100%" }}>
          <Link to="/conditions-entreprise" underline="none">
            <Button>Sélectionner les conditions</Button>
          </Link>
        </Box>
      </Actions>
    </Step>
  );
}

export default ScreenTesting;
