import React from "react";
import { Link as RouterLink } from "react-router-dom";

import MuiLink from "@material-ui/core/Link";

const Link = (props) => (
  <MuiLink component={RouterLink} color="secondary" {...props} />
);

export default Link;
