import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/styles/makeStyles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
}));

const Illustration = (props) => {
  const { children, className, ...others } = props;

  const classes = useStyles();

  return (
    <Box
      mt={5}
      mb={3}
      className={classnames(classes.root, className)}
      {...others}
    >
      {children}
    </Box>
  );
};

Illustration.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Illustration.defaultProps = {
  children: null,
  className: null,
};

export default Illustration;
