import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { resourceEn, resourceFr } from "./i18nResource";

export const langueList = {
  fr: {
    name: "Français",
    mobileName: "Français",
  },
  en: {
    name: "English",
    mobileName: "English",
  },
};

const i18nGetLangueKey = () => Object.keys(langueList);

export const availableLanguages = i18nGetLangueKey();
export const defaultLanguage = availableLanguages[0];
export const currentLanguage =
  localStorage.getItem("i18nextLng") ?? defaultLanguage;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false }, // React already does escaping
    fallbackLng: currentLanguage,
    supportedLngs: i18nGetLangueKey(),
    react: { useSuspense: false },
    ns: [
      "certificationSuccessAutomatic",
      "certificationSuccessManual",
      "certifiedAccount",
      "choiceOfLegalRepresentative",
      "common",
      "companyInfo",
      "companyStatus",
      "disconnectedHome",
      "errorCompanyNonDiffusible",
      "errorInactiveCoclico",
      "errorMissingCoclico",
      "footer",
      "home",
      "idAnalysis",
      "idIllegible",
      "idSelection",
      "invalidId",
      "multipleIdError",
      "notReadableIdError",
      "outOfDateIdError",
      "partners",
      "serviceUnavailable",
      "subHome",
      "subIdSelection",
      "subRLIdSelection",
      "updateUserInfo",
      "upload",
      "step",
      "idSelectionStep",
      "uploadFile",
      "useUpload",
      "certificationControlInProgress",
      "unknownCompany",
      "unknownLeader",
      "errorMissingSiret",
      "errorMissingAddress",
      "errorUrlNotAuthorized",
      "errorInactiveSiret",
    ],
    defaultNS: "common",
    nsSeparator: ":",
    resources: { fr: resourceFr, en: resourceEn },
    debug: process.env.NODE_ENV === "development",
  });

i18n.on("languageChanged", (lang) => {
  localStorage.setItem("i18nextLng", lang);
});

export default i18n;
