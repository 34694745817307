import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Actions from "components/Layout/Actions";
import CenteredTitle from "components/Layout/CenteredTitle";
import Illustration from "components/Layout/Illustration";
import MainContent from "components/Layout/MainContent";
import Button from "components/Button";
import Link from "components/Link";
import makeStyles from "@material-ui/styles/makeStyles";
import { Link as LinkECP } from "@material-ui/core";
import { getReturnUrl } from "utils/UrlUtil";
import Step from "components/Layout/Step";
import PreventManualRequestsProvider from "components/PreventManualRequests/PreventManualRequestsProvider";
import useServiceName from "hook/useServiceName";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  image: {
    width: 100,
    height: 100,
  },
  link: {
    fontWeight: 700,
    color: theme.palette.cerulean,
  },
  labelCgu: {
    fontSize: "12px",
  },
  cgu: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: "30px",
  },
}));

const subdelegationFile = `/POUVOIR DE DELEGATION POSTALE.pdf`;

const SubHome = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const serviceName = useServiceName();

  const title = t("subHome:title");

  return (
    <PreventManualRequestsProvider preventFunction={() => true}>
      <Step progress={30} previousStepPath="/choix-representant-legal">
        <Illustration>
          <img
            className={classes.image}
            src="/certification.png"
            alt={title}
            aria-hidden
            title={title}
          />
        </Illustration>
        <CenteredTitle>{title}</CenteredTitle>
        <MainContent>
          <Typography variant="body1" component="div">
            <Grid container spacing={1} direction="column">
              <Grid item>{t("subHome:content.part1")}</Grid>
              <Grid item>
                <ul>
                  <li>
                    {t("subHome:content.part2.part1")}{" "}
                    <b>{t("subHome:content.part2.part2")}</b>
                  </li>
                  <li>
                    {t("subHome:content.part3.part1")}{" "}
                    <b>{t("subHome:content.part3.part2")}</b>{" "}
                    {t("subHome:content.part3.part3")}{" "}
                    <b>{t("subHome:content.part3.part4")}</b>
                  </li>
                  <li>
                    {t("subHome:content.part4.part1")}
                    <LinkECP
                      className={classes.link}
                      href={subdelegationFile}
                      target="_blank"
                      data-testid="dl-button"
                    >
                      {t("subHome:content.part4.part2")}
                    </LinkECP>{" "}
                    {t("subHome:content.part4.part3")}
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Typography>
        </MainContent>
        <Actions>
          <Box mb={2} style={{ width: "100%" }}>
            <Link
              to="/certification-rl-subdelegataire/choix-piece-identite"
              underline="none"
            >
              <Button data-testid="test-button">{t("common:continue")}</Button>
            </Link>
          </Box>
          <LinkECP href={getReturnUrl()} className={classes.link}>
            <Typography variant="body2" className={classes.link}>
              {t("subHome:returnUrl.part1")} {serviceName}
            </Typography>
          </LinkECP>
        </Actions>
      </Step>
    </PreventManualRequestsProvider>
  );
};

export default SubHome;
