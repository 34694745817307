import Loader from "components/Loader";
import { SERVICE_UNAVAILABLE } from "PublicRoutes";
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import CertificationService from "services/CertificationService";
import TokenService from "services/TokenService";
import CertificationStatus from "utils/CertificationStatus";
import ProcurationStatus from "utils/ProcurationStatus";
import { setIsCertified } from "utils/UserUtil";
import CompanyService from "services/CompanyService";

const maxRetry = process.env.REACT_APP_MAX_RETRY_GET_CERTIFICATION || 10;
const IntervalRetry = process.env.REACT_APP_INTERVAL_RETRY_UPLOAD || 10000;
const IntervalStart = 3000;

const VerifyProcuration = () => {
  const history = useHistory();
  const errorCount = useRef(0);

  const UpdateCertificationStatus = (certificationId) => {
    const keepGoingStatus = [
      CertificationStatus.CERTIFICATION_STARTED,
      CertificationStatus.LINK_CREATION,
      CertificationStatus.READ,
      CertificationStatus.CERTIFICATION_CREATION,
    ];
    const unavailableServiceStatus = [
      CertificationStatus.CERTIFICATION_STARTED_ERROR,
      CertificationStatus.CERTIFICATION_CREATION_ERROR,
      CertificationStatus.LINK_CREATION_ERROR,
      CertificationStatus.LINK_MANAGEMENT_ERROR,
    ];
    CertificationService.get(certificationId)
      .then((response) => {
        const { status } = response.data;
        if (errorCount.current < maxRetry) {
          if (!keepGoingStatus.includes(status)) {
            if (unavailableServiceStatus.includes(status)) {
              history.push("/service-indisponible");
            } else if (status === CertificationStatus.CERTIFICATION_COMPLETED) {
              setIsCertified(true);
              history.push("/compte-certifie");
            } else {
              // pour les cas non gérés
              history.push("/service-indisponible");
            }
          } else {
            setTimeout(
              () => UpdateCertificationStatus(certificationId),
              IntervalRetry,
            );
          }
        } else {
          history.push("/service-indisponible");
        }
      })
      .catch(() => {
        errorCount.current += 1;
        if (errorCount.current < maxRetry) {
          setTimeout(
            () => UpdateCertificationStatus(certificationId),
            IntervalRetry,
          );
        } else {
          // Si pas de réponse au bout de maxRetry alors service indisponible
          history.push("/service-indisponible");
        }
      });
  };

  useEffect(() => {
    const getProcurationResponse = async () => {
      const idToken = TokenService.getIdToken();
      const procurationResponse = await CertificationService.getProcuration(
        idToken,
      );

      switch (procurationResponse?.data?.result) {
        case ProcurationStatus.NOT_FOUND:
        case ProcurationStatus.FOUND_WITH_DIFFERENT_ID:
          CertificationService.setProcurationStatus("NOT_FOUND");
          await CompanyService.get(true);
          history.push("/verification-representant-legal");
          break;
        case ProcurationStatus.FOUND_AND_STARTED:
          CertificationService.setProcurationStatus("FOUND_AND_STARTED");
          setTimeout(
            () =>
              UpdateCertificationStatus(
                procurationResponse?.data?.certificationId,
              ),
            IntervalStart,
          );
          break;
        case ProcurationStatus.FOUND_WITH_DIFFERENT_EMAIL:
          CertificationService.setProcurationStatus(
            "FOUND_WITH_DIFFERENT_EMAIL",
          );
          history.push("/choix-piece-identite");
          break;
        default:
          history.push(SERVICE_UNAVAILABLE.path);
          break;
      }
    };
    getProcurationResponse();
  });

  return <Loader isLoading />;
};

export default VerifyProcuration;
