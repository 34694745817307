import React, { useState } from "react";
import UploadItem from "models/UploadItem";
import Upload from "models/Upload";
import { useTranslation } from "react-i18next";
import UploadTypeStatus from "utils/UploadTypeStatus";
import { Link } from "@material-ui/core";

const useUpload = () => {
  const { t } = useTranslation();

  const generateUploadItems = () => [
    new Upload({
      id: "IDCARD",
      label: t("upload:common.item.idcard.label"),
      type: UploadTypeStatus.ID,
      determinant: "la",
      labelDetails: () => (
        <span>
          {t("upload:common.item.the.label")}
          <strong> {t("upload:common.item.recto.label")} </strong>
          {t("upload:common.item.and.label")}{" "}
          {t("upload:common.item.the.label")}
          <strong> {t("upload:common.item.verso.label")} </strong>
          {t("upload:item.idcard.labelDetails")}
        </span>
      ),
      uploadItems: [
        new UploadItem({
          label: () => t("upload:common.item.addFront.label"),
          id: "ID_RECTO",
          file: null,
          documentId: null,
          progress: 0,
        }),
        new UploadItem({
          label: () => t("upload:common.item.addBack.label"),
          id: "ID_VERSO",
          file: null,
          documentId: null,
          progress: 0,
        }),
      ],
    }),
    new Upload({
      id: "IDCARD_RL",
      label: t("upload:common.item.idcard.label"),
      type: UploadTypeStatus.ID_RL,
      determinant: "la",
      labelDetails: () => (
        <span>
          {t("upload:common.item.the.label")}
          <strong> {t("upload:common.item.recto.label")} </strong>
          {t("upload:common.item.and.label")}{" "}
          {t("upload:common.item.the.label")}
          <strong> {t("upload:common.item.verso.label")} </strong>
          {t("upload:item.idcardRL.labelDetails")}
          <strong> {t("upload:common.item.legalRepresentative.label")} </strong>
        </span>
      ),
      uploadItems: [
        new UploadItem({
          label: () => t("upload:common.item.addFront.label"),
          id: "ID_RECTO_RL",
          file: null,
          documentId: null,
          progress: 0,
        }),
        new UploadItem({
          label: () => t("upload:common.item.addBack.label"),
          id: "ID_VERSO_RL",
          file: null,
          documentId: null,
          progress: 0,
        }),
      ],
    }),
    new Upload({
      id: "PASSPORT",
      label: t("upload:common.item.passport.label"),
      type: UploadTypeStatus.ID,
      determinant: "le",
      labelDetails: () => "",
      uploadItems: [
        new UploadItem({
          label: () => t("upload:common.item.addPage.label"),
          id: "P_PAGE1",
          file: null,
          documentId: null,
          progress: 0,
        }),
      ],
    }),
    new Upload({
      id: "PASSPORT_RL",
      label: t("upload:common.item.passport.label"),
      type: UploadTypeStatus.ID_RL,
      determinant: "le",
      labelDetails: () => "",
      uploadItems: [
        new UploadItem({
          label: () => t("upload:common.item.addPage.label"),
          id: "P_PAGE1_RL",
          file: null,
          documentId: null,
          progress: 0,
        }),
      ],
    }),
    new Upload({
      id: "RESIDENTPERMIT",
      label: t("upload:common.item.residentPermit.label"),
      type: UploadTypeStatus.ID,
      determinant: "le",
      labelDetails: () => (
        <span>
          {t("upload:common.item.the.label")}
          <strong> {t("upload:common.item.recto.label")} </strong>
          {t("upload:common.item.and.label")}{" "}
          {t("upload:common.item.the.label")}
          <strong> {t("upload:common.item.verso.label")} </strong>
          {t("upload:item.residentPermit.labelDetails")}
        </span>
      ),
      uploadItems: [
        new UploadItem({
          label: () => t("upload:common.item.addFront.label"),
          id: "RP_RECTO",
          file: null,
          documentId: null,
          progress: 0,
        }),
        new UploadItem({
          label: () => t("upload:common.item.addBack.label"),
          id: "RP_VERSO",
          file: null,
          documentId: null,
          progress: 0,
        }),
      ],
    }),
    new Upload({
      id: "RESIDENTPERMIT_RL",
      label: t("upload:common.item.residentPermit.label"),
      type: UploadTypeStatus.ID_RL,
      determinant: "le",
      labelDetails: () => (
        <span>
          {t("upload:common.item.the.label")}
          <strong> {t("upload:common.item.recto.label")} </strong>
          {t("upload:common.item.and.label")}{" "}
          {t("upload:common.item.the.label")}
          <strong> {t("upload:common.item.verso.label")} </strong>
          {t("upload:item.residentPermitRL.labelDetails")}
          <strong> {t("upload:common.item.legalRepresentative.label")} </strong>
        </span>
      ),
      uploadItems: [
        new UploadItem({
          label: () => t("upload:common.item.addFront.label"),
          id: "RP_RECTO_RL",
          file: null,
          documentId: null,
          progress: 0,
        }),
        new UploadItem({
          label: () => t("upload:common.item.addBack.label"),
          id: "RP_VERSO_RL",
          file: null,
          documentId: null,
          progress: 0,
        }),
      ],
    }),
    new Upload({
      id: "SUBDOC",
      label: t("upload:item.subDoc.label"),
      type: UploadTypeStatus.SUBDELEGATION,
      determinant: "l'",
      labelDetails: () => "",
      uploadItems: [
        new UploadItem({
          label: () => t("useUpload:addTheDocument"),
          id: "SUBDOC_FILE",
          file: null,
          documentId: null,
          progress: 0,
        }),
      ],
    }),
    new Upload({
      id: "IMMATPENDING",
      label: t("upload:common.item.foreign.label"),
      type: UploadTypeStatus.COMPANY,
      determinant: "les",
      labelDetails: () => [
        <>
          <span className="strongLabel">
            {t("upload:item.immatPending.labelDetails.part1")}
          </span>
          {t("upload:item.immatPending.labelDetails.part2")}
        </>,
        <>
          {t("common:or")}{" "}
          <span className="strongLabel">
            {t("upload:item.immatPending.labelDetails.part3")}
          </span>
          {t("upload:item.immatPending.labelDetails.part4")}
        </>,
        <>
          {t("common:or")}{" "}
          <span className="strongLabel">
            {t("upload:item.immatPending.labelDetails.part5")}
          </span>
          {t("upload:item.immatPending.labelDetails.part6")}
        </>,
      ],
      uploadItems: [
        new UploadItem({
          label: () => t("useUpload:addTheDocument"),
          id: "IMMATPENDING_FILE",
          file: null,
          documentId: null,
          progress: 0,
        }),
      ],
    }),
    new Upload({
      id: "ASSOCIATION",
      label: t("upload:common.item.foreign.label"),
      type: UploadTypeStatus.COMPANY,
      determinant: "les",
      labelDetails: () => [
        <span className="strongLabel">
          {t("upload:item.association.label.labelDetails.part1")}
        </span>,
        <>
          {t("common:or")}{" "}
          <span className="strongLabel">
            {t("upload:item.association.label.labelDetails.part2")}
          </span>
          {t("upload:item.association.label.labelDetails.part3")}
        </>,
        <>
          {t("common:or")}{" "}
          <span className="strongLabel">
            {t("upload:item.association.label.labelDetails.part4")}
          </span>
        </>,
      ],
      uploadItems: [
        new UploadItem({
          label: () => t("upload:common.item.addFile.label"),
          id: "ASSOCIATION_FILE",
          file: null,
          documentId: null,
          progress: 0,
        }),
      ],
    }),
    new Upload({
      id: "LIBERAL",
      label: t("upload:common.item.foreign.label"),
      type: UploadTypeStatus.COMPANY,
      determinant: "les",
      labelDetails: () => (
        <span className="strongLabel">
          {t("upload:item.liberal.labelDetails")}
        </span>
      ),
      uploadItems: [
        new UploadItem({
          label: () => t("upload:common.item.addFile.label"),
          id: "LIBERAL_FILE",
          file: null,
          documentId: null,
          progress: 0,
        }),
      ],
    }),
    new Upload({
      id: "IMMATDONE",
      label: t("upload:common.item.foreign.label"),
      type: UploadTypeStatus.COMPANY,
      determinant: "les",
      labelDetails: () => (
        <span className="strongLabel">
          {t("upload:item.immatDone.labelDetails")}
        </span>
      ),
      uploadItems: [
        new UploadItem({
          label: () => t("upload:common.item.addFile.label"),
          id: "IMMATDONE_FILE",
          file: null,
          documentId: null,
          progress: 0,
        }),
      ],
    }),
    new Upload({
      id: "FOREIGN",
      label: t("upload:common.item.foreign.label"),
      type: UploadTypeStatus.COMPANY,
      determinant: "les",
      labelDetails: () => (
        <span className="strongLabel">
          {t("upload:item.foreign.labelDetails")}
        </span>
      ),
      uploadItems: [
        new UploadItem({
          label: () => t("useUpload:addTheDocument"),
          id: "FOREIGN_FILE",
          file: null,
          documentId: null,
          progress: 0,
        }),
      ],
    }),
  ];

  const [uploadItems] = useState(generateUploadItems());

  const getItemsUploadLabels = (itemId) =>
    uploadItems.find((item) => item.id === itemId).uploadItems;

  const getStateItem = (uploadState) => {
    const stateItems = uploadState.split("_");
    return {
      type: stateItems[0],
      item:
        stateItems.length === 3 && stateItems[2] === "RL"
          ? `${stateItems[1]}_${stateItems[2]}`
          : stateItems[1],
    };
  };

  const getItemLabel = (itemId) =>
    uploadItems.find((item) => item.id === itemId).label;

  const getUploadItems = (uploadState) =>
    getItemsUploadLabels(getStateItem(uploadState).item);

  const getItemLabelDetails = (itemId) =>
    uploadItems.find((item) => item.id === itemId).labelDetails();

  const getUploadLabelDetails = (uploadState) => {
    const itemState = getStateItem(uploadState);

    if (itemState.type === "IDENTITY") {
      if (itemState.item === "PASSPORT") {
        return (
          <div>
            {t("upload:labelDetails.sendPassport.part1")}{" "}
            <strong>{t("upload:labelDetails.sendPassport.part2")}</strong>{" "}
            {t("upload:labelDetails.sendPassport.part3")}
          </div>
        );
      }

      if (itemState.item === "PASSPORT_RL") {
        return (
          <div>
            {t("upload:labelDetails.sendRLPassport.part1")}{" "}
            <strong>{t("upload:labelDetails.sendRLPassport.part2")}</strong>{" "}
            {t("upload:labelDetails.sendRLPassport.part3")}{" "}
            <strong>{t("upload:labelDetails.sendRLPassport.part4")}</strong>
          </div>
        );
      }

      return (
        <div>
          {t("upload:labelDetails.pleaseSendUs")}{" "}
          {getItemLabelDetails(itemState.item)}{" "}
          <strong>{t("upload:labelDetails.2separeteFiles")}</strong>
        </div>
      );
    }

    if (
      (itemState.type === "COMPANY" && itemState.item === "IMMATPENDING") ||
      (itemState.type === "COMPANY" && itemState.item === "ASSOCIATION")
    ) {
      return (
        <div className="detailsList">
          {t("upload:labelDetails.sendFollowingDocuments")}
          <ul>
            {getItemLabelDetails(itemState.item).map((line, i) => (
              <li key={`line_${i.toString()}`}>{line}</li>
            ))}
          </ul>
        </div>
      );
    }

    if (itemState.type === "COMPANY" && itemState.item === "SUBDOC") {
      return (
        <div>
          {t("upload:labelDetails.subDoc.part1")}
          <Link href="/POUVOIR DE DELEGATION POSTALE.pdf" target="_blank">
            {t("upload:labelDetails.subDoc.part2")}
          </Link>{" "}
          {t("upload:labelDetails.subDoc.part3")}
        </div>
      );
    }

    return (
      <div className="detailsList">
        {t("upload:labelDetails.sendFollowingDocument")}
        <ul>
          <li>{getItemLabelDetails(itemState.item)}</li>
        </ul>
      </div>
    );
  };

  const getReturnUrl = (uploadState, subdelegate) => {
    const itemState = getStateItem(uploadState);

    if (itemState.type === "COMPANY" && !subdelegate) {
      return "/choix-statut-societe";
    }

    if (subdelegate) {
      if (itemState.item.slice(-2) !== "RL") {
        return "/certification-rl-subdelegataire/choix-piece-identite";
      }
      return "/certification-rl-subdelegataire/choix-piece-identite-representant-legal";
    }

    return "/choix-piece-identite";
  };

  const getItemDeterminant = (itemId) =>
    uploadItems.find((item) => item.id === itemId).determinant;

  const getUploadTitlePage = (uploadState) => {
    const itemState = getStateItem(uploadState);

    const isRLscreen = itemState.item.includes("_RL");

    if (itemState.type === "IDENTITY" && !isRLscreen) {
      return `${t("upload:titlePage.importYour")} ${getItemLabel(
        itemState.item,
      )}`;
    }

    if (isRLscreen) {
      return `Importez ${getItemDeterminant(itemState.item)} ${getItemLabel(
        itemState.item,
      )} ${t("upload:titlePage.ofLegalRepresentative")}`;
    }

    if (itemState.item === "SUBDOC") {
      return t("upload:titlePage.subDoc");
    }

    return t("upload:titlePage.default");
  };

  const getFileIcon = (mimetype) => {
    if (
      mimetype === "image/png" ||
      mimetype === "image/jpg" ||
      mimetype === "image/jpeg"
    ) {
      return "IMAGE";
    }
    return "PDF";
  };

  const getWarningLabels = (status, detail) => {
    if (status === 413) {
      return {
        title: t("upload:warning.maxFileSizeError.title"),
        content: t("upload:warning.maxFileSizeError.content"),
      };
    }

    if (status === 400 && detail === "bad_mime_type") {
      return {
        title: t("upload:warning.badMimeTypeError.title"),
        content: t("upload:warning.badMimeTypeError.content"),
      };
    }

    return {
      title: t("upload:warning.defaultError.title"),
      content: t("upload:warning.defaultError.content"),
    };
  };

  const deleteIdFilesFromUpload = () => {
    uploadItems
      .filter(
        (item) =>
          item.id === "IDCARD" ||
          item.id === "PASSPORT" ||
          item.id === "RESIDENTPERMIT",
      )
      .forEach((filteredItem) => {
        filteredItem.uploadItems.forEach((uploadItem) => {
          if (uploadItem.documentId !== null) {
            const newUploadItem = uploadItem;

            newUploadItem.file = null;
            newUploadItem.documentId = null;
            newUploadItem.progress = 0;
          }
        });
      });
  };

  const deleteUploadedFilesByType = (type) => {
    uploadItems
      .filter((item) => item.type === type)
      .forEach((filteredItem) => {
        filteredItem.uploadItems.forEach((uploadItem) => {
          if (uploadItem.documentId !== null) {
            const newUploadItem = uploadItem;

            newUploadItem.file = null;
            newUploadItem.documentId = null;
            newUploadItem.progress = 0;
          }
        });
      });
  };

  return {
    uploadItems,
    getUploadLabelDetails,
    getUploadTitlePage,
    getUploadItems,
    getReturnUrl,
    getFileIcon,
    getWarningLabels,
    deleteIdFilesFromUpload,
    deleteUploadedFilesByType,
  };
};

export default useUpload;
