import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSettings } from "components/Settings";
import Step from "components/Layout/Step";
import { getAutomaticStatus } from "utils/AutomaticStatusUtil";
import IdSelectionStep from "components/IdSelectionStep";
import Box from "@material-ui/core/Box";
import Actions from "components/Layout/Actions";
import Button from "components/Button";
import MainContent from "components/Layout/MainContent";
import CertificationService from "services/CertificationService";
import { useTranslation } from "react-i18next";
import CompanyService from "services/CompanyService";
import CompanyDirectorStatus from "utils/CompanyDirectorStatus";

const CompanyStatus = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { automaticProcessEnabled } = useSettings();
  const [idType, setIdType] = useState("IDENTITY_IDCARD");
  const [previousStep, setPreviousStep] = useState(true);

  const goToUpload = () => {
    history.push({
      pathname: "/import/piece-identite",
      state: { access: idType },
    });
  };

  useEffect(() => {
    const getPreviousStep = async () => {
      const companyDirectorStatus = await CompanyService.getStatus();

      const company = await CompanyService.get();
      const dirigeants = company?.dirigeants || [];

      let newPreviousStep = "";

      // Vérification que l'utilisateur est un auto entrepreneur
      if (
        dirigeants.length === 0 &&
        companyDirectorStatus === CompanyDirectorStatus.USER_IS_DIRECTOR
      ) {
        newPreviousStep = "/verification-client";
      } else {
        newPreviousStep =
          CertificationService.getProcurationStatus() ===
            "FOUND_WITH_DIFFERENT_EMAIL" ||
          getAutomaticStatus() ||
          !automaticProcessEnabled
            ? "/choix-representant-legal"
            : "/choix-statut-societe";
      }
      setPreviousStep(newPreviousStep);
    };

    getPreviousStep();
  }, [automaticProcessEnabled]);

  return (
    <Step
      title={t("idSelection:title")}
      previousStepPath={previousStep}
      progress={73}
    >
      <MainContent>
        <IdSelectionStep idType={idType} setIdType={setIdType} />
      </MainContent>
      <Actions>
        <Box mb={2} style={{ width: "100%" }} onClick={goToUpload}>
          <Button data-testid="test-button">{t("common:continue")}</Button>
        </Box>
      </Actions>
    </Step>
  );
};

export default CompanyStatus;
