import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Actions from "components/Layout/Actions";
import MainContent from "components/Layout/MainContent";
import Button from "components/Button";
import Link from "components/Link";
import Step from "components/Layout/Step";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import MiniCard from "components/Layout/MiniCard";
import {
  RESULT_OPTIONS,
  getResult,
  setResult as setResultCondition,
} from "utils/TestModeParametersUtil";

function ResultConditions() {
  const title = "Résultat attendu:";

  const [result, setResult] = useState(getResult());

  const disabled = !result;

  const goOnButton = () => (
    <Button
      disabled={disabled}
      onClick={() => setResultCondition(result)}
      data-testid="test-button"
    >
      Continuer
    </Button>
  );

  return (
    <Step
      title={title}
      previousStepPath="/conditions-piece-d-identite"
      progress={700 / 8}
    >
      <MainContent>
        <FormControl fullWidth component="fieldset">
          <RadioGroup
            name="result"
            value={result}
            onChange={(e) => setResult(e.currentTarget.value)}
          >
            <MiniCard type="tertiary">
              <FormControlLabel
                htmlFor="isNotCertified"
                value={RESULT_OPTIONS.IMPOSSIBLE_CERTIFICATION}
                control={<Radio id="isNotCertified" />}
                label={
                  <Typography variant="subtitle2">
                    impossible de certifier le compte
                  </Typography>
                }
              />
            </MiniCard>

            <MiniCard type="tertiary">
              <FormControlLabel
                htmlFor="isManual"
                value={RESULT_OPTIONS.MANUAL_CERTIFICATION}
                control={<Radio id="isManual" />}
                label={
                  <Typography variant="subtitle2">
                    votre demande sera traitée manuellement
                  </Typography>
                }
              />
            </MiniCard>

            <MiniCard type="tertiary">
              <FormControlLabel
                htmlFor="isCertified"
                value={RESULT_OPTIONS.AUTOMATIC_CERTIFICATION}
                control={<Radio id="isCertified" />}
                label={
                  <Typography variant="subtitle2">
                    votre compte est certifié automatiquement
                  </Typography>
                }
              />
            </MiniCard>
          </RadioGroup>
        </FormControl>
      </MainContent>
      <Actions>
        <Box mb={2} style={{ width: "100%" }}>
          {disabled ? (
            goOnButton()
          ) : (
            <Link
              data-testid="test-link"
              to={{
                pathname: "/commencer-test",
                state: { from: "/conditions-resultat" },
              }}
              underline="none"
            >
              {goOnButton()}
            </Link>
          )}
        </Box>
      </Actions>
    </Step>
  );
}

export default ResultConditions;
