import UrlParametersKey from "utils/UrlParametersKey";

const entry = sessionStorage.getItem(UrlParametersKey.ENTRY);
export const getTags = ({
  ch1 = "certification-rl",
  ch2,
  ch3,
  pageName,
  step,
  userId,
  motifBascule,
}) => ({
  env_work: process.env.REACT_APP_TC_ENV_WORK,
  env_channel: "web",
  nom_site: process.env.REACT_APP_TC_SITE_NAME,
  user_status: "actif",
  xiti_xtn2: "2",
  chapter1: ch1,
  chapter2: ch2,
  chapter3: ch3,
  page_name: pageName,
  new_page_name: `${ch1}::${ch2}::${pageName}`,
  step,
  user_id: userId,
  entry_type: entry || "direct",
  motif_bascule_manuel: motifBascule,
});

export default { getTags };
