import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import PreventManualRequestsService from "services/PreventManualRequestsService";
import Loader from "components/Loader";
import ManualSwitchStatus from "utils/ManualSwitchStatus";
import {
  getManualSwitchStatus,
  setManualSwitchStatus,
} from "utils/AutomaticStatusUtil";
import UserService from "services/UserService";

const PreventManualRequestsProvider = (props) => {
  const { children, preventFunction } = props;

  const { REACT_APP_ENABLE_PREVENT_MANUAL_REQUESTS } = process.env;
  const isEnablePreventManualRequests =
    REACT_APP_ENABLE_PREVENT_MANUAL_REQUESTS === "true";

  const [isLoading, setIsLoading] = useState(isEnablePreventManualRequests);

  const history = useHistory();

  const doPrevent = useMemo(() => preventFunction(), [preventFunction]);

  useEffect(() => {
    const checkPreventManualRequests = async () => {
      if (isEnablePreventManualRequests) {
        try {
          if (doPrevent) {
            const { data } =
              await PreventManualRequestsService.checkPreventManualRequests();
            if (data) {
              setIsLoading(false);
            } else {
              const status = getManualSwitchStatus();
              if (
                status === ManualSwitchStatus.USER_IS_NOT_DIRECTOR ||
                status === ManualSwitchStatus.NO_PHYSICAL_DIRECTOR ||
                status === ManualSwitchStatus.SUBDELEGATION
              ) {
                history.push("/dirigeant-non-connu");
              } else {
                const hasSiretNumber = UserService.hasSiretNumber();
                const hasCompanyInfo = UserService.hasCompanyInfo();
                if (!hasSiretNumber || !hasCompanyInfo) {
                  setManualSwitchStatus(
                    !hasCompanyInfo
                      ? ManualSwitchStatus.MISSING_COMPANY_INFOS
                      : ManualSwitchStatus.NO_SIRET,
                  );
                }
                history.push("/societe-non-connue");
              }
            }
          } else {
            setIsLoading(false);
          }
        } catch (_) {
          history.push("/service-indisponible");
        }
      }
    };

    checkPreventManualRequests();
  }, [isEnablePreventManualRequests, history, doPrevent]);

  return <Loader isLoading={isLoading}>{children}</Loader>;
};

PreventManualRequestsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  preventFunction: PropTypes.func.isRequired,
};

export default PreventManualRequestsProvider;
