import axios from "axios";
import { getLogoutUrlWithCallback } from "utils/UrlUtil";
import TokenService, { TOKEN_PATH } from "services/TokenService";

const initResponseInterceptor = () => {
  axios.interceptors.request.use(
    async (config) => {
      const { headers, url } = config;
      const jwt = TokenService.getJwToken();
      let { accessToken } = jwt || {};
      if (accessToken) {
        if (
          url !== TOKEN_PATH &&
          Math.round(Date.now() / 1000) >= jwt.exp - 60
        ) {
          await TokenService.refresh();
          accessToken = TokenService.getAccessToken();
        }
        headers.authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (error) => Promise.reject(error),
  );
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response } = error || {};
      const { data } = response || {};
      const { status, title } = data || {};
      if (
        status === 403 ||
        (status === 401 && title === "unauthorized_token") ||
        (status === 400 && title === "invalid_grant")
      ) {
        setTimeout(() => {
          window.location.href = getLogoutUrlWithCallback();
        }, 2000);
      }

      return Promise.reject(error);
    },
  );
};

export default initResponseInterceptor;
