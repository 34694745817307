import axios from "axios";

export default class FileService {
  static upload = (file, type, getFileUploadProgress) => {
    const data = new FormData();

    data.append("file", file);
    data.append("type", type === "IDENTITY" ? "user" : "company");

    return axios.post(
      `${process.env.REACT_APP_CRL_BACK_URL}/users/me/documents`,
      data,
      {
        onUploadProgress: (progressEvent) =>
          getFileUploadProgress(
            Math.floor((progressEvent.loaded / progressEvent.total) * 100),
          ),
      },
    );
  };
}
