import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Step from "components/Layout/Step";
import IdSelectionStep from "components/IdSelectionStep";
import Box from "@material-ui/core/Box";
import Actions from "components/Layout/Actions";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

const SubRLIdSelection = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [idType, setIdType] = useState("IDENTITY_IDCARD_RL");

  const goToUpload = () => {
    history.push({
      pathname:
        "/certification-rl-subdelegataire/import/piece-identite-representant-legal",
      state: { access: idType },
    });
  };

  return (
    <Step
      title={t("subRLIdSelection:title")}
      previousStepPath="/certification-rl-subdelegataire/choix-piece-identite"
      progress={62}
    >
      <IdSelectionStep idType={idType} setIdType={setIdType} isRlId />
      <Actions>
        <Box mb={2} style={{ width: "100%" }} onClick={goToUpload}>
          <Button>{t("common:continue")}</Button>
        </Box>
      </Actions>
    </Step>
  );
};

export default SubRLIdSelection;
