import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    "& .MuiCircularProgress-circle": {
      stroke: theme.palette.primary.main,
      strokeLinecap: "round",
    },
    "& > span": {
      color: "#666666",
      marginTop: 16,
    },
  },
}));

const Loader = (props) => {
  const { title, className, isLoading, children, ...others } = props;

  const classes = useStyles();

  return (
    <>
      {isLoading && (
        <div className={classnames(classes.root, className)} {...others}>
          <CircularProgress />
          <span>{title}</span>
        </div>
      )}
      {!isLoading && children}
    </>
  );
};

Loader.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

Loader.defaultProps = {
  title: "",
  className: "",
  isLoading: false,
  children: null,
};

export default Loader;
