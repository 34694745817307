import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
}));

const Actions = (props) => {
  const { children, className, ...others } = props;

  const classes = useStyles();

  return (
    <div className={classnames(classes.root, className)} {...others}>
      {children}
    </div>
  );
};

Actions.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Actions.defaultProps = {
  children: null,
  className: null,
};

export default Actions;
