import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Icon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="nonzero"
      d="M 12 0 C 18.617188 0 24 5.382812 24 12 C 24 18.617188 18.617188 24 12 24 C 5.382812 24 0 18.617188 0 12 C 0 5.382812 5.382812 0 12 0 Z M 16.863281 8.195312 L 10.667969 14.390625 L 7.804688 11.527344 C 7.542969 11.269531 7.121094 11.269531 6.863281 11.527344 C 6.601562 11.789062 6.601562 12.210938 6.863281 12.472656 L 10.667969 16.277344 L 17.804688 9.136719 C 18.066406 8.878906 18.066406 8.457031 17.804688 8.195312 C 17.542969 7.933594 17.121094 7.933594 16.863281 8.195312 Z M 16.863281 8.195312 "
    />
  </SvgIcon>
);

export default Icon;
