import React, { useEffect } from "react";
import { deleteIdFiles } from "utils/FileUtil";
import { useTranslation } from "react-i18next";
import useUpload from "hook/useUpload";
import IdIllegible from "./IdIllegible";

const InvalidId = () => {
  const { t } = useTranslation();
  const { deleteIdFilesFromUpload } = useUpload();

  useEffect(() => {
    deleteIdFiles();
    deleteIdFilesFromUpload();
  }, [deleteIdFilesFromUpload]);

  return (
    <IdIllegible
      title={t("invalidId:title")}
      label={t("invalidId:label")}
      btnLabel={t("invalidId:btnLabel")}
    />
  );
};

export default InvalidId;
