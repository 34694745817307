import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Actions from "components/Layout/Actions";
import MainContent from "components/Layout/MainContent";
import Button from "components/Button";
import Link from "components/Link";
import Step from "components/Layout/Step";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import MiniCard from "components/Layout/MiniCard";
import {
  ACCOUNT_STATUS_OPTIONS,
  COMPANY_STATUS_OPTIONS,
  getAccountStatus,
  setAccountStatus as setStatus,
  getCompanyStatus,
} from "utils/TestModeParametersUtil";

function AccountConditions() {
  const title = "Votre compte:";

  const [accountStatus, setAccountStatus] = useState(getAccountStatus());
  const companyStatus = getCompanyStatus() || [];

  const disabled = !accountStatus;

  const goOnButton = () => (
    <Button
      disabled={disabled}
      onClick={() => setStatus(accountStatus)}
      data-testid="test-button"
    >
      Continuer
    </Button>
  );

  return (
    <Step
      title={title}
      previousStepPath="/conditions-entreprise"
      progress={400 / 8}
    >
      <MainContent>
        <FormControl fullWidth component="fieldset">
          <RadioGroup
            name="account"
            value={accountStatus}
            onChange={(e) => setAccountStatus(e.currentTarget.value)}
          >
            <MiniCard type="tertiary">
              <FormControlLabel
                htmlFor="isCertified"
                value={ACCOUNT_STATUS_OPTIONS.IS_ALREADY_CERTIFIED}
                control={<Radio id="isCertified" />}
                label={
                  <Typography variant="subtitle2">est déjà certifié</Typography>
                }
              />
            </MiniCard>

            <MiniCard type="tertiary">
              <FormControlLabel
                htmlFor="inProcess"
                value={ACCOUNT_STATUS_OPTIONS.CERTIFICATION_IN_PROCESS}
                control={<Radio id="inProcess" />}
                label={
                  <Typography variant="subtitle2">
                    est en cours de certification
                  </Typography>
                }
              />
            </MiniCard>

            <MiniCard type="tertiary">
              <FormControlLabel
                htmlFor="realPath"
                value={ACCOUNT_STATUS_OPTIONS.REAL_PATH}
                control={<Radio id="realPath" />}
                label={
                  <Typography variant="subtitle2">pas de conditions</Typography>
                }
              />
            </MiniCard>
          </RadioGroup>
        </FormControl>
      </MainContent>
      <Actions>
        <Box mb={2} style={{ width: "100%" }}>
          {disabled ? (
            goOnButton()
          ) : (
            <Link
              data-testid="test-link"
              to={{
                pathname:
                  accountStatus === ACCOUNT_STATUS_OPTIONS.REAL_PATH &&
                  !companyStatus.includes(COMPANY_STATUS_OPTIONS.NO_SIRET) &&
                  !companyStatus.includes(COMPANY_STATUS_OPTIONS.INVALID_ADRESS)
                    ? "/conditions-representant-legal"
                    : "/commencer-test",
                state: { from: "/conditions-compte" },
              }}
              underline="none"
            >
              {goOnButton()}
            </Link>
          )}
        </Box>
      </Actions>
    </Step>
  );
}

export default AccountConditions;
