import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const NoReflectIdIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 74 54">
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M45.075 1.782v27.777c.022.461-.33.854-.79.88H3.822c-.46-.028-.81-.419-.79-.88V1.782c-.018-.459.332-.849.79-.88h40.463c.459.029.81.42.79.88z"
          transform="translate(14 11)"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M42.872 1.254v27.702c.029.448-.318.837-.782.877H1.883c-.464-.04-.811-.429-.782-.877V1.254c-.03-.448.318-.837.782-.877H42.09c.464.04.81.429.782.877z"
          transform="translate(14 11)"
        />
        <path
          fill="#20458F"
          d="M45.864 29.559V1.782c.029-.931-.691-1.714-1.62-1.76H1.62C.69.068-.03.851 0 1.782v27.777c-.028.931.69 1.712 1.618 1.76h42.626c.927-.048 1.646-.829 1.619-1.76zM44.289 1.8v27.742c-.015.004-.03.004-.044 0H1.619c-.014.005-.03.005-.044 0V1.8c.014-.006.03-.006.044 0h42.626c.014-.004.029-.004.044 0z"
          transform="translate(14 11)"
        />
        <path
          fill="#20458F"
          fillRule="nonzero"
          d="M1.142 1.268L45.232 1.268 45.232 7.584 1.142 7.584z"
          transform="translate(14 11)"
        />
        <path
          fill="#20458F"
          d="M17.519 12.004h14.486V13.9H17.519v-1.896zm0 3.158h17.635v1.896H17.519v-1.896zm14.486 3.158H17.519v1.896h14.486V18.32zm-14.486 3.158h10.079v1.896h-10.08v-1.896zm-2.522-9.474H5.55v11.371h9.447V12.004z"
          transform="translate(14 11)"
        />
        <path
          fill="#EDF3FF"
          fillRule="nonzero"
          d="M13.294 23.375c-.026-3.224-.79-5.19-1.754-6.07.87-.595 1.247-1.692.927-2.698-.32-1.007-1.26-1.683-2.313-1.663-1.053.019-1.968.73-2.25 1.747-.283 1.018.135 2.1 1.026 2.663-1.031.945-1.842 2.986-1.842 6.021h6.206z"
          transform="translate(14 11)"
        />
        <path
          fill="#FFF"
          d="M27.652 0L15.84 31.336h4.228L31.845 0h-4.193zm-9.127.001L6.42 31.342h8.324L26.45.001h-7.925zm2.52 31.339L32.885 0h1.557L22.923 31.34h-1.877z"
          opacity=".7"
          transform="translate(14 11)"
        />
        <g transform="translate(14 11) translate(4.888 9.617)">
          <circle
            cx="32.339"
            cy="5.37"
            r="1.427"
            stroke="#92004D"
            strokeWidth=".951"
          />
          <path
            stroke="#92004D"
            strokeWidth=".951"
            d="M7.781.001L5.091 2.691M32.512 17.121L29.821 19.811M21.876 7.274L21.876 9.176M21.876 11.078L21.876 12.981M24.729 10.127L22.827 10.127M20.924 10.127L19.022 10.127M7.782 2.69L5.091 0M32.511 19.811L29.821 17.121"
          />
          <circle
            cx="28.058"
            cy="2.041"
            r="1"
            fill="#92004D"
            fillRule="nonzero"
          />
          <circle
            cx="11.889"
            cy="15.357"
            r="1"
            fill="#92004D"
            fillRule="nonzero"
          />
          <circle
            cx=".951"
            cy="6.321"
            r="1"
            fill="#92004D"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default NoReflectIdIcon;
