import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Actions from "components/Layout/Actions";
import Illustration from "components/Layout/Illustration";
import MainContent from "components/Layout/MainContent";
import Button from "components/Button";
import Step from "components/Layout/Step";
import usePartners from "hook/usePartners";
import UserService from "services/UserService";
import Link from "components/Link";
import { useLocation } from "react-router-dom";

function StartTest() {
  const { getPartnerHomeLogo } = usePartners();

  const title = "Mode Test";

  const location = useLocation();

  const { from } = location.state || {};

  return (
    <Step progress={100} previousStepPath={from}>
      <Illustration>
        <img src={getPartnerHomeLogo()} alt={title} aria-hidden title={title} />
      </Illustration>
      <MainContent>
        <Typography
          variant="body1"
          component="div"
          style={{ marginTop: "1.5rem", textAlign: "center" }}
        >
          Vous avez configuré les conditions nécessaires.
        </Typography>
      </MainContent>
      <Actions>
        <Box mb={2} style={{ width: "100%" }}>
          <Link to="/" underline="none">
            <Button onClick={UserService.refreshUser}>Démarrer le test</Button>
          </Link>
        </Box>
      </Actions>
    </Step>
  );
}

export default StartTest;
