import React, { useCallback, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/styles/makeStyles";
import UserService from "services/UserService";
import User from "models/User";
import Loader from "components/Loader";
import Actions from "components/Layout/Actions";
import MainContent from "components/Layout/MainContent";
import Step from "components/Layout/Step";
import Button from "components/Button";
// import Link from "components/Link";
import TextField from "components/TextField";
import MessageContext from "components/MessageContext";
import CompanyService from "services/CompanyService";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Typography } from "@material-ui/core";
import TokenService from "services/TokenService";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  form: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  civilityLabel: {
    fontSize: "16px",
    lineHeight: "24px",
  },
}));

const UpdateUserInfo = () => {
  const [user, setUser] = useState(new User());
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const history = useHistory();
  const { t } = useTranslation();

  const { dispatchError } = useContext(MessageContext);

  const fieldRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\-\s]*$/;

  useEffect(() => {
    UserService.get().then((userResponse) => {
      setUser(userResponse);
    });
  }, []);

  const goToNextStep = useCallback(() => {
    history.push("/verification-procuration");
  }, [history]);

  const handleValidateInfo = useCallback(
    async (values) => {
      setIsLoading(true);

      try {
        await UserService.updateInfo({
          ...values,
          familyName: values.familyName.trim(),
          givenName: values.givenName.trim(),
        });

        await UserService.refreshUser();
        await TokenService.refresh();
        goToNextStep();
      } catch (_) {
        setIsLoading(false);
        dispatchError(
          <>
            {t("common:error.internal")}
            <br />
            {t("common:error.notUpdated")}
          </>,
        );
      }
    },
    [t, goToNextStep, dispatchError],
  );

  const formik = useFormik({
    initialValues: user,
    validationSchema: Yup.object().shape({
      familyName: Yup.string()
        .matches(fieldRegex, {
          message: t("updateUserInfo:regex.error.numbersAndSpecialChars"),
        })
        .required(t("common:error.requiredField")),
      givenName: Yup.string()
        .matches(fieldRegex, {
          message: t("updateUserInfo:regex.error.numbersAndSpecialChars"),
        })
        .required(t("common:error.requiredField")),
      gender: Yup.number()
        .oneOf([1, 2])
        .required(t("common:error.requiredField")),
    }),
    onSubmit: handleValidateInfo,
  });

  useEffect(() => {
    formik.resetForm({
      values: user,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleInputChange = (e) => {
    e.preventDefault();

    if (e.target.value.match(fieldRegex) !== null) {
      formik.setFieldValue(e.target.name, e.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { dirty, isValid } = formik;
    if (isValid && !dirty) {
      goToNextStep();
    } else {
      formik.handleSubmit(e);
    }
  };

  const handleChangeGender = useCallback(
    (gender) => {
      formik.setFieldValue("gender", gender);
    },
    [formik],
  );

  return (
    <Step
      title={t("updateUserInfo:title")}
      previousStepPath={
        CompanyService.getChoiceOfLegalRepresentative() === ""
          ? "/verification-societe"
          : "/choix-representant-legal"
      }
      progress={45}
    >
      <Loader isLoading={isLoading}>
        <form onSubmit={handleSubmit} className={classes.form}>
          <MainContent>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    value={formik.values.gender}
                    onChange={(e) =>
                      handleChangeGender(parseInt(e.currentTarget.value, 10))
                    }
                  >
                    <FormControlLabel
                      htmlFor="civilityLabelMme"
                      value={2}
                      control={<Radio id="civilityLabelMme" />}
                      label={
                        <Typography
                          component="span"
                          className={classes.civilityLabel}
                        >
                          {t("updateUserInfo:ms")}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      htmlFor="civilityLabelMr"
                      value={1}
                      control={<Radio id="civilityLabelMr" />}
                      label={
                        <Typography
                          component="span"
                          className={classes.civilityLabel}
                        >
                          {t("updateUserInfo:mr")}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item>
                <TextField
                  label={t("common:firstName")}
                  name="givenName"
                  error={
                    formik.touched.givenName && Boolean(formik.errors.givenName)
                  }
                  helperText={
                    formik.touched.givenName && formik.errors.givenName
                  }
                  value={formik.values.givenName}
                  inputProps={{
                    maxLength: 30,
                    autocomplete: "given-name",
                    id: "givenName",
                  }}
                  onChange={handleInputChange}
                  onBlur={formik.handleBlur}
                  InputLabelProps={{ shrink: true, htmlFor: "givenName" }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label={t("common:lastName")}
                  name="familyName"
                  error={
                    formik.touched.familyName &&
                    Boolean(formik.errors.familyName)
                  }
                  helperText={
                    formik.touched.familyName && formik.errors.familyName
                  }
                  value={formik.values.familyName}
                  inputProps={{
                    maxLength: 50,
                    autocomplete: "family-name",
                    id: "familyName",
                  }}
                  onChange={handleInputChange}
                  onBlur={formik.handleBlur}
                  InputLabelProps={{ shrink: true, htmlFor: "familyName" }}
                />
              </Grid>
            </Grid>
          </MainContent>
          <Actions>
            <Box mb={2} style={{ width: "100%" }}>
              <Button type="submit" disabled={!formik.isValid}>
                {t("common:continue")}
              </Button>
            </Box>
          </Actions>
        </form>
      </Loader>
    </Step>
  );
};

export default UpdateUserInfo;
