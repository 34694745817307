import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const TitreSejourIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fill="#E0E9F6"
      d="M21.327 23.556L7.08 19.327c-.744-.222-1.172-1.012-.95-1.757l2.227-7.482c.222-.745 1.012-1.173 1.757-.95l16.107 4.794-2.211 7.55c-1.011-.848-2.791.576-2.683 2.074"
      transform="translate(-25 -349) translate(16 338) translate(9 11) translate(5 4)"
    />
    <path
      stroke="#194086"
      strokeLinejoin="round"
      d="M17.305 23.982l-5.266-1.54c-.573-.172-.885-.84-.692-1.486l.03-.1"
      transform="translate(-25 -349) translate(16 338) translate(9 11) translate(5 4)"
    />
    <path
      stroke="#194086"
      strokeLinejoin="round"
      d="M19.62 27.037l-4.874-1.452c-.574-.171-.903-.78-.732-1.352l.026-.09c.17-.572.779-.901 1.352-.731l4.876 1.452c.573.17.903.779.732 1.352l-.026.089c-.171.573-.78.902-1.353.732zM19.92 29.494l-4.787-1.425c-.598-.178-.941-.812-.763-1.41.178-.597.812-.94 1.41-.763l4.786 1.426c.598.177.942.812.764 1.41-.18.596-.812.94-1.41.762z"
      transform="translate(-25 -349) translate(16 338) translate(9 11) translate(5 4)"
    />
    <path
      stroke="#194086"
      strokeLinejoin="round"
      d="M16.054 30.685c-.573-.17-.902-.779-.73-1.353l.025-.088c.17-.573.757-.836 1.33-.666l3.91 1.088"
      transform="translate(-25 -349) translate(16 338) translate(9 11) translate(5 4)"
    />
    <path
      stroke="#194086"
      d="M30.703 15.601c0-8.34-6.762-15.1-15.102-15.1S.5 7.26.5 15.6s6.76 15.101 15.1 15.101 15.103-6.76 15.103-15.1z"
      transform="translate(-25 -349) translate(16 338) translate(9 11) translate(5 4)"
    />
    <path
      stroke="#194086"
      d="M21.327 23.556L6.204 18.867c-.88-.27-1.377-1.212-1.106-2.092l2.93-9.498C8.3 6.397 9.242 5.9 10.12 6.171l15.396 4.75c.879.271 1.377 1.212 1.106 2.091l-2.612 8.47c-1.621-.476-2.24.205-2.683 2.074z"
      transform="translate(-25 -349) translate(16 338) translate(9 11) translate(5 4)"
    />
    <path
      fill="#194086"
      d="M17.38 13.715c0 .877-.711 1.588-1.59 1.588-.876 0-1.587-.711-1.587-1.588 0-.877.71-1.59 1.588-1.59.878 0 1.589.713 1.589 1.59"
      transform="translate(-25 -349) translate(16 338) translate(9 11) translate(5 4)"
    />
    <path
      fill="#194086"
      d="M15.79 15.303c.32 0 .617-.096.866-.258-.297-.288-.655-.52-1.075-.65-.421-.13-.848-.139-1.256-.068.24.573.806.976 1.466.976"
      transform="translate(-25 -349) translate(16 338) translate(9 11) translate(5 4)"
    />
    <path
      fill="#194086"
      d="M15.79 16.185c-1.046 0-1.937-.657-2.296-1.58-.605.328-1.09.88-1.307 1.585l-.341 1.106c-.055.179.046.37.224.425l4.538 1.4c.179.055.37-.046.425-.225l.342-1.106c.217-.704.128-1.431-.187-2.042-.397.275-.878.437-1.397.437"
      transform="translate(-25 -349) translate(16 338) translate(9 11) translate(5 4)"
    />
    <path
      stroke="#194086"
      strokeLinejoin="round"
      d="M24.009 21.481L29.099 23.185"
      transform="translate(-25 -349) translate(16 338) translate(9 11) translate(5 4)"
    />
    <path
      stroke="#194086"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M23.557 21.374c-.964-.155-1.486.186-1.75.892l-.48 1.29c-.264.706.1 1.5.805 1.763.706.263 1.5-.1 1.763-.806l.27-.882"
      transform="translate(-25 -349) translate(16 338) translate(9 11) translate(5 4)"
    />
    <path
      fill="#194086"
      d="M26.482 13.336L8.077 7.856l.547-1.838 17.024 5.068c.763.228 1.197 1.03.97 1.792l-.136.458z"
      transform="translate(-25 -349) translate(16 338) translate(9 11) translate(5 4)"
    />
  </SvgIcon>
);

export default TitreSejourIcon;
