import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Actions from "components/Layout/Actions";
import CenteredTitle from "components/Layout/CenteredTitle";
import Illustration from "components/Layout/Illustration";
import MainContent from "components/Layout/MainContent";
import Step from "components/Layout/Step";

const ErrorPage = (props) => {
  const { title, imgTitle, subtitle, action, imgSrc } = props;

  return (
    <Step progress={100}>
      <Illustration>
        <img
          src={imgSrc}
          alt={imgTitle || title}
          aria-hidden
          title={imgTitle || title}
        />
      </Illustration>
      <CenteredTitle>{title}</CenteredTitle>
      <MainContent>
        <Typography align="center" variant="body1">
          {subtitle}
        </Typography>
      </MainContent>
      <Actions>{action}</Actions>
    </Step>
  );
};

ErrorPage.propTypes = {
  title: PropTypes.node.isRequired,
  imgTitle: PropTypes.string,
  subtitle: PropTypes.node,
  action: PropTypes.node.isRequired,
  imgSrc: PropTypes.string.isRequired,
};

ErrorPage.defaultProps = {
  subtitle: null,
  imgTitle: null,
};

export default ErrorPage;
