import { deleteFilesByType, getFiles } from "utils/FileUtil";
import useUpload from "./useUpload";

const useFile = () => {
  const { deleteUploadedFilesByType } = useUpload();

  const cleanFiles = (type) => {
    // Si il y a des fichiers du type correspondants
    if (getFiles().filter((file) => file.uploadType === type).length > 0) {
      // On supprime les fichiers du tableau files
      deleteFilesByType(type);
      // On supprimes les fichier des uploadItems partie upload
      deleteUploadedFilesByType(type);
    }
  };

  return {
    cleanFiles,
  };
};

export default useFile;
