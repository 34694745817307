import React from "react";
import Step from "components/Layout/Step";
import MainContent from "components/Layout/MainContent";
import Illustration from "components/Layout/Illustration";
import CenteredTitle from "components/Layout/CenteredTitle";
import { Box, Link, Typography } from "@material-ui/core";
import Actions from "components/Layout/Actions";
import { getReturnUrl } from "utils/UrlUtil";
import Button from "components/Button";
import useServiceName from "hook/useServiceName";
import { useTranslation } from "react-i18next";

const UnknownLeader = () => {
  const { t } = useTranslation();
  const serviceName = useServiceName();

  const title = t("unknownLeader:title");

  return (
    <Step progress={100}>
      <Illustration>
        <img src="/certification.png" alt={title} aria-hidden title={title} />
      </Illustration>
      <CenteredTitle>{title}</CenteredTitle>
      <MainContent>
        <Typography variant="body1" align="center">
          {t("unknownLeader:content.part1")}
        </Typography>
        <Typography variant="body1" align="center">
          {t("unknownLeader:content.part2")}
        </Typography>
      </MainContent>
      <Actions>
        <Box mb={2} style={{ width: "100% " }}>
          <Link href={getReturnUrl({ error: true })} underline="none">
            <Button>
              {t("common:goTo")} {serviceName}
            </Button>
          </Link>
        </Box>
      </Actions>
    </Step>
  );
};
export default UnknownLeader;
