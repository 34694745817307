export default class CompanyAddress {
  constructor({
    country = "",
    countyCode = "",
    name1 = "",
    postalCode = "",
    street = "",
    ...others
  } = {}) {
    this.country = country;
    this.countyCode = countyCode;
    this.name1 = name1;
    this.postalCode = postalCode;
    this.street = street;
    Object.keys(others).forEach((key) => {
      this[key] = others[key];
    });
  }
}
