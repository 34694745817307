import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Actions from "components/Layout/Actions";
import MainContent from "components/Layout/MainContent";
import Button from "components/Button";
import Link from "components/Link";
import Step from "components/Layout/Step";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import MiniCard from "components/Layout/MiniCard";
import {
  ID_STATUS_OPTIONS,
  getIdStatus,
  setIdStatus as setStatus,
} from "utils/TestModeParametersUtil";

function IdConditions() {
  const title = "Votre pièce d'identité:";

  const [idStatus, setIdStatus] = useState(getIdStatus());

  const disabled = !idStatus;

  const goOnButton = () => (
    <Button
      disabled={disabled}
      onClick={() => setStatus(idStatus)}
      data-testid="test-button"
    >
      Continuer
    </Button>
  );

  return (
    <Step
      title={title}
      previousStepPath="/conditions-representant-legal"
      progress={600 / 8}
    >
      <MainContent>
        <FormControl fullWidth component="fieldset">
          <RadioGroup
            name="id"
            value={idStatus}
            onChange={(e) => setIdStatus(e.currentTarget.value)}
          >
            <MiniCard type="tertiary">
              <FormControlLabel
                htmlFor="isNotReadable"
                value={ID_STATUS_OPTIONS.NOT_READABLE}
                control={<Radio id="isNotReadable" />}
                label={
                  <Typography variant="subtitle2">
                    n&apos;est pas lisible
                  </Typography>
                }
              />
            </MiniCard>

            <MiniCard type="tertiary">
              <FormControlLabel
                htmlFor="isExpired"
                value={ID_STATUS_OPTIONS.IS_EXPIRED}
                control={<Radio id="isExpired" />}
                label={<Typography variant="subtitle2">est périmée</Typography>}
              />
            </MiniCard>

            <MiniCard type="tertiary">
              <FormControlLabel
                htmlFor="isNotValid"
                value={ID_STATUS_OPTIONS.NOT_VALID}
                control={<Radio id="isNotValid" />}
                label={
                  <Typography variant="subtitle2">
                    n&apos;est pas valide
                  </Typography>
                }
              />
            </MiniCard>

            <MiniCard type="tertiary">
              <FormControlLabel
                htmlFor="realPath"
                value={ID_STATUS_OPTIONS.REAL_PATH}
                control={<Radio id="realPath" />}
                label={
                  <Typography variant="subtitle2">pas de conditions</Typography>
                }
              />
            </MiniCard>
          </RadioGroup>
        </FormControl>
      </MainContent>
      <Actions>
        <Box mb={2} style={{ width: "100%" }}>
          {disabled ? (
            goOnButton()
          ) : (
            <Link
              data-testid="test-link"
              to={{
                pathname:
                  idStatus === ID_STATUS_OPTIONS.REAL_PATH
                    ? "/conditions-resultat"
                    : "/commencer-test",
                state: { from: "/conditions-piece-d-identite" },
              }}
              underline="none"
            >
              {goOnButton()}
            </Link>
          )}
        </Box>
      </Actions>
    </Step>
  );
}

export default IdConditions;
