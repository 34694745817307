import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const PdfFile = (props) => (
  <SvgIcon {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#3C3C3C" fillRule="nonzero">
        <g>
          <g>
            <path
              d="M13.135.833c.49 0 .961.183 1.329.509l.133.128 2.32 2.454c.333.352.535.811.575 1.296l.008.183v11.648c0 1.158-.905 2.116-2.045 2.116H4.545c-1.09 0-1.966-.876-2.04-1.966l-.005-.15V2.95c0-1.108.828-2.032 1.898-2.11l.147-.006h8.59zm0 1.667h-8.59c-.173 0-.333.146-.37.355l-.008.094V17.05c0 .226.138.4.305.44l.073.009h10.91c.198 0 .378-.191.378-.449V5.403c0-.096-.026-.189-.073-.264l-.054-.07-2.32-2.454c-.054-.057-.118-.093-.185-.108l-.066-.007zm-3.37 5c.522 0 .948.426.948.95 0 .43-.168.934-.345 1.468l-.063.192c.233.42.548.833.902 1.182.335-.107 1.045-.31 1.594-.31.523 0 .949.426.949.949 0 .523-.426.949-.95.949-.593 0-1.292-.246-1.808-.63-.591.148-1.195.376-1.811.617-.29.545-.593 1.092-.863 1.415l-.049.045c-.152.158-.392.256-.653.256-.523 0-.95-.425-.95-.948 0-.254.097-.492.273-.672.364-.3.845-.484 1.403-.696l.28-.108c.307-.617.549-1.333.753-1.97-.295-.597-.56-1.216-.56-1.74 0-.523.426-.949.95-.949zm.19 3.653c-.065.19-.136.387-.213.586.134-.047.268-.094.402-.137l.135-.044c-.113-.13-.222-.265-.324-.405z"
              transform="translate(-30 -591) translate(16 573) translate(14 18)"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default PdfFile;
