import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const StaticLoaderIcon = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.75 1C11.75 0.585786 11.4142 0.25 11 0.25C10.5858 0.25 10.25 0.585786 10.25 1V5C10.25 5.41421 10.5858 5.75 11 5.75C11.4142 5.75 11.75 5.41421 11.75 5V1ZM18.6003 4.46032C18.8932 4.16743 18.8932 3.69256 18.6003 3.39966C18.3074 3.10677 17.8326 3.10677 17.5397 3.39966L14.7097 6.22966C14.4168 6.52256 14.4168 6.99743 14.7097 7.29032C15.0026 7.58322 15.4774 7.58322 15.7703 7.29032L18.6003 4.46032ZM0.25 11C0.25 10.5858 0.585786 10.25 1 10.25H5C5.41421 10.25 5.75 10.5858 5.75 11C5.75 11.4142 5.41421 11.75 5 11.75H1C0.585786 11.75 0.25 11.4142 0.25 11ZM17 10.25C16.5858 10.25 16.25 10.5858 16.25 11C16.25 11.4142 16.5858 11.75 17 11.75H21C21.4142 11.75 21.75 11.4142 21.75 11C21.75 10.5858 21.4142 10.25 21 10.25H17ZM7.29038 14.7097C7.58328 15.0026 7.58328 15.4774 7.29038 15.7703L4.46038 18.6003C4.16749 18.8932 3.69262 18.8932 3.39972 18.6003C3.10683 18.3074 3.10683 17.8326 3.39972 17.5397L6.22972 14.7097C6.52262 14.4168 6.99749 14.4168 7.29038 14.7097ZM15.7703 14.7097C15.4774 14.4168 15.0026 14.4168 14.7097 14.7097C14.4168 15.0026 14.4168 15.4774 14.7097 15.7703L17.5397 18.6003C17.8326 18.8932 18.3074 18.8932 18.6003 18.6003C18.8932 18.3074 18.8932 17.8326 18.6003 17.5397L15.7703 14.7097ZM11.75 17C11.75 16.5858 11.4142 16.25 11 16.25C10.5858 16.25 10.25 16.5858 10.25 17V21C10.25 21.4142 10.5858 21.75 11 21.75C11.4142 21.75 11.75 21.4142 11.75 21V17ZM3.39972 3.39966C3.69262 3.10677 4.16749 3.10677 4.46038 3.39966L7.29038 6.22966C7.58328 6.52256 7.58328 6.99743 7.29038 7.29032C6.99749 7.58322 6.52262 7.58322 6.22972 7.29032L3.39972 4.46032C3.10683 4.16743 3.10683 3.69256 3.39972 3.39966Z"
      fill="#3C3C3C"
    />
  </SvgIcon>
);

export default StaticLoaderIcon;
