import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  root: {
    flex: "1 auto",
  },
}));

const MainContent = (props) => {
  const { children, className, ...others } = props;

  const classes = useStyles();

  return (
    <div className={classnames(classes.root, className)} {...others}>
      {children}
    </div>
  );
};

MainContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

MainContent.defaultProps = {
  children: null,
  className: null,
};

export default MainContent;
