import React from "react";
import { Box, Link, Typography, useTheme } from "@material-ui/core";
import Button from "components/Button";
import ErrorPage from "pages/ErrorPage";
import { getReturnUrl } from "utils/UrlUtil";
import useServiceName from "hook/useServiceName";
import { useTranslation } from "react-i18next";
import Toaster, { types as toasterTypes } from "components/Toaster";

const ErrorInactiveCoclico = () => {
  const serviceName = useServiceName();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <ErrorPage
      title={t("errorInactiveCoclico:title")}
      subtitle={t("errorInactiveCoclico:subTitle")}
      action={
        <>
          <Box mt={1.5} mb={2.5}>
            <Toaster type={toasterTypes.INFO} style={{ borderRadius: "8px" }}>
              {t("errorInactiveCoclico:info")}
            </Toaster>
          </Box>
          <Link
            style={{ width: "100%" }}
            href={process.env.REACT_APP_ADV_MISSING_INFO}
            underline="none"
          >
            <Button>{t("errorInactiveCoclico:cta")}</Button>
          </Link>
          <Link
            style={{
              width: "100%",
              marginTop: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
            href={getReturnUrl({ error: true })}
            underline="none"
            data-testid="test-link"
          >
            <Typography
              variant="body2"
              style={{ fontWeight: 700, color: theme.palette.cerulean }}
            >
              {t("common:goBackTo")} {serviceName}
            </Typography>
          </Link>
        </>
      }
      imgSrc="/certification.png"
    />
  );
};

export default ErrorInactiveCoclico;
