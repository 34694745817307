import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/styles/makeStyles";
import UserService from "services/UserService";
import User from "models/User";
import Actions from "components/Layout/Actions";
import MainContent from "components/Layout/MainContent";
import Step from "components/Layout/Step";
import Button from "components/Button";
import { isFrenchAddress } from "utils/AddressUtil";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "12px",
    color: theme.palette["neutral-neutral-6"],
  },
  labelValue: {
    fontSize: "12px",
    color: theme.palette["01-body-background-charte-gris-6-tex"],
    lineHeight: "1.5",
    fontWeight: 500,
  },
  infoLink: {
    color: theme.palette.cerulean,
  },
  infoIcon: {
    marginLeft: 2,
    marginBottom: -2,
  },
  boxContent: {
    border: `1px solid ${theme.palette["neutral-neutral-3"]}`,
    borderRadius: 10,
  },
}));

const CompanyInfo = () => {
  const [user, setUser] = useState(new User());

  const history = useHistory();
  const { t } = useTranslation();

  const { company } = user || {};
  const { address, name = "", siret = "", rnaNumber = "" } = company || {};
  const { street = "", postalCode = "", country = "" } = address || {};

  const classes = useStyles();

  const handleClickValidate = useCallback(() => {
    const redirect = async () => {
      const hasSiretNumber = await UserService.hasSiretNumber();
      const hasCompanyInfo = await UserService.hasCompanyInfo();

      if (!hasSiretNumber) {
        history.push("/siret-manquant");
      } else if (!hasCompanyInfo) {
        history.push("/adresse-manquante");
      } else {
        history.push("/choix-representant-legal");
      }
    };

    redirect();
  }, [history]);

  useEffect(() => {
    UserService.get().then((userResponse) => {
      setUser(userResponse);
    });
  }, []);

  const updateInfosEvent = (event) => {
    if (typeof window.tc_events_20 === "function") {
      window.tc_events_20(event, "click", {
        event: "C",
        type_clic: "navigation",
        chapter1: "certification-rl",
        chapter2: "etape-2",
        chapter3: "",
        name: "mettre_a_jour_les_informations",
      });
    }
  };

  return (
    <Step title={t("companyInfo:title")} previousStepPath="/" progress={18}>
      <MainContent>
        <Box p={2} className={classes.boxContent}>
          <Box pb={1.5} display="flex" justifyContent="space-between">
            <Box>
              <div className={classes.label}>
                {(rnaNumber && t("companyInfo:rna")) || t("companyInfo:siret")}
              </div>
              <div className={classes.labelValue}>{siret || rnaNumber}</div>
            </Box>
          </Box>
          <Divider />
          <Box pt={1.5} pb={1}>
            <div className={classes.label}>{t("companyInfo:companyName")}</div>
            <div className={classes.labelValue}>{name}</div>
          </Box>
          <Box py={1}>
            <div className={classes.label}>{t("companyInfo:legalAddress")}</div>
            <div className={classes.labelValue}>{street}</div>
            <div className={classes.labelValue}>{postalCode}</div>
            {country && !isFrenchAddress(address) && (
              <div className={classes.labelValue}>{country}</div>
            )}
          </Box>
        </Box>
        <Box pt={1.5} display="flex" justifyContent="flex-end">
          <Typography
            variant="body2"
            component={Link}
            href={process.env.REACT_APP_ADV_UPDATE_COMPANY_INFO}
            className={classes.infoLink}
            onClick={(event) => {
              updateInfosEvent(event);
            }}
          >
            {t("companyInfo:updateInfos")}
          </Typography>
        </Box>
      </MainContent>
      <Actions>
        <Box mb={2} style={{ width: "100%" }}>
          <Button onClick={handleClickValidate} data-testid="test-button">
            {t("common:continue")}
          </Button>
        </Box>
      </Actions>
    </Step>
  );
};

export default CompanyInfo;
