import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";
import WarningIcon from "icons/warning";
import SuccessIcon from "icons/success";
import CloseIcon from "icons/close";
import InfoIcon from "icons/info";

const ErrorIcon = WarningIcon;

export const types = {
  ERROR: "ERROR",
  WARNING: "WARNING",
  SUCCESS: "SUCCESS",
  INFO: "INFO",
};

const getBackgroundColorByType = (type, theme) => {
  switch (type) {
    case types.ERROR:
      return theme.palette["status-background-error-backgroun"];
    case types.WARNING:
      return theme.palette["status-background-warning-backgro"];
    case types.SUCCESS:
      return theme.palette["status-background-success-backgro"];
    case types.INFO:
      return theme.palette["status-background-information-bac"];
    default:
      return theme.palette["status-background-information-bac"];
  }
};

const getColorByType = (type, theme) => {
  switch (type) {
    case types.ERROR:
      return theme.palette["status-icon-error"];
    case types.WARNING:
      return theme.palette["status-icon-warning"];
    case types.SUCCESS:
      return theme.palette["status-icon-success"];
    case types.INFO:
    default:
      return theme.palette["status-icon-information"];
  }
};

const getIconByType = (type) => {
  switch (type) {
    case types.ERROR:
      return <ErrorIcon />;
    case types.WARNING:
      return <WarningIcon />;
    case types.SUCCESS:
      return <SuccessIcon />;
    case types.INFO:
    default:
      return <InfoIcon />;
  }
};

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: "flex",
    flexWrap: "no-wrap",
    padding: "14px 12px 17px",
    backgroundColor: getBackgroundColorByType(props.type, theme),
    fontSize: "12px",
    borderRadius: 8,
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "14px",
    },
  }),
  icon: (props) => ({
    color: getColorByType(props.type, theme),
    fontSize: "18px",
    marginTop: "2px",
  }),
  content: {
    flex: 1,
    marginLeft: "8px",
    color: theme.palette["background-dark"],
    lineHeight: "18px",
  },
  close: {
    marginLeft: "8px",
    fontSize: "20px",
  },
}));

const Toaster = (props) => {
  const { type, children, onClose, className, ...others } = props;

  const classes = useStyles({ type });

  return (
    <div className={classnames(classes.root, className)} {...others}>
      <div className={classes.icon}>{getIconByType(type)}</div>
      <div className={classes.content}>{children}</div>
      {typeof onClose === "function" && (
        <div className={classes.close}>
          <CloseIcon onClick={onClose} style={{ cursor: "pointer" }} />
        </div>
      )}
    </div>
  );
};

Toaster.propTypes = {
  type: PropTypes.oneOf(Object.values(types)),
  children: PropTypes.node,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

Toaster.defaultProps = {
  type: types.INFO,
  children: null,
  onClose: null,
  className: null,
};

export default Toaster;
