import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Actions from "components/Layout/Actions";
import MainContent from "components/Layout/MainContent";
import Button from "components/Button";
import Link from "components/Link";
import Step from "components/Layout/Step";
import { FormControl, FormControlLabel, Checkbox } from "@material-ui/core";
import MiniCard from "components/Layout/MiniCard";
import {
  COMPANY_STATUS_OPTIONS,
  getCompanyStatus,
  setCompanyStatus,
} from "utils/TestModeParametersUtil";

function CompanyConditions() {
  const title = "Dans le test, que vous allez effectuer, votre entreprise:";

  const companyStatus = getCompanyStatus() || [];

  const [hasNoCoclico, setHasNoCoclico] = useState(
    companyStatus.includes(COMPANY_STATUS_OPTIONS.NO_COCLICO),
  );
  const [hasInvalidCoclico, setHasInvalidCoclico] = useState(
    companyStatus.includes(COMPANY_STATUS_OPTIONS.INVALID_COCLICO),
  );
  const [hasNoSiret, setHasNoSiret] = useState(
    companyStatus.includes(COMPANY_STATUS_OPTIONS.NO_SIRET),
  );
  const [hasInactiveSiret, setHasInactiveSiret] = useState(
    companyStatus.includes(COMPANY_STATUS_OPTIONS.INACTIVE_SIRET),
  );
  const [isNotDiffusable, setIsNotDiffusable] = useState(
    companyStatus.includes(COMPANY_STATUS_OPTIONS.IS_NOT_DIFFUSABLE),
  );
  const [hasInvalidAdress, setHasInvalidAdress] = useState(
    companyStatus.includes(COMPANY_STATUS_OPTIONS.INVALID_ADRESS),
  );
  const [realPath, setRealPath] = useState(
    companyStatus.includes(COMPANY_STATUS_OPTIONS.REAL_PATH),
  );

  const handleClickRealPath = () => {
    setRealPath(!realPath);
    if (!realPath) {
      setHasNoCoclico(false);
      setHasInvalidCoclico(false);
      setHasNoSiret(false);
      setHasInactiveSiret(false);
      setHasInvalidAdress(false);
      setIsNotDiffusable(false);
    }
  };

  const disabled =
    !realPath &&
    !hasNoCoclico &&
    !hasInvalidCoclico &&
    !hasNoSiret &&
    !hasInactiveSiret &&
    !hasInvalidAdress &&
    !isNotDiffusable;

  const handleClick = () => {
    const status = [];
    if (realPath) {
      status.push(COMPANY_STATUS_OPTIONS.REAL_PATH);
    } else {
      if (hasNoCoclico) status.push(COMPANY_STATUS_OPTIONS.NO_COCLICO);
      if (hasInvalidCoclico)
        status.push(COMPANY_STATUS_OPTIONS.INVALID_COCLICO);
      if (hasInactiveSiret) status.push(COMPANY_STATUS_OPTIONS.INACTIVE_SIRET);
      if (isNotDiffusable)
        status.push(COMPANY_STATUS_OPTIONS.IS_NOT_DIFFUSABLE);
      if (hasNoSiret) status.push(COMPANY_STATUS_OPTIONS.NO_SIRET);
      if (hasInvalidAdress) status.push(COMPANY_STATUS_OPTIONS.INVALID_ADRESS);
    }
    setCompanyStatus(status);
  };

  const isFinishedConfiguration = () =>
    hasNoCoclico || hasInvalidCoclico || hasInactiveSiret || isNotDiffusable;

  const goOnButton = () => (
    <Button disabled={disabled} onClick={handleClick} data-testid="test-button">
      Continuer
    </Button>
  );

  return (
    <Step
      title={title}
      previousStepPath="/description-simulateur"
      progress={300 / 8}
    >
      <MainContent>
        <FormControl fullWidth component="fieldset">
          <MiniCard type="tertiary">
            <FormControlLabel
              htmlFor="hasNoCoclico"
              control={
                <Checkbox
                  id="hasNoCoclico"
                  checked={hasNoCoclico}
                  onClick={() => {
                    setHasNoCoclico(!hasNoCoclico);
                    if (!hasNoCoclico) {
                      setRealPath(false);
                    }
                  }}
                />
              }
              label={
                <Typography variant="subtitle2">
                  n&apos;a pas de Coclico
                </Typography>
              }
            />
          </MiniCard>

          <MiniCard type="tertiary">
            <FormControlLabel
              htmlFor="hasInvalidCoclico"
              control={
                <Checkbox
                  id="hasInvalidCoclico"
                  checked={hasInvalidCoclico}
                  onClick={() => {
                    setHasInvalidCoclico(!hasInvalidCoclico);
                    if (!hasInvalidCoclico) {
                      setRealPath(false);
                    }
                  }}
                />
              }
              label={
                <Typography variant="subtitle2">
                  a un Coclico invalide
                </Typography>
              }
            />
          </MiniCard>

          <MiniCard type="tertiary">
            <FormControlLabel
              htmlFor="hasNoSiret"
              control={
                <Checkbox
                  id="hasNoSiret"
                  checked={hasNoSiret}
                  onClick={() => {
                    setHasNoSiret(!hasNoSiret);
                    if (!hasNoSiret) {
                      setRealPath(false);
                    }
                  }}
                />
              }
              label={
                <Typography variant="subtitle2">
                  n&apos;a pas de SIRET
                </Typography>
              }
            />
          </MiniCard>

          <MiniCard type="tertiary">
            <FormControlLabel
              htmlFor="hasInactiveSiret"
              control={
                <Checkbox
                  id="hasInactiveSiret"
                  checked={hasInactiveSiret}
                  onClick={() => {
                    setHasInactiveSiret(!hasInactiveSiret);
                    if (!hasInactiveSiret) {
                      setRealPath(false);
                    }
                  }}
                />
              }
              label={
                <Typography variant="subtitle2">a un SIRET inactive</Typography>
              }
            />
          </MiniCard>

          <MiniCard type="tertiary">
            <FormControlLabel
              htmlFor="isDiffusable"
              control={
                <Checkbox
                  id="isDiffusable"
                  checked={isNotDiffusable}
                  onClick={() => {
                    setIsNotDiffusable(!isNotDiffusable);
                    if (!isNotDiffusable) {
                      setRealPath(false);
                    }
                  }}
                />
              }
              label={
                <Typography variant="subtitle2">
                  a un statut de diffusion privé
                </Typography>
              }
            />
          </MiniCard>

          <MiniCard type="tertiary">
            <FormControlLabel
              htmlFor="hasInvalidAdress"
              control={
                <Checkbox
                  id="hasInvalidAdress"
                  checked={hasInvalidAdress}
                  onClick={() => {
                    setHasInvalidAdress(!hasInvalidAdress);
                    if (!hasInvalidAdress) {
                      setRealPath(false);
                    }
                  }}
                />
              }
              label={
                <Typography variant="subtitle2">
                  n&apos;a pas d&apos;adresse valide
                </Typography>
              }
            />
          </MiniCard>

          <MiniCard type="tertiary">
            <FormControlLabel
              htmlFor="realPath"
              control={
                <Checkbox
                  id="realPath"
                  checked={realPath}
                  onClick={handleClickRealPath}
                />
              }
              label={
                <Typography variant="subtitle2">pas de conditions</Typography>
              }
            />
          </MiniCard>
        </FormControl>
      </MainContent>
      <Actions>
        <Box mb={2} style={{ width: "100%" }}>
          {disabled ? (
            goOnButton()
          ) : (
            <Link
              data-testid="test-link"
              to={{
                pathname: isFinishedConfiguration()
                  ? "/commencer-test"
                  : "/conditions-compte",
                state: { from: "/conditions-entreprise" },
              }}
              underline="none"
            >
              {goOnButton()}
            </Link>
          )}
        </Box>
      </Actions>
    </Step>
  );
}

export default CompanyConditions;
