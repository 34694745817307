import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Actions from "components/Layout/Actions";
import Illustration from "components/Layout/Illustration";
import MainContent from "components/Layout/MainContent";
import Button from "components/Button";
import Step from "components/Layout/Step";
import usePartners from "hook/usePartners";
import Link from "components/Link";

function TestModeHome() {
  const { getPartnerHomeLogo } = usePartners();

  const title = "Mode Test";

  return (
    <Step progress={0}>
      <Illustration>
        <img src={getPartnerHomeLogo()} alt={title} aria-hidden title={title} />
      </Illustration>
      <MainContent>
        <Typography
          variant="body1"
          component="div"
          style={{ marginTop: "1.5rem", textAlign: "center" }}
        >
          Bienvenue dans &quot;Mode Test&quot;.
          <div style={{ marginTop: "1.25rem" }}>
            C&apos;est un similateur qui vous permettra de tester les diverses
            situations lors du parcours de certification.
          </div>
        </Typography>
      </MainContent>
      <Actions>
        <Box mb={2} style={{ width: "100%" }}>
          <Link to="/automatic-success" underline="none">
            <Button>Commencer</Button>
          </Link>
        </Box>
      </Actions>
    </Step>
  );
}

export default TestModeHome;
